<template>
  <div class="dashboard-container">
    <h1>首页看板</h1>
    <a href="/"><h4>前台页面</h4></a>
  </div>
</template>

<script>

export default {
  name: 'Dashboard'
}
</script>

<style lang="scss" scoped>
.dashboard {
  &-container {
    margin: 30px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}
</style>

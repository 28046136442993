<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
      </el-col>

      <el-col :span="20">
        <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        <el-button type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>
      </el-col>
    </el-row>

    <el-table
      ref="tableList"
      v-loading="listLoading"
      :data="list"
      :height="tableHeight"
      fit
      highlight-current-row
      style="width: 100%; overflow: auto !important;"
    >
      <el-table-column label="ID" align="center">
        <template slot-scope="{row}">
          <div>{{ row.id }}</div>
          <div>{{ row.code }}</div>
        </template>
      </el-table-column>
      <el-table-column label="名称" align="center">
        <template slot-scope="{row}">
          <div>{{ row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{row, $index}">
          <div v-show="row.is_system!==1">
            <el-button v-show="row.id>0" size="mini" type="primary" style="margin-right: 12px" @click="handleUpdate(row)">编辑</el-button>
            <el-popover
              :ref="`popover` + $index"
              placement="top"
              width="240"
              :tabindex="$index"
            >
              <p>删除 ‘{{ row.name }}’ 吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="handleCancelVisible($index)">取消</el-button>
                <el-button size="mini" type="danger" @click="handleDel(row.id, row.name, $index)">确定</el-button>
              </div>
              <el-button slot="reference" size="mini">删除</el-button>
            </el-popover>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListData" />

    <el-dialog title="添加用户组" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="60px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="temp.code" maxlength="8" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="createData">
          确认
        </el-button>
      </div>
    </el-dialog>

    <el-drawer
      :title="temp.name"
      :visible.sync="drawerVisible"
      direction="rtl"
      :show-close="false"
      size="60%"
    >
      <div class="drawer-content">
        <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="60px" style="padding: 0 20px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="temp.name" maxlength="32" show-word-limit />
          </el-form-item>
          <el-form-item label="编码" prop="code">
            <el-input v-model="temp.code" maxlength="8" show-word-limit />
          </el-form-item>
        </el-form>
        <el-table
          ref="multipleRoleTable"
          :data="roleList"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" :selectable="roleSelectable" width="55" />
          <el-table-column label="角色">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="Code">
            <template slot-scope="scope">{{ scope.row.code }}</template>
          </el-table-column>
        </el-table>

        <pagination v-show="roleTotal>roleListQuery.limit" :total="roleTotal" :page.sync="roleListQuery.page" :limit.sync="roleListQuery.limit" @pagination="roleDetailData(temp)" />

        <div class="drawer-footer">
          <el-button size="medium" @click="drawerVisible = false">取消</el-button>
          <el-button size="medium" type="primary" @click="updateData">确认</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { userGroupListApi, userGroupAddApi, userGroupDetailApi, userGroupUpdateApi, userGroupDeleteApi } from '@/api/user-center'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

export default {
  name: 'UserGroup',
  components: { Pagination },
  data() {
    return {
      tableHeight: 300,
      listLoading: false,
      list: null,
      listQuery: {
        page: 1,
        limit: 20,
        name: ''
      },
      total: 0,
      temp: {
        id: undefined,
        name: '',
        code: ''
      },
      dialogFormVisible: false,
      rules: {
        name: [{ required: true, message: '名称必须填写', trigger: 'change' }]
      },
      drawerVisible: false,
      roleListQuery: {
        id: 1,
        page: 1,
        limit: 20,
        name: ''
      },
      roleTotal: 0,
      roleList: [],
      checkedAllRoleId: [], // 翻了的有效页面
      checkedRoleId: [], // 选中了的角色ID数据
      isComplete: false,
      checkedMenu: [] // 选中了的权限数据
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 64
      // 监听窗口大小变化
      window.onresize = function() {
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 64
      }
    })
  },
  created() {
    this.getListData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getListData() {
      this.listLoading = true
      userGroupListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.getListData()
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        name: '',
        code: ''
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          userGroupAddApi(this.temp).then((response) => {
            this.temp = response.data
            this.handleUpdate(this.temp)
            this.getListData()
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = row
      this.resetRoleListQuery()
      this.roleDetailData(row)
    },
    resetRoleListQuery() {
      this.checkedAllRoleId = []
      this.checkedRoleId = []
      this.roleListQuery = {
        organization_id: 1,
        page: 1,
        limit: 20,
        name: ''
      }
    },
    handleSelectionChange(val) {
      if (this.isComplete) {
        this.checkedRoleId[this.roleListQuery.page] = val
      }
    },
    roleSelectable(row, index) {
      return !row.disabled
    },
    roleDetailData(row) {
      this.isComplete = false
      this.roleListQuery.id = row.id
      userGroupDetailApi(this.roleListQuery).then(response => {
        this.roleList = response.data.listData
        this.roleTotal = response.data.pageData.total
        if (!this.checkedRoleId[this.roleListQuery.page]) {
          this.checkedRoleId[this.roleListQuery.page] = response.data.checkedRole
        }
        this.checkedAllRoleId[this.roleListQuery.page] = this.roleList

        this.drawerVisible = true
        this.$nextTick(() => {
          this.checkedRoleId[this.roleListQuery.page].forEach(row => {
            // 不能自己自定义对象来设置选中（原因如下分析），那我可以从列表中找到需要选中的那个对象，然后把它拿过来作为选中的项就可以了
            this.$refs.multipleRoleTable.toggleRowSelection(this.roleList.find(item => {
              return row.id === item.id // 注意这里寻找的字段要唯一，示例仅参考
            }), true)
          })

          // 渲染完毕
          this.isComplete = true
        })
      })
    },
    updateData() {
      const roleIdArray = []
      const allRoleIdArray = []
      for (const p in this.checkedAllRoleId) {
        if (this.checkedAllRoleId[p]) {
          for (const a in this.checkedAllRoleId[p]) {
            allRoleIdArray.push(this.checkedAllRoleId[p][a].id)
          }
        }
      }
      for (const cp in this.checkedRoleId) {
        if (this.checkedRoleId[cp]) {
          for (const c in this.checkedRoleId[cp]) {
            roleIdArray.push(this.checkedRoleId[cp][c].id)
          }
        }
      }

      const tempData = { name: this.temp.name, code: this.temp.code, id: this.temp.id, role_id: roleIdArray.join(','), all_role_id: allRoleIdArray.join(',') }
      userGroupUpdateApi(tempData).then((response) => {
        this.getListData()
        this.drawerVisible = false
        this.$message({
          message: response.message,
          type: 'success',
          duration: 1500
        })
      })
    },
    handleCancelVisible(index) {
      this.$refs[`popover` + index].doClose()
    },
    handleDel(id, name, index) {
      this.$refs[`popover` + index].doClose()
      userGroupDeleteApi({ id: id }).then(response => {
        this.getListData()
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>
  .drawer-footer {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    padding: 0 20px;
    margin-top: 60px;
    margin-bottom: 20px;
  }
</style>


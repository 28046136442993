var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        { staticClass: "redis-manager-container" },
        [
          _c(
            "el-aside",
            { attrs: { width: "380px" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: { gutter: 8 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.listQuery.db,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "db", $$v)
                            },
                            expression: "listQuery.db",
                          },
                        },
                        _vm._l(_vm.dbArray, function (item) {
                          return _c("el-option", {
                            key: item.db,
                            attrs: { label: "DB" + item.db, value: item.db },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.keysTotal,
                          callback: function ($$v) {
                            _vm.keysTotal = $$v
                          },
                          expression: "keysTotal",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: { gutter: 8 },
                },
                [
                  _c(
                    "el-col",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "key", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleFilter($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.key,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "key", $$v)
                          },
                          expression: "listQuery.key",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "tableList",
                  staticStyle: { width: "100%", overflow: "auto !important" },
                  attrs: {
                    data: _vm.list,
                    height: _vm.tableHeight,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "键名", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { underline: false },
                                    nativeOn: {
                                      dblclick: function ($event) {
                                        return _vm.handleDetail(row.db, row.key)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.key))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", width: "80px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "el-popover",
                              {
                                ref: "popover" + $index,
                                attrs: {
                                  placement: "top",
                                  width: "240",
                                  tabindex: $index,
                                },
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    "删除DB" +
                                      _vm._s(row.db) +
                                      ": " +
                                      _vm._s(row.key) +
                                      " 吗？"
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      margin: "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCancelVisible(
                                              $index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("取消")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDel(
                                              row.db,
                                              row.key,
                                              $index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("确定")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "reference", size: "mini" },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  "page-count": _vm.cursorArray.length,
                  "current-page": _vm.listQuery.page,
                  "page-size": _vm.listQuery.limit,
                },
                on: { "current-change": _vm.currentList },
              }),
              _c("div", { staticClass: "tip" }, [
                _c("span", [_vm._v("*最多显示 3000 条数据")]),
              ]),
            ],
            1
          ),
          _c(
            "el-main",
            { staticClass: "main-tabs-content" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-remove": _vm.removeDetailTab },
                  model: {
                    value: _vm.editableTabsValue,
                    callback: function ($$v) {
                      _vm.editableTabsValue = $$v
                    },
                    expression: "editableTabsValue",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "首页", name: "index" } },
                    [
                      _vm.indexData
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-descriptions",
                                { attrs: { title: "实例基本信息" } },
                                [
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "版本号" } },
                                    [_vm._v(_vm._s(_vm.indexData.version))]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "运行模式" } },
                                    [_vm._v(_vm._s(_vm.indexData.start_mode))]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "服务监听端口" } },
                                    [_vm._v(_vm._s(_vm.indexData.port))]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "DB数" } },
                                    [_vm._v(_vm._s(_vm.indexData.db_total))]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "有效期Key数量" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.indexData.expires_total)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "Key总数" } },
                                    [_vm._v(_vm._s(_vm.indexData.keys_total))]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "已运行时间" } },
                                    [_vm._v(_vm._s(_vm.indexData.runtime))]
                                  ),
                                ],
                                1
                              ),
                              _c("el-divider"),
                              _c(
                                "el-descriptions",
                                { attrs: { title: "内存使用情况" } },
                                [
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "数据内存" } },
                                    [_vm._v(_vm._s(_vm.indexData.used_memory))]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "服务内存" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.indexData.used_memory_rss)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "消耗峰值" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.indexData.used_memory_peak)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "使用峰值百分比" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.indexData.used_memory_peak_perc
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "服务器总量" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.indexData.total_system_memory
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "数据使用百分比" } },
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: _vm._f("userMemoryTagFilter")(
                                              _vm.indexData.used_memory_perc
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.indexData.used_memory_perc
                                            ) + "%"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._l(_vm.detailTabs, function (tab) {
                    return _c(
                      "el-tab-pane",
                      {
                        key: tab.name,
                        attrs: {
                          label: tab.label,
                          name: tab.name,
                          closable: "",
                        },
                      },
                      [
                        _c("div", { staticClass: "tab-key-desc" }, [
                          _c("span", [
                            _vm._v("库："),
                            _c("b", [_vm._v(_vm._s(tab.db))]),
                          ]),
                          _c("span", [
                            _vm._v("键名："),
                            _c("b", [_vm._v(_vm._s(tab.key))]),
                          ]),
                          _c("span", [
                            _vm._v("类型："),
                            _c("b", [_vm._v(_vm._s(tab.detail.type))]),
                          ]),
                          _c("span", [
                            _vm._v("TTL："),
                            _c("b", [_vm._v(_vm._s(tab.detail.ttl))]),
                          ]),
                        ]),
                        _c("el-divider"),
                        _c(
                          "div",
                          [
                            tab.detail.type === "string"
                              ? _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: tab.detail.data,
                                    callback: function ($$v) {
                                      _vm.$set(tab.detail, "data", $$v)
                                    },
                                    expression: "tab.detail.data",
                                  },
                                })
                              : tab.detail.type === "hash" ||
                                tab.detail.type === "zset"
                              ? _c(
                                  "el-table",
                                  { attrs: { data: tab.detail.data } },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        label:
                                          tab.detail.type === "zset"
                                            ? "member"
                                            : "key",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _c("div", [
                                                  _vm._v(_vm._s(row.key)),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        label:
                                          tab.detail.type === "zset"
                                            ? "score"
                                            : "value",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _c("div", [
                                                  _vm._v(_vm._s(row.value)),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : tab.detail.type === "list" ||
                                tab.detail.type === "set"
                              ? _c(
                                  "el-table",
                                  { attrs: { data: tab.detail.data } },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        label:
                                          tab.detail.type === "set"
                                            ? "member"
                                            : "value",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _c("div", [
                                                  _vm._v(_vm._s(row)),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          ref: "selectRef",
          staticStyle: { width: "100%" },
          attrs: {
            multiple: "",
            clearable: "",
            filterable: "",
            placeholder: "请选择",
            "value-key": "id",
          },
          on: { change: _vm.changeData },
          model: {
            value: _vm.sValue,
            callback: function ($$v) {
              _vm.sValue = $$v
            },
            expression: "sValue",
          },
        },
        _vm._l(_vm.optionGroup, function (optionData, index) {
          return _c(
            "el-option-group",
            { key: index, attrs: { label: optionData.name } },
            _vm._l(optionData.identity, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item },
              })
            }),
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.code" placeholder="编码" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.execute_script" placeholder="脚本" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-select v-model="listQuery.status" placeholder="状态" clearable>
          <el-option v-for="item in statusOptions" :key="item.key" :label="item.display_name" :value="item.key" />
        </el-select>
      </el-col>

      <el-col :span="8">
        <el-button v-waves type="primary" icon="el-icon-search" :disabled="importLoading" @click="handleFilter">
          搜索
        </el-button>
        <el-button style="margin-left: 10px;" type="primary" icon="el-icon-edit" :disabled="importLoading" @click="handleCreate">
          添加
        </el-button>
        <el-button style="margin-left: 10px;" type="danger" icon="el-icon-download" :disabled="importLoading" @click="importServerData">
          服务器导入
        </el-button>
      </el-col>
    </el-row>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="名称" align="center">
        <template slot-scope="{row}">
          <div>名称：<b>{{ row.name }}</b></div>
          <div>编码：<el-link :underline="false" type="primary">{{ row.code }}</el-link></div>
        </template>
      </el-table-column>
      <el-table-column label="脚本" min-width="168px" align="left">
        <template slot-scope="{row}">
          <div>{{ row.timing_script }}</div>
          <div>{{ row.execute_script }}</div>
        </template>
      </el-table-column>
      <el-table-column label="服务" align="center">
        <template slot-scope="{row}">
          <div>{{ row.server }}</div>
          <div>
            <el-tag :type="row.status === 1 ? 'success' : (row.status === 2 ? 'info' : 'warning')">{{ row.status | statusFilter }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="时间" width="168px" align="center">
        <template slot-scope="{row}">
          <div>创建于：{{ row.create_date }}</div>
          <div>更新于：{{ row.updated_at > 0 ? row.updated_date : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="{row,$index}">
          <el-dropdown :split-button="false">
            <span class="el-dropdown-link">
              操作<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu v-if="row.status===1" slot="dropdown">
              <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
              <el-dropdown-item @click.native="handleModifyStatus(row, 2, $index)">停用</el-dropdown-item>
              <el-dropdown-item @click.native="seeLog(row.execute_script, row.name)">日志</el-dropdown-item>
            </el-dropdown-menu>
            <el-dropdown-menu v-if="row.status===2" slot="dropdown">
              <el-dropdown-item @click.native="handleModifyStatus(row, 1, $index)">启动</el-dropdown-item>
              <el-dropdown-item @click.native="handleModifyStatus(row, 3, $index)">删除</el-dropdown-item>
              <el-dropdown-item @click.native="seeLog(row.execute_script, row.name)">日志</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="temp.code" maxlength="8" show-word-limit />
        </el-form-item>
        <el-form-item label="定时脚本" prop="timing_script">
          <el-input v-model="temp.timing_script" maxlength="32" show-word-limit />
          <el-link :underline="false" href="https://tool.lu/crontab/" target="_blank" type="primary">CRON表达式</el-link>
        </el-form-item>
        <el-form-item label="执行命令" prop="execute_script">
          <el-input v-model="temp.execute_script" maxlength="128" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          确认
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="logTitle" :visible.sync="dialogLog">
      <el-row :gutter="6">
        <el-col :span="8">
          <el-select v-model="logQuery.dir" placeholder="请选择" @change="seeLogData">
            <el-option
              v-for="item in logData.dirArray"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="logQuery.file" placeholder="请选择" @change="seeLogData">
            <el-option
              v-for="item in logData.fileArray"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="logQuery.line" placeholder="请选择" @change="seeLogData">
            <el-option
              v-for="item in logLine"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-col>
      </el-row>
      <div class="log-content">
        <div class="log-content-body">{{ logData.content }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cronList, createCron, updateCron, updateStatus, importData, logData } from '@/api/config-center'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

const statusOptions = [
  { key: '', display_name: '全部' },
  { key: 1, display_name: '启用' },
  { key: 2, display_name: '停用' }
]
// arr to obj, such as { CN : "China", US : "USA" }
const statusTypeKeyValue = statusOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name
  return acc
}, {})

export default {
  name: 'Cron',
  components: { Pagination },
  directives: { waves },
  filters: {
    statusFilter(status) {
      if (statusTypeKeyValue[status]) {
        return statusTypeKeyValue[status]
      }
      return '未知'
    }
  },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        status: 1
      },
      statusOptions,
      temp: {
        id: undefined,
        name: '',
        code: '',
        timing_script: '',
        execute_script: '',
        server: '',
        status: 0,
        create_at: 0,
        updated_at: 0
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{ required: true, message: '名称必须填写', trigger: 'change' }],
        code: [{ type: 'string', required: true, message: '编码必须填写', trigger: 'change' }],
        timing_script: [{ required: true, message: '定时脚本必须填写', trigger: 'blur' }],
        execute_script: [{ required: true, message: '执行命令必须填写', trigger: 'blur' }]
      },
      importLoading: false,
      dialogLog: false,
      logTitle: '',
      logQuery: {
        script: '',
        dir: '',
        file: '',
        line: 100
      },
      logData: {
        name: '',
        dirArray: [],
        fileArray: [],
        dir: '',
        file: '',
        showLine: 100,
        content: ''
      },
      logLine: [100, 200, 500, 1000]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      cronList(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        name: '',
        code: '',
        timing_script: '',
        execute_script: '',
        server: '',
        status: 0,
        create_at: 0,
        updated_at: 0
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          createCron(this.temp).then((response) => {
            this.temp = response.data
            this.list.unshift(this.temp)
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleModifyStatus(row, status, index) {
      updateStatus({ id: row.id, status: status }).then(response => {
        if (status === 3) {
          this.list.splice(index, 1)
        } else {
          this.getList()
        }

        this.$notify({
          // title: 'Success',
          message: response.message,
          type: 'success',
          duration: 1500
        })
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          updateCron(tempData).then((response) => {
            const index = this.list.findIndex(v => v.id === this.temp.id)
            this.temp = response.data
            this.list.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    seeLogData() {
      const tempData = Object.assign({}, this.logQuery)
      logData(tempData).then(response => {
        this.dialogLog = true
        this.logData = response.data
        this.logQuery.dir = response.data.dir
        this.logQuery.file = response.data.file
      })
    },
    seeLog(s, name) {
      this.logQuery.script = s
      this.logTitle = name
      this.seeLogData()
    },
    importServerData() {
      this.importLoading = true
      this.listLoading = true
      importData().then(response => {
        let type = 'success'
        if (response.status === 0) {
          this.getList()
        } else {
          type = 'error'
        }
        this.$notify({
          message: response.message,
          type: type,
          duration: 1500
        })
        setTimeout(() => {
          this.importLoading = false
          this.listLoading = false
        }, 0.5 * 1000)
      })
    }
  }
}
</script>

<style scoped>
  .log-content {
    margin-top: 12px;
    height: 400px;
    background: #000000;
    color: #ffffff;
    padding: 8px;
    overflow-y: scroll;
  }
  .log-content-body {
    white-space: pre-wrap;
  }
</style>


import Cookies from 'js-cookie'

const TokenKey = 'hsxx_token'

// eslint-disable-next-line no-unused-vars
const host = document.domain
const m = host.split('.')
// eslint-disable-next-line no-unused-vars
let domain = ''
for (let i = 1; i < m.length; i++) {
  domain += '.' + m[i]
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { domain: domain })
}

export function removeToken() {
  return Cookies.remove(TokenKey, { domain: domain })
}

<template>
  <div>
    <el-select v-model="sValue" :disabled="disabled" filterable placeholder="请选择" @change="changeData">
      <el-option
        v-for="(item, $index) in options"
        :key="$index"
        :label="item.value"
        :value="item.key"
      />
    </el-select>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sValue: '',
      options: [
        { key: 10, value: 'tinyint' },
        { key: 11, value: 'smallint' },
        { key: 12, value: 'mediumint' },
        { key: 13, value: 'int' },
        { key: 14, value: 'bigint' },
        { key: 20, value: 'float' },
        { key: 21, value: 'double' },
        { key: 22, value: 'decimal' },
        { key: 30, value: 'date' },
        { key: 31, value: 'datetime' },
        { key: 40, value: 'char' },
        { key: 41, value: 'varchar' },
        { key: 50, value: 'text' },
        { key: 51, value: 'mediumtext' },
        { key: 52, value: 'longtext' }
      ]
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.sValue = val
      }
    },
    disabled: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {}
    }
  },
  methods: {
    changeData(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="app-container">
    <el-container class="redis-manager-container">
      <el-aside width="320px">
        <div>
          <el-input v-model="filterText" placeholder="搜索分类" style="margin-bottom:2px;" />
        </div>
        <div>
          <el-tree
            ref="typeTreeData"
            :data="typeTreeData"
            :props="defaultProps"
            :filter-node-method="filterNode"
            class="filter-tree"
            :highlight-current="true"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            node-key="id"
            :default-expanded-keys="defaultData"
            @node-click="nodeClick"
          >
            <span slot-scope="{ node }" class="custom-tree-node">
              <el-tooltip class="item" effect="dark" :content="node.label" :disabled="node.label | hoverStr(13)" placement="top-start">
                <span> {{ node.label }} </span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
      </el-aside>

      <el-main class="main-tabs-content">
        <div>
          <el-button type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>
        </div>
        <div style="margin-top: 12px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, i) in checkTypeArray" :key="i">
              <el-link v-show="i!==checkTypeArray.length-1" :underline="false" type="primary" @click="breadcrumbClick(item.id, item.name)">{{ item.name }}</el-link>
              <span v-show="i===checkTypeArray.length-1">{{ item.name }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <el-table
          ref="tableList"
          :data="list"
          :height="tableHeight"
          fit
          highlight-current-row
          style="width: 100%; overflow: auto !important;"
        >
          <el-table-column label="名称" align="center">
            <template slot-scope="{row}">
              <div>{{ row.label }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{row, $index}">
              <el-button v-show="row.id>0" size="mini" type="primary" style="margin-right: 12px" @click="handleUpdate(row)">编辑</el-button>
              <el-popover
                v-show="!row.children"
                :ref="`popover` + $index"
                placement="top"
                width="240"
                :tabindex="$index"
              >
                <p>删除{{ row.label }} 吗？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="handleCancelVisible($index)">取消</el-button>
                  <el-button size="mini" type="danger" @click="handleDel(row.id, row.name, $index)">确定</el-button>
                </div>
                <el-button slot="reference" size="mini">删除</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="目录">
          <el-select
            ref="selectUpResId"
            v-model="tempParent.id"
            clearable
            placeholder="请选择"
            style="width: 100%"
            prop="parent_id"
            @clear="handleClear"
          >
            <el-option key="parentId" hidden :value="tempParent.id" :label="tempParent.name" />
            <el-tree
              ref="treeDataDirCheck"
              :data="typeTreeData"
              :props="defaultProps"
              :highlight-current="true"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              node-key="id"
              :default-expanded-keys="defaultData"
              @node-click="nodeClickCheck"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { goodsAdminCategoryListApi, goodsAdminAddCategoryApi, goodsAdminUpdateCategoryApi, goodsAdminDeleteCategoryApi } from '@/api/niche-goods'
import { ellipsis, ellipsisBool } from '@/utils/validate'

export default {
  name: 'NicheCategory',
  filters: {
    hoverStr(str, len) {
      return !ellipsisBool(str, len)
    },
    hoverStrOmit(str, len) {
      return ellipsis(str, len)
    }
  },
  data() {
    return {
      checkTypeArray: [{ name: '全部' }],
      typeTreeData: [{ id: 0, label: '全部' }],
      filterText: '',
      defaultData: [0, 0],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableHeight: 300,
      list: null,
      temp: {
        id: undefined,
        parent_id: undefined,
        label: '',
        name: ''
      },
      tempParent: { id: 0, name: '全部' },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{ required: true, message: '名称必须填写', trigger: 'change' }]
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.typeTreeData.filter(val)
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 30
      // 监听窗口大小变化
      window.onresize = function() {
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 30
      }
    })
  },
  created() {
    this.getTreeData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getTreeData() {
      goodsAdminCategoryListApi().then(response => {
        this.typeTreeData = response.data
        this.list = response.data[0].children
      })
    },
    updateTreeData() {
      goodsAdminCategoryListApi().then(response => {
        this.typeTreeData = response.data
        this.breadcrumbClick(this.defaultData[1])
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    nodeClick(obj, node, selfObj) {
      const typeArray = [{ name: obj.name }]
      let parentData = node.parent
      while (parentData) {
        if (parentData.parent) {
          typeArray.unshift({ id: parentData.data.id, name: parentData.data.name })
        }
        parentData = parentData.parent
      }
      this.checkTypeArray = typeArray
      this.defaultData[1] = obj.id
      this.tempParent = { id: obj.id, name: obj.name }
      this.list = obj.children
    },
    breadcrumbClick(id, name) {
      const typeArray = []
      let listData = this.typeTreeData
      for (const i in this.checkTypeArray) {
        for (const j in listData) {
          if (listData[j].id === this.checkTypeArray[i].id) {
            listData = listData[j].children
            break
          }
          if (listData[j].id === id) {
            listData = listData[j].children
            name = this.checkTypeArray[i].name
            break
          }
        }

        if (id !== this.checkTypeArray[i].id) {
          typeArray.push(this.checkTypeArray[i])
        } else {
          typeArray.push({ name: name })
          break
        }
      }

      this.checkTypeArray = typeArray
      this.defaultData[1] = id
      this.tempParent = { id: id, name: name }
      this.list = listData
    },
    nodeClickCheck(obj, node, selfObj) {
      this.tempParent = { id: obj.id, name: obj.name }
      this.$refs.selectUpResId.blur()
    },
    handleClear() {
      // 将选择器的值置空
      this.tempParent = { id: 0, name: '全部' }
    },
    resetTemp() {
      this.temp = { id: undefined, parent_id: undefined, label: '', name: '' }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.parent_id = this.tempParent.id
          goodsAdminAddCategoryApi(this.temp).then((response) => {
            this.temp = response.data
            this.temp.label = this.temp.name
            this.updateTreeData()
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.temp.id === this.tempParent.id) {
            // 同一个目录下编辑自己
            this.$message({
              message: '不能迁移自己到自己目录',
              type: 'error',
              duration: 1500
            })
          } else {
            this.temp.parent_id = this.tempParent.id
            const tempData = Object.assign({}, this.temp)
            goodsAdminUpdateCategoryApi(tempData).then((response) => {
              this.temp = response.data
              this.temp.label = this.temp.name
              this.updateTreeData()
              this.dialogFormVisible = false
              this.$message({
                // title: 'Success',
                message: response.message,
                type: 'success',
                duration: 1500
              })
            })
          }
        }
      })
    },
    handleCancelVisible(index) {
      this.$refs[`popover` + index].doClose()
    },
    handleDel(id, name, index) {
      this.$refs[`popover` + index].doClose()
      goodsAdminDeleteCategoryApi({ id: id }).then(response => {
        this.updateTreeData()
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>
  .main-tabs-content {
    padding: 0 20px 20px 8px !important;
  }
  .redis-manager-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .redis-manager-container .el-pagination {
    text-align: center;
    padding-top: 20px;
  }
  .tab-key-desc span {
    margin-right: 20px;
  }
  .redis-manager-container .tip {
    padding: 10px 0;
    text-align: center;
    font-family: "Hiragino Sans GB";
    font-size: 12px;
  }
</style>


<template>
  <div class="app-container">
    <el-container class="organization-el-container">
      <el-aside width="240px" style="overflow-y: hidden">
        <organization-tree-list-menu :height="(tableHeight + 20) + `px`" :refresh="refreshTree" @nodeClick="nodeClick" @treeData="treeData" />
      </el-aside>

      <el-main class="main-tabs-content">
        <user-table-list :del-height="delHeight" :refresh-user-list="refreshUserList" :now-organization="nowOrganization" @tableHeightData="tableHeightData" @tableListData="tableListData" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import OrganizationTreeListMenu from '@/components/UserCenter/Organization/TreeListMenu'
import UserTableList from '@/components/UserCenter/User/TableList'

export default {
  name: 'UserOrganizationUser',
  components: { OrganizationTreeListMenu, UserTableList },
  data() {
    return {
      nowOrganization: { id: '', name: '', code: '', is_system: 0 },
      refreshTree: false,
      delHeight: 48,
      tableHeight: 300,
      refreshUserList: false
    }
  },
  methods: {
    treeData(data) {
      this.refreshTree = false

      if (this.nowOrganization.name === '') {
        this.nowOrganization = data[0]
      }

      // 加载用户列表
      this.refreshUserList = true
    },
    refreshTreeData() {
      // 更新子组件的数据
      this.refreshTree = true
    },
    nodeClick(obj, node, selfObj) {
      // 重置分页数据
      this.listQuery = {
        page: 1,
        limit: 20,
        name: ''
      }
      this.nowOrganization = obj
      this.refreshUserList = true
    },
    tableHeightData(height) {
      this.tableHeight = height
    },
    tableListData() {
      this.refreshUserList = false
    }
  }
}
</script>

<style scoped>
  .app-container {
    padding-bottom: 0 !important;
  }
  .main-tabs-content {
    padding: 0 20px 0 8px !important;
  }
  .organization-el-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
</style>

<template>
  <div class="app-container">
    <el-container class="redis-manager-container">
      <el-aside width="380px">
        <el-row :gutter="8" style="margin-bottom: 12px">
          <el-col :span="12">
            <el-select v-model="listQuery.db" placeholder="请选择" @change="handleFilter">
              <el-option
                v-for="item in dbArray"
                :key="item.db"
                :label="`DB`+item.db"
                :value="item.db"
              />
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-input v-model="keysTotal" :disabled="true" />
          </el-col>
        </el-row>
        <el-row :gutter="8" style="margin-bottom: 12px">
          <el-col>
            <el-input v-model="listQuery.key" placeholder="key" clearable @keyup.enter.native="handleFilter" />
          </el-col>
        </el-row>

        <el-table
          ref="tableList"
          v-loading="listLoading"
          :data="list"
          :height="tableHeight"
          border
          fit
          highlight-current-row
          style="width: 100%; overflow: auto !important;"
        >
          <el-table-column label="键名" align="center">
            <template slot-scope="{row}">
              <div>
                <el-link :underline="false" @dblclick.native="handleDetail(row.db, row.key)">{{ row.key }}</el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="80px">
            <template slot-scope="{row, $index}">
              <el-popover
                :ref="`popover` + $index"
                placement="top"
                width="240"
                :tabindex="$index"
              >
                <p>删除DB{{ row.db }}: {{ row.key }} 吗？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="handleCancelVisible($index)">取消</el-button>
                  <el-button size="mini" type="danger" @click="handleDel(row.db, row.key, $index)">确定</el-button>
                </div>
                <el-button slot="reference" size="mini">删除</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          v-show="total>0"
          background
          layout="prev, pager, next"
          :page-count="cursorArray.length"
          :current-page="listQuery.page"
          :page-size="listQuery.limit"
          @current-change="currentList"
        />
        <div class="tip"><span>*最多显示 3000 条数据</span></div>
      </el-aside>

      <el-main class="main-tabs-content">
        <el-tabs v-model="editableTabsValue" type="card" @tab-remove="removeDetailTab">
          <el-tab-pane label="首页" name="index">
            <div v-if="indexData">
              <el-descriptions title="实例基本信息">
                <el-descriptions-item label="版本号">{{ indexData.version }}</el-descriptions-item>
                <el-descriptions-item label="运行模式">{{ indexData.start_mode }}</el-descriptions-item>
                <el-descriptions-item label="服务监听端口">{{ indexData.port }}</el-descriptions-item>
                <el-descriptions-item label="DB数">{{ indexData.db_total }}</el-descriptions-item>
                <el-descriptions-item label="有效期Key数量">{{ indexData.expires_total }}</el-descriptions-item>
                <el-descriptions-item label="Key总数">{{ indexData.keys_total }}</el-descriptions-item>
                <el-descriptions-item label="已运行时间">{{ indexData.runtime }}</el-descriptions-item>
              </el-descriptions>
              <el-divider />
              <el-descriptions title="内存使用情况">
                <el-descriptions-item label="数据内存">{{ indexData.used_memory }}</el-descriptions-item>
                <el-descriptions-item label="服务内存">{{ indexData.used_memory_rss }}</el-descriptions-item>
                <el-descriptions-item label="消耗峰值">{{ indexData.used_memory_peak }}</el-descriptions-item>
                <el-descriptions-item label="使用峰值百分比">{{ indexData.used_memory_peak_perc }}</el-descriptions-item>
                <el-descriptions-item label="服务器总量">{{ indexData.total_system_memory }}</el-descriptions-item>
                <el-descriptions-item label="数据使用百分比">
                  <el-tag size="small" :type="indexData.used_memory_perc | userMemoryTagFilter">{{ indexData.used_memory_perc }}%</el-tag>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </el-tab-pane>

          <el-tab-pane v-for="(tab) in detailTabs" :key="tab.name" :label="tab.label" :name="tab.name" closable>
            <div class="tab-key-desc">
              <span>库：<b>{{ tab.db }}</b></span>
              <span>键名：<b>{{ tab.key }}</b></span>
              <span>类型：<b>{{ tab.detail.type }}</b></span>
              <span>TTL：<b>{{ tab.detail.ttl }}</b></span>
            </div>
            <el-divider />
            <div>
              <el-input v-if="tab.detail.type==='string'" v-model="tab.detail.data" :disabled="true" />
              <el-table v-else-if="tab.detail.type==='hash'||tab.detail.type==='zset'" :data="tab.detail.data">
                <el-table-column :label="tab.detail.type==='zset'?'member':'key'" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.key }}</div>
                  </template>
                </el-table-column>
                <el-table-column :label="tab.detail.type==='zset'?'score':'value'" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.value }}</div>
                  </template>
                </el-table-column>
              </el-table>
              <el-table v-else-if="tab.detail.type==='list'||tab.detail.type==='set'" :data="tab.detail.data">
                <el-table-column :label="tab.detail.type==='set'?'member':'value'" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>

  </div>
</template>

<script>
import { redisIndexApi, redisListApi, redisDetailApi, redisDelApi } from '@/api/config-center'
import waves from '@/directive/waves' // waves directive

export default {
  name: 'Variable',
  directives: { waves },
  filters: {
    userMemoryTagFilter(p) {
      if (p < 20) {
        return 'success'
      }
      if (p < 40) {
        return 'warning'
      }
      return 'danger'
    }
  },
  data() {
    return {
      indexData: null,
      editableTabsValue: 'index',
      detailTabs: [],
      dbArray: [],
      list: null,
      keysTotal: '',
      total: 0,
      listLoading: true,
      tableHeight: 300,
      cursorArray: [0],
      listQuery: {
        page: 1,
        cursor: 0,
        limit: 100,
        db: 1,
        key: ''
      },
      delVisible: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - this.$refs.tableList.$el.offsetTop - 64 - 120
      // 监听窗口大小变化
      window.onresize = () => {
        this.tableHeight = window.innerHeight - this.$refs.tableList.$el.offsetTop - 64 - 120
      }
    })
  },
  created() {
    this.getIndexData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getIndexData() {
      redisIndexApi().then(response => {
        this.indexData = response.data
        this.dbArray = response.data.db_array
        this.listQuery.db = this.indexData.max_db.db
        this.getList()
      })
    },
    getList() {
      this.listLoading = true
      redisListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total
        if (this.listQuery.page === 1) {
          this.cursorArray = response.data.cursor
        }
        this.keysTotal = `Keys: ` + response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.listQuery.cursor = 0
      this.getList()
    },
    currentList(p) {
      this.listQuery.page = p
      this.listQuery.cursor = this.cursorArray[p - 1]
      this.getList()
    },
    handleDetail(db, key) {
      redisDetailApi({ db: db, key: key }).then(response => {
        const label = 'DB' + db + ': ' + key
        const name = db + '-' + key
        if (this.editableTabsValue === 'index') {
          // 新增新tab
          this.detailTabs.push({ label: label, name: name, db: db, key: key, detail: response.data })
        } else {
          // 覆盖当前tab
          for (const tab in this.detailTabs) {
            if (this.detailTabs[tab].name === this.editableTabsValue) {
              this.detailTabs[tab] = { label: label, name: name, db: db, key: key, detail: response.data }
              break
            }
          }
        }
        this.editableTabsValue = name
      })
    },
    removeDetailTab(name) {
      const tabs = this.detailTabs
      let activeName = this.editableTabsValue
      if (activeName === name) {
        tabs.forEach((tab, index) => {
          if (tab.name === name) {
            const nextTab = tabs[index - 1] || tabs[index + 1]
            if (nextTab) {
              activeName = nextTab.name
            } else {
              activeName = 'index'
            }
          }
        })
      }

      this.editableTabsValue = activeName
      this.detailTabs = tabs.filter(tab => tab.name !== name)
    },
    handleCancelVisible(index) {
      this.$refs[`popover` + index].doClose()
    },
    handleDel(db, key, index) {
      this.$refs[`popover` + index].doClose()
      redisDelApi({ db: db, key: key }).then(response => {
        const name = db + '-' + key
        this.removeDetailTab(name)
        this.getList()
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>
  .main-tabs-content {
    padding: 0 20px 20px 8px !important;
  }
  .redis-manager-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .redis-manager-container .el-pagination {
    text-align: center;
    padding-top: 20px;
  }
  .tab-key-desc span {
    margin-right: 20px;
  }
  .redis-manager-container .tip {
    padding: 10px 0;
    text-align: center;
    font-family: "Hiragino Sans GB";
    font-size: 12px;
  }
</style>


<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route() {
      // 百度统计添加
      if (window._hmt) {
        const location = window.location
        const contentUrl = location.pathname + location.hash
        window._hmt.push(['_trackPageview', '/' + contentUrl])
      }
    }
  },
  mounted() {
    // 百度统计添加
    const script = document.createElement('script')
    script.src = process.env.VUE_APP_BAIDU_TONGJI_URL
    script.language = 'JavaScript'
    document.body.appendChild(script)
  }
}
</script>

<template>
  <div>
    <el-container class="redis-manager-container">
      <el-aside width="160px">
        <div>
          <el-input v-model="filterText" placeholder="搜索权限" style="margin-bottom:2px;" />
        </div>
        <div>
          <el-tree
            ref="powerTreeData"
            :data="typeTreeData"
            :props="defaultProps"
            :filter-node-method="filterNode"
            class="filter-tree"
            :highlight-current="true"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            node-key="id"
            :default-expanded-keys="defaultData"
            @node-click="nodeClick"
          >
            <span slot-scope="{ node }" class="custom-tree-node">
              <el-tooltip class="item" effect="dark" :content="node.label" :disabled="node.label | hoverStr(13)" placement="top-start">
                <span> {{ node.label }} </span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
      </el-aside>

      <el-main class="main-tabs-content">
        <el-row :gutter="4">
          <el-col :span="8">
            <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="8">
            <el-input v-model="listQuery.code" placeholder="编码" clearable @keyup.enter.native="handleFilter" />
          </el-col>

          <el-col :span="8">
            <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          </el-col>
        </el-row>

        <div style="margin-top: 12px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, i) in checkTypeArray" :key="i">
              <el-link v-show="i!==checkTypeArray.length-1" :underline="false" type="primary" @click="breadcrumbClick(item.id, item.name)">{{ item.name }}</el-link>
              <span v-show="i===checkTypeArray.length-1">{{ item.name }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <el-table
          :ref="`tableList` + listQuery.parent_id"
          v-loading="listLoading"
          :data="list"
          :height="tableHeight"
          fit
          highlight-current-row
          tooltip-effect="dark"
          style="width: 100%; overflow: auto !important; padding:0 0 0 10px;"
          @selection-change="handleSelectionChange"
          @expand-change="handleExpandChange"
        >
          <el-table-column type="selection" width="45" />
          <el-table-column type="expand">
            <template slot-scope="prow">
              <el-table
                :ref="`multipleTable` + prow.row.id"
                :data="prow.row.children"
                fit
                highlight-current-row
                tooltip-effect="dark"
                @selection-change="handleSelectionChangeBack($event, prow.row.id)"
              >
                <el-table-column type="selection" width="45" />
                <el-table-column label="权限" align="center">
                  <template slot-scope="cData">
                    <div>{{ cData.row.name }}</div>
                    <div>{{ cData.row.code }} <el-tag v-show="cData.row.is_system===1">系统</el-tag></div>
                  </template>
                </el-table-column>
                <el-table-column label="路由" align="center">
                  <template slot-scope="cData">
                    <div>name: {{ cData.row.en_name }}</div>
                    <div>path: {{ cData.row.path }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="参数" align="center">
                  <template slot-scope="cData">
                    <div>{{ cData.row.params }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="名称" align="center">
            <template slot-scope="{row}">
              <div>{{ row.name }}</div>
              <div>{{ row.code }} <el-tag v-show="row.is_system===1">系统</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="路由path" align="center">
            <template slot-scope="{row}">
              <div>{{ row.path }}</div>
            </template>
          </el-table-column>
          <el-table-column label="路由name" align="center">
            <template slot-scope="{row}">
              <div>{{ row.en_name }}</div>
            </template>
          </el-table-column>
        </el-table>

        <pagination v-show="total>0" :total="total" :layout="`total, prev, pager, next`" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListData" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { powerTreeListApi, powerListApi } from '@/api/user-center'
import { ellipsis, ellipsisBool } from '@/utils/validate'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

export default {
  components: { Pagination },
  filters: {
    hoverStr(str, len) {
      return !ellipsisBool(str, len)
    },
    hoverStrOmit(str, len) {
      return ellipsis(str, len)
    }
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    roleId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      checkTypeArray: [{ name: '全部' }],
      typeTreeData: [{ id: 0, label: '全部' }],
      filterText: '',
      defaultData: [0, 0],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableHeight: 300,
      listLoading: false,
      list: [],
      listQuery: {
        page: 1,
        limit: 10,
        parent_id: 0,
        name: ''
      },
      total: 0,
      checkedAllPowerId: [], // 翻了的有效页面
      checkedAllPowerBackId: [], // 翻了的有效页面 - 后端
      checkedPowerId: [], // 选中了的前端权限ID数据
      checkedPowerBackId: [], // 选中了的后端权限ID数据
      autoParentId: [], // 自动选中的父ID数据
      isComplete: false,
      isCompleteBack: false
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {}
    },
    roleId: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.resetAllData()
        this.getListData()
      }
    },
    filterText(val) {
      this.$refs.powerTreeData.filter(val)
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this

      self.tableHeight = window.innerHeight - self.$refs[`tableList` + self.listQuery.parent_id].$el.offsetTop - 260 - 76
      // 监听窗口大小变化
      window.onresize = () => {
        self.tableHeight = window.innerHeight - self.$refs[`tableList` + self.listQuery.parent_id].$el.offsetTop - 260 - 76
      }
    })
  },
  created() {
    this.getTreeData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getTreeData() {
      powerTreeListApi().then(response => {
        this.typeTreeData = response.data
      })
    },
    getListData() {
      this.isComplete = false
      this.listLoading = true
      this.listQuery.role_id = this.roleId
      powerListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // 前台路由
        if (!this.checkedPowerId[this.listQuery.parent_id]) {
          this.checkedPowerId[this.listQuery.parent_id] = []
        }
        if (!this.checkedPowerId[this.listQuery.parent_id][this.listQuery.page]) {
          this.checkedPowerId[this.listQuery.parent_id][this.listQuery.page] = response.data.rolePowerArray
        }
        if (!this.checkedAllPowerId[this.listQuery.parent_id]) {
          this.checkedAllPowerId[this.listQuery.parent_id] = []
        }
        this.checkedAllPowerId[this.listQuery.parent_id][this.listQuery.page] = this.list

        // 后台路由
        for (const i in this.list) {
          if (!this.checkedPowerBackId[this.list[i].id]) {
            this.checkedPowerBackId[this.list[i].id] = this.list[i].rolePowerBackArray
          }
          if (!this.checkedAllPowerBackId[this.list[i].id]) {
            this.checkedAllPowerBackId[this.list[i].id] = this.list[i].children
          }
        }

        this.$nextTick(() => {
          // 前台路由勾选
          this.checkedPowerId[this.listQuery.parent_id][this.listQuery.page].forEach(row => {
            this.$refs[`tableList` + this.listQuery.parent_id].toggleRowSelection(this.list.find(item => {
              return row.id === item.id // 注意这里寻找的字段要唯一，示例仅参考
            }), true)
          })

          // 渲染完毕
          this.isComplete = true

          // Just to simulate the time of the request
          setTimeout(() => {
            this.listLoading = false
          }, 0.5 * 1000)
        })
      })
    },
    handleFilter() {
      this.getListData()
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    resetAllData() {
      this.resetListQuery()
      this.checkedPowerId = []
      this.checkedAllPowerId = []
    },
    resetListQuery() {
      this.listQuery = {
        page: 1,
        limit: 10,
        parent_id: 0,
        name: ''
      }
    },
    nodeClick(obj, node, selfObj) {
      this.resetListQuery()
      const typeArray = [{ name: obj.name }]
      let parentData = node.parent
      while (parentData) {
        if (parentData.parent) {
          typeArray.unshift({ id: parentData.data.id, name: parentData.data.name })
        }
        parentData = parentData.parent
      }
      this.checkTypeArray = typeArray
      this.defaultData[1] = obj.id

      this.listQuery.parent_id = obj.id
      this.handleFilter()
    },
    breadcrumbClick(id, name) {
      const typeArray = []
      let listData = this.typeTreeData
      for (const i in this.checkTypeArray) {
        for (const j in listData) {
          if (listData[j].id === this.checkTypeArray[i].id) {
            listData = listData[j].children
            break
          }
          if (listData[j].id === id) {
            listData = listData[j].children
            name = this.checkTypeArray[i].name
            break
          }
        }

        if (id !== this.checkTypeArray[i].id) {
          typeArray.push(this.checkTypeArray[i])
        } else {
          typeArray.push({ name: name })
          break
        }
      }

      this.checkTypeArray = typeArray
      this.defaultData[1] = id

      this.listQuery.parent_id = id
      this.handleFilter()
    },
    // 展开、关闭 后台权限表格
    handleExpandChange(row, data) {
      if (data.length > 0) {
        // 展开，渲染后台权限选择
        // 后台路由权限勾选
        this.$nextTick(() => {
          this.checkedPowerBackId[row.id].forEach(crow => {
            this.$refs[`multipleTable` + row.id].toggleRowSelection(row.children.find(item => {
              return crow.id === item.id // 注意这里寻找的字段要唯一，示例仅参考
            }), true)
          })
        })
      }
    },
    handleSelectionChange(val) {
      if (this.isComplete) {
        if (val.length > 0) {
          // 所有添加父祖数据
          if (!this.autoParentId.includes(this.listQuery.parent_id)) {
            this.autoParentId.push(this.listQuery.parent_id)
          }
          const cl = this.checkTypeArray.length
          if (cl > 2) {
            for (let cli = cl - 2; cli > 0; cli--) {
              if (!this.autoParentId.includes(this.checkTypeArray[cli].id)) {
                this.autoParentId.push(this.checkTypeArray[cli].id)
              }
            }
          }
        } else {
          // 只移除父数据
          const autoIndex = this.autoParentId.indexOf(this.listQuery.parent_id)
          if (autoIndex > -1) {
            this.autoParentId.splice(autoIndex, 1)
          }
        }

        this.checkedPowerId[this.listQuery.parent_id][this.listQuery.page] = val
        this.$emit('checkedPowerData', { powerArray: this.checkedPowerId, allPowerArray: this.checkedAllPowerId, autoParentId: this.autoParentId })
      }
    },
    handleSelectionChangeBack(val, id) {
      if (this.isComplete) {
        this.checkedPowerBackId[id] = val
        this.$emit('checkedPowerBackData', { powerBackArray: this.checkedPowerBackId, allPowerBackArray: this.checkedAllPowerBackId })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-tabs-content {
    padding: 0 0 0 8px !important;
    overflow: hidden;
  }
</style>

<template>
  <div class="app-container">
    <el-container class="dev-manager-container">
      <el-aside width="180px">
        <div>
          <el-input v-model="filterText" placeholder="搜索菜单" style="margin-bottom:2px;" />
        </div>
        <div>
          <el-tree
            ref="powerTreeData"
            :data="typeTreeData"
            :props="defaultProps"
            :filter-node-method="filterNode"
            class="filter-tree"
            :highlight-current="true"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            node-key="id"
            :default-expanded-keys="defaultData"
            @node-click="nodeClick"
          >
            <span slot-scope="{ node }" class="custom-tree-node">
              <el-tooltip class="item" effect="dark" :content="node.label" :disabled="node.label | hoverStr(13)" placement="top-start">
                <span> {{ node.label }} </span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
      </el-aside>

      <el-main class="main-tabs-content">
        <el-row :gutter="8">
          <el-col :span="4">
            <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="3">
            <el-input v-model="listQuery.code" placeholder="编码" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="listQuery.path" placeholder="路由path" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="5">
            <el-input v-model="listQuery.en_name" placeholder="路由name" clearable @keyup.enter.native="handleFilter" />
          </el-col>

          <el-col :span="8">
            <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
            <el-button style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>
          </el-col>
        </el-row>

        <div style="margin-top: 12px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, i) in checkTypeArray" :key="i">
              <el-link v-show="i!==checkTypeArray.length-1" :underline="false" type="primary" @click="breadcrumbClick(item.id, item.name)">{{ item.name }}</el-link>
              <span v-show="i===checkTypeArray.length-1">{{ item.name }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <el-table
          ref="tableList"
          v-loading="listLoading"
          :data="list"
          :height="tableHeight"
          fit
          highlight-current-row
          style="width: 100%; overflow: auto !important;"
        >
          <el-table-column label="名称" align="center">
            <template slot-scope="{row}">
              <div>{{ row.name }}</div>
              <div>{{ row.code }} <el-tag v-show="row.is_system===1">系统</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="路由" align="center">
            <template slot-scope="{row}">
              <div>{{ row.path }}</div>
              <div>{{ row.en_name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="类型" align="center">
            <template slot-scope="{row}">
              <div>{{ row.type | typeOptionsFilters }}&nbsp;&nbsp;
                <el-tag v-show="row.type===2" type="default">{{ row.view_type | viewTypeOptionsFilters }}</el-tag>&nbsp;&nbsp;
                <el-tag v-show="row.is_hidden===1" type="warning">隐藏</el-tag>
              </div>
              <div v-show="row.type===1">{{ row.layout | layoutOptionsFilters }}</div>
              <div v-show="row.type===2">{{ row.view }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{row, $index}">
              <div v-show="row.is_system!==1">
                <el-dropdown :split-button="false">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down el-icon--right" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
                    <el-dropdown-item v-show="row.type===2" @click.native="handleEditView(row)">编辑视图</el-dropdown-item>
                    <el-dropdown-item>
                      <el-popover
                        :ref="`popover` + $index"
                        placement="top"
                        :tabindex="$index"
                        style="display: block; width: 100%;"
                      >
                        <p>删除 `{{ row.name }}` 吗？</p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text" @click="handleCancelVisible($index)">取消</el-button>
                          <el-button size="mini" type="danger" @click="handleDel(row, $index)">确定</el-button>
                        </div>
                        <span slot="reference" style="display: block; width: 100%;">删除</span>
                      </el-popover>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListData" />
      </el-main>
    </el-container>

    <!--前台权限-->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="temp.code" maxlength="8" show-word-limit />
        </el-form-item>
        <el-form-item label="路由path" prop="path">
          <el-input v-model="temp.path" maxlength="100" show-word-limit />
        </el-form-item>
        <el-form-item label="路由name" prop="en_name">
          <el-input v-model="temp.en_name" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item label="icon" prop="icon">
          <icon-select v-model="temp.icon" :class="temp.icon" />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <common-select v-model="temp.type" :options="typeOptions" :disabled="dialogStatus!=='create'" />
        </el-form-item>
        <el-form-item v-show="temp.type===1" label="重定向" prop="redirect">
          <el-input v-model="temp.redirect" maxlength="100" show-word-limit />
        </el-form-item>
        <el-form-item v-show="temp.type===1" label="布局" prop="layout">
          <common-select v-model="temp.layout" :options="layoutOptions" />
        </el-form-item>
        <el-form-item v-show="temp.type===2" label="视图类型" prop="view_type">
          <common-select v-model="temp.view_type" :options="viewTypeOptions" :disabled="dialogStatus!=='create'" />
        </el-form-item>
        <el-form-item v-show="temp.type===2" label="视图" prop="view">
          <el-input v-model="temp.view" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item label="是否隐藏" prop="is_hidden">
          <el-switch v-model="temp.is_hidden" :active-value="switchData.open" :inactive-value="switchData.close" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { menuListApi, powerListApi, powerAddApi, powerUpdateApi, powerDeleteApi } from '@/api/user-center'
import { ellipsis, ellipsisBool } from '@/utils/validate'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import CommonSelect from '@/components/BaseData/CommonSelect'
import IconSelect from '@/components/BaseData/IconSelect'

const typeOptions = [{ label: '基础布局', value: 1 }, { label: '视图', value: 2 }, { label: '链接', value: 3 }]
const typeOptionsKeyValue = typeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.label
  return acc
}, {})

const layoutOptions = [{ label: '空白', value: '' }, { label: '后台', value: 'index' }]
const layoutOptionsKeyValue = layoutOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.label
  return acc
}, {})

const viewTypeOptions = [{ label: '普通表单', value: 1 }, { label: '流程表单', value: 2 }, { label: '报表', value: 3 }, { label: '自定义', value: 4 }, { label: '大屏', value: 5 }]
const viewTypeOptionsKeyValue = viewTypeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.label
  return acc
}, {})

export default {
  name: 'Development',
  components: { Pagination, CommonSelect, IconSelect },
  filters: {
    hoverStr(str, len) {
      return !ellipsisBool(str, len)
    },
    hoverStrOmit(str, len) {
      return ellipsis(str, len)
    },
    typeOptionsFilters(v) {
      return typeOptionsKeyValue[v]
    },
    layoutOptionsFilters(v) {
      return layoutOptionsKeyValue[v]
    },
    viewTypeOptionsFilters(v) {
      return viewTypeOptionsKeyValue[v]
    }
  },
  data() {
    return {
      switchData: { open: 1, close: 0 },
      typeOptions,
      layoutOptions,
      viewTypeOptions,
      checkTypeArray: [{ name: '全部' }],
      typeTreeData: [{ id: 0, label: '全部' }],
      filterText: '',
      defaultData: [0, 0],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableHeight: 300,
      listLoading: false,
      list: [],
      listQuery: {
        page: 1,
        limit: 20,
        parent_id: 0,
        name: ''
      },
      total: 0,
      temp: {
        id: undefined,
        parent_id: undefined,
        name: '',
        code: '',
        type: 1,
        layout: ''
      },
      tempParent: { id: 0, name: '全部' },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{ required: true, message: '必须填写', trigger: 'change' }],
        path: [{ required: true, message: '必须填写', trigger: 'change' }]
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.powerTreeData.filter(val)
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 10 - 56
      // 监听窗口大小变化
      window.onresize = function() {
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 10 - 56
      }
    })
  },
  created() {
    this.getTreeData()
    this.getListData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getTreeData() {
      menuListApi().then(response => {
        this.typeTreeData = response.data
      })
    },
    getListData() {
      this.listLoading = true
      powerListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.getListData()
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    resetListQuery() {
      this.listQuery = {
        page: 1,
        limit: 20,
        parent_id: 0,
        name: ''
      }
    },
    nodeClick(obj, node, selfObj) {
      this.resetListQuery()
      const typeArray = [{ name: obj.name }]
      let parentData = node.parent
      while (parentData) {
        if (parentData.parent) {
          typeArray.unshift({ id: parentData.data.id, name: parentData.data.name })
        }
        parentData = parentData.parent
      }
      this.checkTypeArray = typeArray
      this.defaultData[1] = obj.id
      this.tempParent = { id: obj.id, name: obj.name }

      this.listQuery.parent_id = obj.id
      this.handleFilter()
    },
    breadcrumbClick(id, name) {
      const typeArray = []
      let listData = this.typeTreeData
      for (const i in this.checkTypeArray) {
        for (const j in listData) {
          if (listData[j].id === this.checkTypeArray[i].id) {
            listData = listData[j].children
            break
          }
          if (listData[j].id === id) {
            listData = listData[j].children
            name = this.checkTypeArray[i].name
            break
          }
        }

        if (id !== this.checkTypeArray[i].id) {
          typeArray.push(this.checkTypeArray[i])
        } else {
          typeArray.push({ name: name })
          break
        }
      }

      this.checkTypeArray = typeArray
      this.defaultData[1] = id
      this.tempParent = { id: id, name: name }

      this.listQuery.parent_id = id
      this.handleFilter()
    },
    handleClear() {
      // 将选择器的值置空
      this.tempParent = { id: 0, name: '全部' }
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        parent_id: undefined,
        name: '',
        code: '',
        type: 1,
        layout: ''
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.parent_id = this.tempParent.id
          powerAddApi(this.temp).then((response) => {
            this.getTreeData()
            this.dialogFormVisible = false
            this.handleFilter()
            this.$message({
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.parent_id = this.tempParent.id
          const tempData = Object.assign({}, this.temp)
          powerUpdateApi(tempData).then((response) => {
            this.getTreeData()
            this.handleFilter()
            this.dialogFormVisible = false
            this.$message({
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleCancelVisible(index) {
      this.$refs[`popover` + index].doClose()
    },
    handleDel(row, index) {
      this.$refs[`popover` + index].doClose()
      powerDeleteApi({ id: row.id }).then(response => {
        this.getTreeData()
        this.handleFilter()
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    },
    handleEditView(row) {
      this.$message({
        message: '功能屏蔽',
        type: 'warning'
      })
      // this.$router.push({
      //   name: 'ViewDevelop',
      //   path: '/config/viewDevelop',
      //   query: { id: row.id, type: row.view_type },
      //   params: { id: row.id, type: row.view_type }
      // })
    }
  }
}
</script>

<style scoped>
  .main-tabs-content {
    padding: 0 20px 0 8px !important;
  }
  .dev-manager-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .dev-manager-container .el-pagination {
    text-align: center;
    padding-top: 20px;
  }
  .tab-key-desc span {
    margin-right: 20px;
  }
  .dev-manager-container .tip {
    padding: 10px 0;
    text-align: center;
    font-family: "Hiragino Sans GB";
    font-size: 12px;
  }
</style>


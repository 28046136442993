<template>
  <div class="wang-editor-main">
    <div ref="editor" style="padding-bottom: 60px" />
    <el-drawer
      title="素材中心"
      :visible.sync="dialogUpload"
      direction="rtl"
      :show-close="true"
      size="85%"
    >
      <div class="drawer-content">
        <a>没有素材了</a>
      </div>
      <div class="dialog-footer" style="text-align: center">
        <el-button @click="dialogUpload = false">取 消</el-button>
        <el-button type="primary" @click="confirmImg">确 定</el-button>
      </div>
    </el-drawer>

    <el-drawer
      title="联盟商品"
      :visible.sync="dialogNicheGoods"
      direction="rtl"
      :show-close="true"
      size="75%"
    >
      <div class="drawer-content">
        <el-row :gutter="6" style="margin-bottom: 8px">
          <el-col :span="4">
            <el-input v-model="goodsQuery.id" placeholder="ID" clearable @keyup.enter.native="handleNicheGoodsFilter" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="goodsQuery.title" placeholder="标题" clearable @keyup.enter.native="handleNicheGoodsFilter" />
          </el-col>
          <el-col :span="6">
            <common-select v-model="goodsQuery.type" :width="`100%`" clearable placeholder="选择联盟" :options="typeOptions" />
          </el-col>
          <el-col :span="6">
            <el-select
              ref="selectUpResId"
              v-model="goodsQuery.category_id"
              prop="category_id"
              placeholder="选择分类"
            >
              <el-option key="categoryId" hidden :value="goodsQuery.category_id" :label="goodsQuery.category_name" />
              <el-tree
                ref="treeDataDirCheck"
                :data="typeTreeData"
                :props="defaultProps"
                :highlight-current="true"
                :expand-on-click-node="false"
                :check-on-click-node="true"
                node-key="id"
                :default-expanded-keys="defaultData"
                @node-click="nodeClickCheck"
              />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" icon="el-icon-search" @click="handleNicheGoodsFilter">搜索</el-button>
          </el-col>
        </el-row>

        <div style="overflow:auto" :style="{height: tableHeight + `px`}">
          <el-table
            ref="tableList"
            v-loading="listLoading"
            :data="goodsList"
            highlight-current-row
            @current-change="handleSelectionChange"
          >
            <el-table-column label="序号" type="index" width="50" />
            <el-table-column label="标题">
              <template slot-scope="scope">{{ scope.row.title | hoverStrOmit(12) }}</template>
            </el-table-column>
            <el-table-column label="价格">
              <template slot-scope="scope">
                <div>ID：{{ scope.row.id }}</div>
                <div>价格：{{ scope.row.price }}</div>
              </template>
            </el-table-column>
            <el-table-column label="主图">
              <template slot-scope="scope">
                <el-image :src="scope.row.photo" width="80" />
              </template>
            </el-table-column>
            <el-table-column label="联盟">
              <template slot-scope="scope">{{ scope.row.type | typeOptionsFilters }}</template>
            </el-table-column>
          </el-table>
        </div>

        <pagination v-show="goodsTotal>goodsQuery.limit" :total="goodsTotal" :page.sync="goodsQuery.page" :limit.sync="goodsQuery.limit" @pagination="openNicheGoods" />
      </div>
      <div class="dialog-footer" style="text-align: center">
        <el-button @click="dialogNicheGoods = false">取 消</el-button>
        <el-button type="primary" @click="confirmNicheGoods">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import E from 'wangeditor'
// import hljs from 'highlight.js'
// import '@/styles/highlight/github-dark.scss'
import { getToken } from '@/utils/auth'
const md5 = require('md5')
import { base64Encode } from '@/utils/tool'
import { goodsAdminListGoodsApi, goodsAdminCategoryListApi } from '@/api/niche-goods'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import CommonSelect from '@/components/BaseData/CommonSelect'
import { ellipsis } from '@/utils/validate'

const baseUrl = process.env.VUE_APP_BASE_API
const authKey = 'hsxx'

const typeOptions = [
  { label: 'Amazon', value: 1 },
  { label: 'Ebay', value: 2 },
  { label: 'Walmart', value: 3 },
  { label: 'AliExpress', value: 4 },
  { label: '淘宝', value: 5 },
  { label: '京东', value: 6 }
]
const typeOptionsKeyValue = typeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.label
  return acc
}, {})

export default {
  name: 'WangEditor',
  components: { Pagination, CommonSelect },
  filters: {
    hoverStrOmit(str, len) {
      return ellipsis(str, len)
    },
    typeOptionsFilters(v) {
      return typeOptionsKeyValue[v]
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    height: {
      type: [Number, String],
      required: false,
      default: 400
    },
    zIndex: {
      type: Number,
      required: false,
      default: 10000
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    changeValue: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      typeOptions,
      tableHeight: 600,
      editorContent: '',
      editor: '',
      hasInit: false,
      hasChange: false,
      dialogUpload: false,
      dialogNicheGoods: false,
      listLoading: false,
      timestamp: new Date().getTime(),
      checkedFile: [], // 选中的图片url数组
      goodsTotal: 0,
      goodsQuery: { page: 1, limit: 10, category_id: 0, category_name: '全部' },
      goodsList: [],
      checkedNicheGoods: [], // 选中的商品数组
      typeTreeData: [{ id: 0, label: '全部' }],
      defaultProps: { children: 'children', label: 'label' },
      defaultData: [0]
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        // 只有初始第一次执行
        if ((!this.hasChange && this.hasInit) || this.changeValue) {
          this.$nextTick(() => this.editor.txt.html(val || ''))
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  created() {

  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    init() {
      const editor = new E(this.$refs.editor)
      // 设置编辑区域高度 px
      editor.config.height = this.height
      // 编辑器 z-index 默认为 10000，注意：z-index值需要>=0
      editor.config.zIndex = this.zIndex
      editor.config.placeholder = this.placeholder
      // 自定义 alert
      editor.config.customAlert = (s, t) => {
        this.$message({
          message: s,
          type: t
        })
      }

      const self = this

      editor.config.pasteIgnoreImg = true // true忽略粘贴内容中的图片

      // 图片上传 配置 server 接口地址
      editor.config.uploadImgServer = baseUrl + '/fileCenter/image/upload'
      let sign = ''
      sign = 'path' + 'ni' + authKey + this.timestamp
      sign = md5(sign)
      sign = base64Encode(sign, 3)

      editor.config.uploadImgParams = { path: 'ni', sign: sign, timestamp: this.timestamp, adminUpload: 'hsxx' } // 自定义参数
      editor.config.uploadImgParamsWithUrl = true // 将参数拼接到 url 中
      editor.config.uploadImgHeaders = {
        'X-Token': getToken()
      }
      editor.config.uploadImgMaxSize = 500 * 1024 // 500KB
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
      editor.config.uploadImgMaxLength = 5 // 一次最多上传 5 个图片
      editor.config.uploadImgTimeout = 5 * 1000 // 上传接口等待的最大时间 5秒

      // 视频上传 配置 server 接口地址
      editor.config.uploadVideoServer = baseUrl + '/fileCenter/video/upload'
      editor.config.uploadVideoMaxSize = 50 * 1024 * 1024 // 50MB
      editor.config.uploadVideoTimeout = 1000 * 60 * 5 // 5分钟
      editor.config.uploadVideoAccept = ['mp4', 'avi', 'mov', 'wmv', 'flv']
      editor.config.uploadVideoParams = { path: 'ni', sign: sign, timestamp: this.timestamp, adminUpload: 'hsxx' } // 自定义参数
      editor.config.uploadVideoParamsWithUrl = true // 将参数拼接到 url 中
      editor.config.uploadVideoHeaders = {
        'X-Token': getToken()
      }

      const { BtnMenu, DropListMenu } = E
      // 自定义菜单栏
      // 插入特色卡片（最近常用（） 通用（图片 附件 表格 画板 思维导图 UML图 流程图 代码块 公式 文本绘图 状态 日历 加密文本））
      class MaterialCenter extends BtnMenu {
        constructor(editors) {
          // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
          const $elem = E.$(`<div class="w-e-menu" data-title="素材库"><span class="el-icon-picture"/></div>`)

          super($elem, editors)
        }

        // 菜单点击事件
        clickHandler() {
          self.open()
        }
        // 菜单是否被激活（如果不需要，这个函数可以空着）
        // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活
        // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
        tryChangeActive() {
          // 激活菜单
          // 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
          // 2. this.this.isActive === true
          // this.active()

          // // 取消激活菜单
          // // 1. 菜单 DOM 节点会删掉 .w-e-active
          // // 2. this.this.isActive === false
          // this.unActive()
        }
      }
      // 注册菜单
      editor.menus.extend('materialCenter', MaterialCenter)
      // 帮助菜单
      class HelpMenu extends DropListMenu {
        constructor(editors) {
          // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
          const $elem = E.$(`<div class="w-e-menu" data-title="帮助"><span class="el-icon-thumb"/></div>`)

          // droplist 配置
          const dropListConf = {
            width: 232,
            title: '帮助',
            type: 'text',
            list: [
              { $elem: E.$('<div><b>加粗 </b><span> Ctrl + B</span></div>' +
                        '<div><b>斜体 </b><span> Ctrl + I</span></div>' +
                  '<div><b>撤销 </b><span> Ctrl + Z</span>; <b>恢复 </b><span> Ctrl + Shift + Z</span></div>' +
                  ''
              ), value: '' }
            ],
            // droplist 每个 item 的点击事件
            clickHandler: (value) => {
              // value 参数即 dropListConf.list 中配置的 value
              this.command(value)
            }
          }

          super($elem, editor, dropListConf)
        }

        command(value) {
        }

        tryChangeActive() {
        }
      }
      // 注册菜单
      editor.menus.extend('helpMenu', HelpMenu)

      // 注册“联盟商品卡”菜单
      class NicheGoods extends BtnMenu {
        constructor(editors) {
          // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
          const $elem = E.$(`<div class="w-e-menu" data-title="联盟商品卡"><span class="el-icon-s-goods"/></div>`)
          super($elem, editors)
        }
        // 菜单点击事件
        clickHandler() {
          self.openNicheGoods()
        }
        tryChangeActive() {
        }
      }
      // 注册菜单
      editor.menus.extend('nicheGoods', NicheGoods)

      // 配置菜单栏，删减菜单，调整顺序
      editor.config.menus = [
        'materialCenter', 'nicheGoods', 'code',
        'undo', 'redo',
        'head', 'fontSize', 'bold', 'italic', 'strikeThrough', 'underline',
        'foreColor', 'backColor',
        'justify', 'list', 'indent', 'lineHeight',
        'todo', 'link', 'quote', 'splitLine',
        'emoticon', 'table', 'image', 'video', 'helpMenu'
      ]
      // 配置颜色（文字颜色、背景色）
      // editor.config.colors = ['#000000', '#eeece0', '#1c487f', '#4d80bf']
      // 配置行高
      editor.config.lineHeights = ['1', '1.15', '1.5', '2', '2.5', '3']
      // 挂载highlight插件
      // editor.highlight = hljs
      // 插入代码语言配置
      editor.config.languageType = [
        'PHP', 'Python', 'Shell Session',
        'JavaScript', 'TypeScript', 'CSS', 'Html',
        'Java', 'Bash', 'JSON', 'SQL', 'Go'
      ]

      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        // const safeHtml = xss(newHtml) // npm install xss
        // console.log('处理过 xss 攻击的 html', safeHtml)

        this.hasChange = true
        this.$emit('input', newHtml)
      }
      // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500

      // 创建编辑器
      editor.create()

      this.editor = editor
      this.hasInit = true
    },
    getContent: function() {
      alert(this.editorContent)
    },
    open() {
      // 组件自定义的事件
      this.dialogUpload = true
    },
    imgList(data) {
      // 选择图片后的回调
      this.checkedFile = data
    },
    confirmImg() {
      this.checkedFile.forEach(item => {
        this.editor.cmd.do('insertHTML', `<img src=${item}/q70.jpg> `)
        // this.editor.txt.append(`<img src=${item}> `)
      })
      this.checkedFile = []
      this.dialogUpload = false
    },
    // 打开联盟商品卡
    openNicheGoods() {
      this.listLoading = true
      if (this.typeTreeData.length < 2) {
        goodsAdminCategoryListApi().then(response => {
          this.typeTreeData = response.data
        })
      }

      goodsAdminListGoodsApi(this.goodsQuery).then(response => {
        this.goodsList = response.data.listData
        this.goodsTotal = response.data.pageData.total

        setTimeout(() => { this.listLoading = false }, 0.5 * 1000)
      })

      this.dialogNicheGoods = true

      this.$nextTick(function() {
        const self = this
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 98
        // 监听窗口大小变化
        window.onresize = function() {
          self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 98
        }
      })
    },
    nodeClickCheck(obj, node, selfObj) {
      this.goodsQuery.category_id = obj.id
      this.goodsQuery.category_name = obj.name
      this.$refs.selectUpResId.blur()
    },
    handleNicheGoodsFilter() {
      this.goodsQuery.page = 1
      this.openNicheGoods()
    },
    handleSelectionChange(val) {
      this.checkedNicheGoods = val
    },
    confirmNicheGoods() {
      const goods = this.checkedNicheGoods
      const id = goods?.id ?? 0
      if (id === 0) {
        this.checkedNicheGoods = {}
        this.dialogNicheGoods = false
      } else {
        const typeName = typeOptionsKeyValue[goods.type]
        this.editor.cmd.do('insertHTML', `<div><a href="` + goods.url + `" target="_blank">
<div style="background-color: #f6f6f6; border-radius: 8px; display: block; margin: 1em auto;text-decoration: none;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;-webkit-box-sizing: border-box;box-sizing: border-box;cursor: pointer;display: -webkit-box;display: -ms-flexbox;display: flex;max-width: 400px;min-height: 88px;overflow: hidden;padding: 14px;position: relative;width: 100%;z-index: 0;">
<div style="border-radius: 6px;flex-shrink: 0;height: 100px;overflow: hidden;position: relative;width: 100px;">
    <img src="` + goods.photo + `" style="max-width: 100%;" />
</div>

<div style="-webkit-box-orient: vertical;overflow: hidden;-webkit-box-direction: normal;-webkit-box-flex: 1;-ms-flex-positive: 1;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-direction: column;flex-direction: column;flex-grow: 1;margin-left: 12px;margin-top: -1px;">
    <div style="-webkit-line-clamp: 2;color: #121212;display: -webkit-box;font-size: 16px;font-synthesis: style;font-weight: 600;line-height: 20px;max-height: 40px;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient: vertical;overflow: hidden;">` + goods.title + `</div>
    <div style="-webkit-box-align: center;-ms-flex-align: center;align-items: center;display: -webkit-box;display: -ms-flexbox;display: flex;margin-top: 8px;"><div style="-webkit-box-flex: 0;color: grey;-ms-flex: none;flex: none;font-size: 12px;font-synthesis: style;font-weight: 600;line-height: 16px;margin-right: 12px;">` + typeName + `</div></div>
    <div style="-webkit-box-align: center;align-items: center;display: -webkit-box;display: -ms-flexbox;display: flex;height: 18px;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;margin-top: auto;overflow: hidden;">
        <div style="-webkit-box-align: center;-ms-flex-align: center;align-items: center;color: #fd5409;display: -webkit-box;display: -ms-flexbox;display: flex;font-size: 15px;height: 18px;line-height: 18px;margin-right: auto;overflow: hidden;"><span style="font-size: 16px;font-weight: 550;">￥` + goods.price + `</span></div>
        <div style="-webkit-box-pack: center;-ms-flex-pack: center;-ms-flex-item-align: start;-ms-flex-align: center;align-self: flex-start;color: #fd5409;flex-shrink: 0;font-size: 13px;font-synthesis: style;font-weight: 600;justify-content: center;line-height: 18px;margin-right: 2px;">去购买 ></div>
    </div>
</div>
</div>
</a></div>`)
      }

      this.checkedNicheGoods = {}
      this.dialogNicheGoods = false
    }
  }
}
</script>

<style lang="scss">
  pre code {
    color: #c9d1d9 !important;
    background-color: #0d1117 !important;
    border-radius: 0 !important;
  }
  .wang-editor-main .el-dialog__wrapper {
    z-index: 10020 !important;
  }

  .wang-editor-main .el-drawer__header {
    margin-bottom: 0;
    padding: 10px;
  }
  .wang-editor-main .el-drawer {
    padding-bottom: 70px;
  }
  .wang-editor-main .el-drawer__body {
    overflow-y: hidden;
  }

  .wang-editor-main .drawer-content {
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 20px;
    height: auto !important;
  }
  .wang-editor-main .drawer-content .drawer-form {
    flex: 1;
  }
  .wang-editor-main .drawer-footer {
    text-align: center;
    display: flex;
    padding: 10px 0 10px 0;
    margin-left: 20%;
    margin-right: 20%;
  }
  .wang-editor-main .drawer-footer button {
    flex: 1;
  }
</style>

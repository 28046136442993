var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wscn-http404-container" }, [
    _c("div", { staticClass: "wscn-http404" }, [
      _c("div", { staticClass: "pic-404" }, [
        _c("img", {
          staticClass: "pic-404__parent",
          attrs: { src: _vm.img404, alt: "404" },
        }),
        _c("img", {
          staticClass: "pic-404__child left",
          attrs: { src: _vm.img404Cloud, alt: "404" },
        }),
        _c("img", {
          staticClass: "pic-404__child mid",
          attrs: { src: _vm.img404Cloud, alt: "404" },
        }),
        _c("img", {
          staticClass: "pic-404__child right",
          attrs: { src: _vm.img404Cloud, alt: "404" },
        }),
      ]),
      _c("div", { staticClass: "bullshit" }, [
        _c("div", { staticClass: "bullshit__headline" }, [
          _vm._v(_vm._s(_vm.message)),
        ]),
        _c("div", { staticClass: "bullshit__info" }, [
          _vm._v("请检查您输入的URL是否正确，或单击下面的按钮返回主页。"),
        ]),
        _c(
          "a",
          { staticClass: "bullshit__return-home", attrs: { href: "/" } },
          [_vm._v("回到首页")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
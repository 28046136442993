var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: { filterable: "", placeholder: "请选择" },
          on: { change: _vm.changeData },
          model: {
            value: _vm.sValue,
            callback: function ($$v) {
              _vm.sValue = $$v
            },
            expression: "sValue",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.label + item.value, value: item.value },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        { staticClass: "dev-manager-container" },
        [
          _c("el-aside", { attrs: { width: "180px" } }, [
            _c(
              "div",
              [
                _c("el-input", {
                  staticStyle: { "margin-bottom": "2px" },
                  attrs: { placeholder: "搜索菜单" },
                  model: {
                    value: _vm.filterText,
                    callback: function ($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("el-tree", {
                  ref: "powerTreeData",
                  staticClass: "filter-tree",
                  attrs: {
                    data: _vm.typeTreeData,
                    props: _vm.defaultProps,
                    "filter-node-method": _vm.filterNode,
                    "highlight-current": true,
                    "expand-on-click-node": false,
                    "check-on-click-node": true,
                    "node-key": "id",
                    "default-expanded-keys": _vm.defaultData,
                  },
                  on: { "node-click": _vm.nodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var node = ref.node
                        return _c(
                          "span",
                          { staticClass: "custom-tree-node" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: node.label,
                                  disabled: _vm._f("hoverStr")(node.label, 13),
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(" " + _vm._s(node.label) + " "),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-main",
            { staticClass: "main-tabs-content" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 8 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleFilter($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "name", $$v)
                          },
                          expression: "listQuery.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "编码", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleFilter($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "code", $$v)
                          },
                          expression: "listQuery.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "路由path", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleFilter($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.path,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "path", $$v)
                          },
                          expression: "listQuery.path",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "路由name", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleFilter($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.en_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "en_name", $$v)
                          },
                          expression: "listQuery.en_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.handleFilter },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary", icon: "el-icon-edit" },
                          on: { click: _vm.handleCreate },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "12px" } },
                [
                  _c(
                    "el-breadcrumb",
                    { attrs: { separator: "/" } },
                    _vm._l(_vm.checkTypeArray, function (item, i) {
                      return _c(
                        "el-breadcrumb-item",
                        { key: i },
                        [
                          _c(
                            "el-link",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: i !== _vm.checkTypeArray.length - 1,
                                  expression: "i!==checkTypeArray.length-1",
                                },
                              ],
                              attrs: { underline: false, type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.breadcrumbClick(item.id, item.name)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: i === _vm.checkTypeArray.length - 1,
                                  expression: "i===checkTypeArray.length-1",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "tableList",
                  staticStyle: { width: "100%", overflow: "auto !important" },
                  attrs: {
                    data: _vm.list,
                    height: _vm.tableHeight,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "名称", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [_vm._v(_vm._s(row.name))]),
                            _c(
                              "div",
                              [
                                _vm._v(_vm._s(row.code) + " "),
                                _c(
                                  "el-tag",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.is_system === 1,
                                        expression: "row.is_system===1",
                                      },
                                    ],
                                  },
                                  [_vm._v("系统")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "路由", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [_vm._v(_vm._s(row.path))]),
                            _c("div", [_vm._v(_vm._s(row.en_name))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "类型", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("typeOptionsFilters")(row.type)
                                  ) + " "
                                ),
                                _c(
                                  "el-tag",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.type === 2,
                                        expression: "row.type===2",
                                      },
                                    ],
                                    attrs: { type: "default" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("viewTypeOptionsFilters")(
                                          row.view_type
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tag",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.is_hidden === 1,
                                        expression: "row.is_hidden===1",
                                      },
                                    ],
                                    attrs: { type: "warning" },
                                  },
                                  [_vm._v("隐藏")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.type === 1,
                                    expression: "row.type===1",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("layoutOptionsFilters")(row.layout)
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.type === 2,
                                    expression: "row.type===2",
                                  },
                                ],
                              },
                              [_vm._v(_vm._s(row.view))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.is_system !== 1,
                                    expression: "row.is_system!==1",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-dropdown",
                                  { attrs: { "split-button": false } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [
                                        _vm._v(" 操作"),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.handleUpdate(row)
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.type === 2,
                                                expression: "row.type===2",
                                              },
                                            ],
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.handleEditView(row)
                                              },
                                            },
                                          },
                                          [_vm._v("编辑视图")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                ref: "popover" + $index,
                                                staticStyle: {
                                                  display: "block",
                                                  width: "100%",
                                                },
                                                attrs: {
                                                  placement: "top",
                                                  tabindex: $index,
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "删除 `" +
                                                      _vm._s(row.name) +
                                                      "` 吗？"
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                      margin: "0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "text",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleCancelVisible(
                                                              $index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("取消")]
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "danger",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDel(
                                                              row,
                                                              $index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("确定")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      display: "block",
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getListData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "32", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "name", $$v)
                      },
                      expression: "temp.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "编码", prop: "code" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "8", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "code", $$v)
                      },
                      expression: "temp.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "路由path", prop: "path" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "path", $$v)
                      },
                      expression: "temp.path",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "路由name", prop: "en_name" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.en_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "en_name", $$v)
                      },
                      expression: "temp.en_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "icon", prop: "icon" } },
                [
                  _c("icon-select", {
                    class: _vm.temp.icon,
                    model: {
                      value: _vm.temp.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "icon", $$v)
                      },
                      expression: "temp.icon",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c("common-select", {
                    attrs: {
                      options: _vm.typeOptions,
                      disabled: _vm.dialogStatus !== "create",
                    },
                    model: {
                      value: _vm.temp.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "type", $$v)
                      },
                      expression: "temp.type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.temp.type === 1,
                      expression: "temp.type===1",
                    },
                  ],
                  attrs: { label: "重定向", prop: "redirect" },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.redirect,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "redirect", $$v)
                      },
                      expression: "temp.redirect",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.temp.type === 1,
                      expression: "temp.type===1",
                    },
                  ],
                  attrs: { label: "布局", prop: "layout" },
                },
                [
                  _c("common-select", {
                    attrs: { options: _vm.layoutOptions },
                    model: {
                      value: _vm.temp.layout,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "layout", $$v)
                      },
                      expression: "temp.layout",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.temp.type === 2,
                      expression: "temp.type===2",
                    },
                  ],
                  attrs: { label: "视图类型", prop: "view_type" },
                },
                [
                  _c("common-select", {
                    attrs: {
                      options: _vm.viewTypeOptions,
                      disabled: _vm.dialogStatus !== "create",
                    },
                    model: {
                      value: _vm.temp.view_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "view_type", $$v)
                      },
                      expression: "temp.view_type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.temp.type === 2,
                      expression: "temp.type===2",
                    },
                  ],
                  attrs: { label: "视图", prop: "view" },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.view,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "view", $$v)
                      },
                      expression: "temp.view",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否隐藏", prop: "is_hidden" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": _vm.switchData.open,
                      "inactive-value": _vm.switchData.close,
                    },
                    model: {
                      value: _vm.temp.is_hidden,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "is_hidden", $$v)
                      },
                      expression: "temp.is_hidden",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Fingerprint2 from 'fingerprintjs2'
const base64 = require('crypto-js/enc-base64')
const utf8 = require('crypto-js/enc-utf8')

let uuid = ''

// 创建浏览器指纹uuid - 需要在页面打开时候调用。
export function createUuid() {
  Fingerprint2.get(function(components) {
    const values = components.map(function(component, index) {
      if (index === 0) {
        // 把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
        return component.value.replace(/\bNetType\/\w+\b/, '')
      }
      return component.value
    })
    // 生成最终id murmur
    uuid = Fingerprint2.x64hash128(values.join(''), 31)
    return uuid
  })
}

// 调用了createUuid，才能使用这个获取，需要延迟才能获取到
export function getUuid() {
  return uuid
}

/**
 * Base64 编码
 * @param {any} word 加密数据
 * @param {any} times 加密次数
 */
export const base64Encode = (word, times) => {
  word = base64.stringify(utf8.parse(word))
  while (times > 1) {
    times--
    word = base64.stringify(utf8.parse(word))
  }
  return word
}

/**
 * Base64 解码
 * @param {any} word 解密数据
 * @param {any} times 解密次数
 */
export const base64Decode = (word, times) => {
  const is_base_64 = base64.parse(word).sigBytes % 8 === 0
  if (!is_base_64) {
    return ''
  }
  word = base64.parse(word).toString(utf8)
  while (times > 1) {
    times--
    word = base64.parse(word).toString(utf8)
  }
  return word
}

/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout'

const linkCenterRouter = {
  path: '/link',
  component: Layout,
  redirect: '/link/index',
  name: 'Link',
  meta: { title: '链接中心', icon: 'el-icon-link' },
  children: [
    {
      path: 'hsxx',
      meta: { title: '火山云平台', icon: 'el-icon-cloudy' },
      children: [
        {
          path: process.env.VUE_APP_BLOG_URL,
          meta: { title: '博客平台', icon: 'el-icon-edit' }
        }
      ]
    },
    {
      path: 'devops',
      meta: { title: '运维平台', icon: 'el-icon-data-line' },
      children: [
        {
          path: 'https://workbench.umeng.com/',
          meta: { title: '友盟统计', icon: 'el-icon-s-data' }
        },
        {
          path: 'https://console.cloud.tencent.com/',
          meta: { title: '腾讯云', icon: 'el-icon-cloudy' }
        },
        {
          path: 'https://portal.qiniu.com/kodo/bucket',
          meta: { title: '七牛云', icon: 'el-icon-cloudy-and-sunny' }
        },
        {
          path: 'https://flow.aliyun.com/my?page=1',
          meta: { title: '云效', icon: 'el-icon-lightning' }
        },
        {
          path: 'https://xcan.coding.net/user',
          meta: { title: 'coding', icon: 'el-icon-share' }
        }
      ]
    },
    {
      path: 'seo',
      meta: { title: 'SEO优化', icon: 'el-icon-attract' },
      children: [
        {
          path: 'https://ziyuan.baidu.com/linksubmit/index',
          meta: { title: '百度', icon: 'el-icon-data-analysis' }
        },
        {
          path: 'https://zhanzhang.sogou.com/index.php/sitelink/index',
          meta: { title: '搜狗', icon: 'el-icon-data-analysis' }
        },
        {
          path: 'https://zhanzhang.so.com/sitetool/site_manage',
          meta: { title: '360好搜', icon: 'el-icon-data-analysis' }
        }
      ]
    },
    {
      path: 'tools',
      meta: { title: '工具包', icon: 'el-icon-brush' },
      children: [
        {
          path: 'http://www.easyswoole.com/wstool.html',
          meta: { title: 'WebSocket测试工具', icon: 'el-icon-cpu' }
        },
        {
          path: 'https://www.json.cn/',
          meta: { title: 'json解析', icon: 'el-icon-refresh' }
        }
      ]
    },
    {
      path: 'https://element.eleme.cn/#/zh-CN/component/layout',
      meta: { title: 'Element文档', icon: 'el-icon-reading' }
    },
    {
      path: 'https://www.wangeditor.com/doc/',
      meta: { title: 'wangEditor文档', icon: 'el-icon-reading' }
    },
    {
      path: 'http://tinymce.ax-z.cn/plugins/emoticons.php',
      meta: { title: 'tinyMCE文档', icon: 'el-icon-reading' }
    }
  ]
}
export default linkCenterRouter

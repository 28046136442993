
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * 省略多余字符，用...显示
 * @param {String} value
 * @param {number} len 字节长度
 * @param {String} symbol 符号，默认...
 */
export function ellipsis(value, len, symbol) {
  if (!value) return ''
  if (symbol === undefined) symbol = '...'

  // eslint-disable-next-line no-unused-vars
  let bytesCount = 0
  for (var i = 0, n = value.length; i < n; i++) {
    const c = value.charCodeAt(i)
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      bytesCount += 1
    } else {
      bytesCount += 2
    }

    if (bytesCount === len) {
      if (n - 1 === i) {
        // 最后一个
        return value
      }
      return value.slice(0, i + 1) + symbol
    }
    if (bytesCount > len) {
      if (i > 0) {
        return value.slice(0, i) + symbol
      }
      return symbol
    }
  }
  return value
}

export function ellipsisBool(value, len) {
  if (!value) return false

  // eslint-disable-next-line no-unused-vars
  let bytesCount = 0
  for (var i = 0, n = value.length; i < n; i++) {
    const c = value.charCodeAt(i)
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      bytesCount += 1
    } else {
      bytesCount += 2
    }
    if (bytesCount >= len) {
      return true
    }
  }
  return false
}

/**
 * 日期格式化
 * @param time 时间戳
 * @param format 格式
 */
export function dateFormat(time, format) {
  const t = new Date(time)
  // 日期格式
  format = format || 'Y-M-D H:I:S'
  const year = t.getFullYear()
  // 由于 getMonth 返回值会比正常月份小 1
  const month = t.getMonth() + 1
  const day = t.getDate()
  const hours = t.getHours()
  const minutes = t.getMinutes()
  const seconds = t.getSeconds()
  const millSeconds = t.getMilliseconds()

  const hash = {
    'y': year,
    'm': month,
    'd': day,
    'h': hours,
    'i': minutes,
    's': seconds,
    'e': millSeconds
  }
  // 是否补 0
  const isAddZero = (o) => {
    return /M|D|H|I|S|E/.test(o)
  }
  return format.replace(/\w/g, o => {
    const rt = hash[o.toLocaleLowerCase()]
    return rt >= 10 || !isAddZero(o) ? rt : `0${rt}`
  })
}

/**
 * 日期转为年龄
 * @param birthStr '2020-01-01'
 * @param append 结尾单位
 */
export function ageByBirthday(birthStr, append = '岁') {
  if (!birthStr) {
    return '~'
  }
  const birthdays = new Date(birthStr.replace(/-/g, '/'))
  const d = new Date()
  const age = d.getFullYear() - birthdays.getFullYear() - (d.getMonth() < birthdays.getMonth() || (d.getMonth() === birthdays.getMonth() && d.getDate() < birthdays.getDate()) ? 1 : 0)
  return age + append
}

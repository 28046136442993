<template>
  <div>
    <el-select
      ref="selectRef"
      v-model="sValue"
      :disabled="disabled"
      multiple
      clearable
      filterable
      placeholder="请选择"
      value-key="id"
      style="width: 100%;"
      @change="changeData"
    >
      <el-option
        v-for="item in optionData"
        :key="item.id"
        :label="item.name"
        :value="item"
      />
    </el-select>
  </div>
</template>

<script>
import { roleListAllApi } from '@/api/user-center'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sValue: [], // 选中的数据 [{ id: 1, name: '' }, {}]
      optionData: []
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.sValue = val
      }
    },
    disabled: {
      handler(val) {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      roleListAllApi().then(response => {
        this.optionData = response.data
        if (this.sValue) {
          const allData = []
          const sValueTemp = []
          for (const i in this.optionData) {
            allData[this.optionData[i].id] = true
          }

          for (const k in this.sValue) {
            if (allData[this.sValue[k].id]) {
              sValueTemp.push(this.sValue[k])
            }
          }
          this.sValue = sValueTemp
        }
      })
    },
    changeData(data) {
      this.$emit('input', data)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="8">
        <el-input v-model="listQuery.article_id" placeholder="文章ID" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="8">
        <el-button v-waves type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-col>
    </el-row>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" align="center">
        <template slot-scope="{row}">
          <div>ID：<b>{{ row.id }}</b></div>
          <div>文章ID：{{ row.article_id }}</div>
          <div><el-link :underline="false" type="primary" :href="blogUrl + '/blog/' + row.title_url" target="_blank">{{ row.title }}</el-link></div>
        </template>
      </el-table-column>
      <el-table-column label="用户" min-width="168px" align="left">
        <template slot-scope="{row}">
          <div>用户ID：{{ row.user_id }}</div>
          <div>用户名称：{{ row.user_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="回复用户" min-width="168px" align="left">
        <template slot-scope="{row}">
          <div>用户ID：{{ row.reply_user_id }}</div>
          <div>用户名称：{{ row.reply_user_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="内容" min-width="168px" align="left">
        <template slot-scope="{row}">
          <div style="white-space: pre-wrap;">{{ row.content }}</div>
        </template>
      </el-table-column>
      <el-table-column label="时间" width="168px" align="center">
        <template slot-scope="{row}">
          <div>创建于：{{ row.create_date }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="{row, $index}">
          <el-popover
            :ref="`popoverDel` + $index"
            placement="top"
            width="240"
          >
            <p>删除用户'{{ row.user_name }}'的评论吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="handleCancelVisible($index)">取消</el-button>
              <el-button size="mini" type="danger" @click="handleDel(row.id, $index)">确定</el-button>
            </div>
            <el-button slot="reference" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { nicheArticleArticleCommentAdminListApi, nicheArticleArticleCommentAdminDeleteApi } from '@/api/article-comment'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

const blogUrl = process.env.VUE_APP_BLOG_URL

export default {
  name: 'ArticleComment',
  components: { Pagination },
  directives: { waves },
  data() {
    return {
      blogUrl,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: { page: 1, limit: 20 }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      nicheArticleArticleCommentAdminListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => { this.listLoading = false }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleCancelVisible(index) {
      this.$refs[`popoverDel` + index].doClose()
    },
    handleDel(id, index) {
      this.$refs[`popoverDel` + index].doClose()
      nicheArticleArticleCommentAdminDeleteApi({ id: id }).then(response => {
        this.list.splice(index, 1)
        this.$message({ message: response.message, type: 'success' })
      })
    }
  }
}
</script>

<style scoped>

</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("el-input", {
          staticStyle: { "margin-bottom": "2px" },
          attrs: { placeholder: "搜索组织" },
          model: {
            value: _vm.filterText,
            callback: function ($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("el-tree", {
          ref: "treeDataRef",
          staticClass: "filter-tree",
          style: { height: _vm.treeHeight, overflowY: "auto" },
          attrs: {
            data: _vm.treeData,
            props: _vm.defaultProps,
            "filter-node-method": _vm.filterNode,
            "highlight-current": true,
            "expand-on-click-node": false,
            "check-on-click-node": true,
            "node-key": "id",
            "default-expanded-keys": _vm.defaultExpandedData,
          },
          on: { "node-click": _vm.nodeClick },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var node = ref.node
                return _c(
                  "span",
                  { staticClass: "custom-tree-node" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: node.label,
                          disabled: _vm._f("hoverStr")(node.label, 13),
                          placement: "top-start",
                        },
                      },
                      [_c("span", [_vm._v(" " + _vm._s(node.label) + " ")])]
                    ),
                  ],
                  1
                )
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
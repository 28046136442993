import Cookies from 'js-cookie'

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: Cookies.get('size') || 'small',
  websiteName: Cookies.get('websiteName') || '',
  websiteCode: Cookies.get('websiteCode') || '',
  websiteUrl: Cookies.get('websiteUrl') || ''
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_WEBSITE_NAME: (state, name) => {
    state.websiteName = name
    Cookies.set('websiteName', name)
  },
  SET_WEBSITE_CODE: (state, code) => {
    state.websiteCode = code
    Cookies.set('websiteCode', code)
  },
  SET_WEBSITE_URL: (state, url) => {
    state.websiteUrl = url
    Cookies.set('websiteUrl', url)
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setWebsiteName({ commit }, name) {
    commit('SET_WEBSITE_NAME', name)
  },
  setWebsiteCode({ commit }, code) {
    commit('SET_WEBSITE_CODE', code)
  },
  setWebsiteUrl({ commit }, url) {
    commit('SET_WEBSITE_URL', url)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        { staticClass: "data-manager-container" },
        [
          _c(
            "el-aside",
            {
              staticStyle: { "overflow-y": "hidden" },
              attrs: { width: "240px" },
            },
            [
              _c(
                "div",
                { staticClass: "top-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%", "margin-bottom": "10px" },
                      attrs: { icon: "el-icon-circle-plus" },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v("添加数据库")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "top-container" },
                [
                  _c("database-tree-list-menu", {
                    attrs: {
                      height: _vm.tableHeight + 100 + "px",
                      refresh: _vm.refreshTree,
                    },
                    on: { nodeClick: _vm.nodeClick, treeData: _vm.treeData },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-main",
            { staticClass: "main-tabs-content" },
            [
              _c(
                "div",
                { staticClass: "database-info" },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "数据库信息" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "数据库名" } },
                        [
                          _vm._v(_vm._s(_vm.nowDatabase.database_name) + " "),
                          _c(
                            "el-tag",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.nowDatabase.is_system === 1,
                                  expression: "nowDatabase.is_system===1",
                                },
                              ],
                              staticStyle: { "margin-left": "6px" },
                              attrs: { size: "small" },
                            },
                            [_vm._v("系统")]
                          ),
                        ],
                        1
                      ),
                      _c("el-descriptions-item", { attrs: { label: "名称" } }, [
                        _vm._v(_vm._s(_vm.nowDatabase.name)),
                      ]),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "字符集" } },
                        [_vm._v(_vm._s(_vm.nowDatabase.charset))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.nowDatabase.is_system !== 1,
                          expression: "nowDatabase.is_system!==1",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "12px" },
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleUpdate(_vm.nowDatabase)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "el-popover",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.total === 0,
                              expression: "total===0",
                            },
                          ],
                          ref: "popoverNowDatabase",
                          attrs: { placement: "top", width: "240" },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "删除数据库 `" +
                                _vm._s(_vm.nowDatabase.database_name) +
                                "` 吗？"
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                margin: "0",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCancelVisible()
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDel(
                                        _vm.nowDatabase.id,
                                        _vm.nowDatabase.name
                                      )
                                    },
                                  },
                                },
                                [_vm._v("确定")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", size: "mini" },
                              slot: "reference",
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 8 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "表名", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleFilter($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.table_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "table_name", $$v)
                          },
                          expression: "listQuery.table_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "注释", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleFilter($event)
                          },
                        },
                        model: {
                          value: _vm.listQuery.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "name", $$v)
                          },
                          expression: "listQuery.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.handleFilter },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary", icon: "el-icon-edit" },
                          on: { click: _vm.handleCreateTable },
                        },
                        [_vm._v("添加表")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "tableList",
                  staticStyle: { width: "100%", overflow: "auto !important" },
                  attrs: {
                    data: _vm.list,
                    height: _vm.tableHeight,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "表名", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.table_name))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "注释", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.name))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "字符集", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.charset))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.is_system !== 1,
                                    expression: "row.is_system!==1",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-dropdown",
                                  { attrs: { "split-button": false } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [
                                        _vm._v(" 操作"),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.handleUpdateTable(
                                                  row,
                                                  0
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                ref: "popover" + $index,
                                                staticStyle: {
                                                  display: "block",
                                                  width: "100%",
                                                },
                                                attrs: {
                                                  placement: "top",
                                                  tabindex: $index,
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "删除数据表 `" +
                                                      _vm._s(row.table_name) +
                                                      "` 吗？"
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                      margin: "0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "text",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleCancelTableVisible(
                                                              $index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("取消")]
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "danger",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDelTable(
                                                              row.id,
                                                              $index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("确定")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      display: "block",
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > _vm.listQuery.limit,
                    expression: "total>listQuery.limit",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getListTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "数据库名称", prop: "database_name" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      disabled: _vm.dialogStatus !== "create",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.temp.database_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "database_name", $$v)
                      },
                      expression: "temp.database_name",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "命名规则：由小写字母、数字、下划线_，组成，且小写字母开头"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "name", $$v)
                      },
                      expression: "temp.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "字符集", prop: "charset" } },
                [
                  _c("my-s-q-l-select", {
                    model: {
                      value: _vm.temp.charset,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "charset", $$v)
                      },
                      expression: "temp.charset",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "创建表", visible: _vm.dialogTableFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataTableForm",
              attrs: {
                rules: _vm.tableRules,
                model: _vm.temp,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "数据表名称", prop: "table_name" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.table_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "table_name", $$v)
                      },
                      expression: "temp.table_name",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "命名规则：由小写字母、数字、下划线_，组成，且小写字母开头"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注释", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "name", $$v)
                      },
                      expression: "temp.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "字符集", prop: "charset" } },
                [
                  _c("my-s-q-l-select", {
                    model: {
                      value: _vm.temp.charset,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "charset", $$v)
                      },
                      expression: "temp.charset",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogTableFormVisible = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.createTableData()
                    },
                  },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.temp.name,
            visible: _vm.drawerVisible,
            direction: "rtl",
            "show-close": false,
            size: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "drawer-content" }, [
            _c(
              "div",
              { staticClass: "drawer-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataTableForm",
                    attrs: {
                      rules: _vm.tableRules,
                      model: _vm.temp,
                      "label-position": "right",
                      "label-width": "90px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "数据表名", prop: "table_name" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "50", "show-word-limit": "" },
                          model: {
                            value: _vm.temp.table_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "table_name", $$v)
                            },
                            expression: "temp.table_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "注释", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "20", "show-word-limit": "" },
                          model: {
                            value: _vm.temp.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "name", $$v)
                            },
                            expression: "temp.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "字符集", prop: "charset" } },
                      [
                        _c("my-s-q-l-select", {
                          model: {
                            value: _vm.temp.charset,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "charset", $$v)
                            },
                            expression: "temp.charset",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "border-card" },
                    model: {
                      value: _vm.tabsName,
                      callback: function ($$v) {
                        _vm.tabsName = $$v
                      },
                      expression: "tabsName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "table-field-data",
                        attrs: { label: "字段配置", name: "tabs1" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-link",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  color: "green",
                                },
                                attrs: {
                                  icon: "el-icon-circle-plus-outline",
                                  underline: false,
                                },
                                on: { click: _vm.handleFieldRowAdd },
                              },
                              [_vm._v("添加字段")]
                            ),
                            _c(
                              "el-link",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  color: "blue",
                                },
                                attrs: {
                                  icon: "el-icon-s-unfold",
                                  underline: false,
                                },
                                on: { click: _vm.handleFieldRowInsert },
                              },
                              [_vm._v("插入字段")]
                            ),
                            _c(
                              "el-link",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.fieldOtherData.index > 0,
                                    expression: "fieldOtherData.index>0",
                                  },
                                ],
                                staticStyle: { "margin-right": "10px" },
                                attrs: { underline: false },
                                on: { click: _vm.handleFieldRowCopy },
                              },
                              [_vm._v("复制字段")]
                            ),
                            _c(
                              "el-link",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.fieldOtherData.index > 0,
                                    expression: "fieldOtherData.index>0",
                                  },
                                ],
                                staticStyle: {
                                  "margin-right": "10px",
                                  color: "red",
                                },
                                attrs: {
                                  icon: "el-icon-remove-outline",
                                  underline: false,
                                },
                                on: { click: _vm.handleFieldRowDelete },
                              },
                              [_vm._v("删除字段")]
                            ),
                            _c(
                              "el-link",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.fieldOtherData.index > 1,
                                    expression: "fieldOtherData.index>1",
                                  },
                                ],
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  icon: "el-icon-top",
                                  underline: false,
                                },
                                on: { click: _vm.handleFieldRowUp },
                              },
                              [_vm._v("上移")]
                            ),
                            _c(
                              "el-link",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.fieldOtherData.index > 0,
                                    expression: "fieldOtherData.index>0",
                                  },
                                ],
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  icon: "el-icon-bottom",
                                  underline: false,
                                },
                                on: { click: _vm.handleFieldRowDown },
                              },
                              [_vm._v("下移")]
                            ),
                          ],
                          1
                        ),
                        _c("el-divider"),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listFieldLoading,
                                expression: "listFieldLoading",
                              },
                            ],
                            ref: "tableFieldDataList",
                            staticStyle: { overflow: "auto !important" },
                            attrs: {
                              data: _vm.tableFieldData,
                              height: _vm.tableHeight - 24,
                              fit: "",
                              "highlight-current-row": "",
                              "cell-class-name": "no-padding-table-cell",
                              "row-class-name": _vm.tableRowClassName,
                            },
                            on: { "row-click": _vm.handleFieldRowClick },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "名", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c("el-input", {
                                        ref: "fieldRowName" + $index,
                                        attrs: {
                                          disabled: $index === 0,
                                          maxlength: "50",
                                        },
                                        on: {
                                          change: function (value) {
                                            _vm.handleInputFieldRowName(
                                              value,
                                              row
                                            )
                                          },
                                        },
                                        model: {
                                          value: row.name,
                                          callback: function ($$v) {
                                            _vm.$set(row, "name", $$v)
                                          },
                                          expression: "row.name",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: row.name === "",
                                              expression: "row.name === ''",
                                            },
                                          ],
                                          staticStyle: { color: "red" },
                                        },
                                        [_vm._v("名称不能为空")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类型",
                                align: "center",
                                width: "90px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c("my-s-q-l-type-select", {
                                        attrs: { disabled: $index === 0 },
                                        model: {
                                          value: row.type,
                                          callback: function ($$v) {
                                            _vm.$set(row, "type", $$v)
                                          },
                                          expression: "row.type",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "长度",
                                align: "center",
                                width: "60px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c("el-input", {
                                        staticClass: "no-padding",
                                        attrs: {
                                          disabled: $index === 0,
                                          type: "number",
                                          min: 0,
                                          max: 10000,
                                        },
                                        model: {
                                          value: row.length,
                                          callback: function ($$v) {
                                            _vm.$set(row, "length", $$v)
                                          },
                                          expression: "row.length",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "小数点",
                                align: "center",
                                width: "50px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: $index === 0,
                                          type: "number",
                                          min: 0,
                                          max: 10,
                                        },
                                        model: {
                                          value: row.point,
                                          callback: function ($$v) {
                                            _vm.$set(row, "point", $$v)
                                          },
                                          expression: "row.point",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "不是NULL",
                                align: "center",
                                width: "60px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c("el-checkbox", {
                                        attrs: { disabled: $index === 0 },
                                        model: {
                                          value: row.is_not_null,
                                          callback: function ($$v) {
                                            _vm.$set(row, "is_not_null", $$v)
                                          },
                                          expression: "row.is_not_null",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "主键",
                                align: "center",
                                width: "30px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c("el-checkbox", {
                                        attrs: { disabled: $index === 0 },
                                        model: {
                                          value: row.is_primary_key,
                                          callback: function ($$v) {
                                            _vm.$set(row, "is_primary_key", $$v)
                                          },
                                          expression: "row.is_primary_key",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "注释", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: $index === 0,
                                          maxlength: "20",
                                        },
                                        model: {
                                          value: row.comment,
                                          callback: function ($$v) {
                                            _vm.$set(row, "comment", $$v)
                                          },
                                          expression: "row.comment",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form",
                          {
                            staticClass: "field-other-data",
                            attrs: {
                              model: _vm.fieldOtherData,
                              "label-position": "right",
                              "label-width": "90px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "默认", prop: "default_value" },
                              },
                              [
                                _c("el-autocomplete", {
                                  attrs: {
                                    disabled: _vm.fieldOtherData.type >= 50,
                                    "fetch-suggestions": function (
                                      queryString,
                                      callback
                                    ) {
                                      _vm.querySearch(
                                        queryString,
                                        callback,
                                        _vm.fieldOtherData.type,
                                        _vm.fieldOtherData.is_not_null
                                      )
                                    },
                                    "trigger-on-focus": "",
                                  },
                                  model: {
                                    value: _vm.fieldOtherData.default_value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fieldOtherData,
                                        "default_value",
                                        $$v
                                      )
                                    },
                                    expression: "fieldOtherData.default_value",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.fieldOtherData.type >= 40,
                                    expression: "fieldOtherData.type>=40",
                                  },
                                ],
                                attrs: { label: "字符集", prop: "charset" },
                              },
                              [
                                _c("my-s-q-l-select", {
                                  model: {
                                    value: _vm.fieldOtherData.charset,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fieldOtherData,
                                        "charset",
                                        $$v
                                      )
                                    },
                                    expression: "fieldOtherData.charset",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.fieldOtherData.type < 30,
                                    expression: "fieldOtherData.type<30",
                                  },
                                ],
                                attrs: { label: "无符号", prop: "is_unsigned" },
                              },
                              [
                                _c("el-checkbox", {
                                  model: {
                                    value: _vm.fieldOtherData.is_unsigned,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fieldOtherData,
                                        "is_unsigned",
                                        $$v
                                      )
                                    },
                                    expression: "fieldOtherData.is_unsigned",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "table-field-data",
                        attrs: { label: "索引配置", name: "tabs2" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-link",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  color: "green",
                                },
                                attrs: {
                                  icon: "el-icon-circle-plus-outline",
                                  underline: false,
                                },
                                on: { click: _vm.handleFieldIndexRowAdd },
                              },
                              [_vm._v("添加索引")]
                            ),
                            _c(
                              "el-link",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  color: "red",
                                },
                                attrs: {
                                  icon: "el-icon-remove-outline",
                                  underline: false,
                                },
                                on: { click: _vm.handleFieldIndexRowDelete },
                              },
                              [_vm._v("删除索引")]
                            ),
                          ],
                          1
                        ),
                        _c("el-divider"),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            ref: "tableIndexDataList",
                            staticStyle: { overflow: "auto !important" },
                            attrs: {
                              data: _vm.tableIndexData,
                              height: _vm.tableHeight + 48,
                              fit: "",
                              "highlight-current-row": "",
                              "cell-class-name": "no-padding-table-cell",
                              "row-class-name": _vm.tableRowClassName,
                            },
                            on: { "row-click": _vm.handleFieldIndexRowClick },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "名", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c("el-input", {
                                        ref: "fieldIndexRowName" + $index,
                                        attrs: { maxlength: "100" },
                                        model: {
                                          value: row.name,
                                          callback: function ($$v) {
                                            _vm.$set(row, "name", $$v)
                                          },
                                          expression: "row.name",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: row.name === "",
                                              expression: "row.name === ''",
                                            },
                                          ],
                                          staticStyle: { color: "red" },
                                        },
                                        [_vm._v("名称不能为空")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "字段", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c("el-input", {
                                        ref: "fieldIndexRowField" + $index,
                                        attrs: { maxlength: "100" },
                                        model: {
                                          value: row.field,
                                          callback: function ($$v) {
                                            _vm.$set(row, "field", $$v)
                                          },
                                          expression: "row.field",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: row.field === "",
                                              expression: "row.field === ''",
                                            },
                                          ],
                                          staticStyle: { color: "red" },
                                        },
                                        [_vm._v("字段不能为空")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "索引类型",
                                align: "center",
                                width: "150px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("my-s-q-l-index-type-select", {
                                        model: {
                                          value: row.type,
                                          callback: function ($$v) {
                                            _vm.$set(row, "type", $$v)
                                          },
                                          expression: "row.type",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "注释", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("el-input", {
                                        attrs: { maxlength: "20" },
                                        model: {
                                          value: row.comment,
                                          callback: function ($$v) {
                                            _vm.$set(row, "comment", $$v)
                                          },
                                          expression: "row.comment",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "drawer-footer" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.drawerVisible = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.updateTableData },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
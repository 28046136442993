
export function typeFilter(key) {
  const options = []
  options[10] = 'tinyint'
  options[11] = 'smallint'
  options[12] = 'mediumint'
  options[13] = 'int'
  options[14] = 'bigint'
  options[20] = 'float'
  options[21] = 'double'
  options[22] = 'decimal'
  options[30] = 'date'
  options[31] = 'datetime'
  options[40] = 'char'
  options[41] = 'varchar'
  options[50] = 'text'
  options[51] = 'mediumtext'
  options[52] = 'longtext'

  if (options[key]) {
    return options[key]
  }
  return ''
}

import request from '@/utils/request'

export function treeDirImage() {
  return request({
    url: '/materialCenter/image/dir',
    method: 'get'
  })
}

export function addDirImage(data) {
  return request({
    url: '/materialCenter/image/addDir',
    method: 'post',
    data
  })
}

export function addImage(data) {
  return request({
    url: '/materialCenter/image/add',
    method: 'post',
    data
  })
}

export function listImage(params) {
  return request({
    url: '/materialCenter/image/list',
    method: 'get',
    params
  })
}

export function allSize() {
  return request({
    url: '/materialCenter/image/allSize',
    method: 'get'
  })
}

export function updateName(data) {
  return request({
    url: '/materialCenter/image/updateName',
    returnError: false,
    method: 'post',
    data
  })
}

export function delDataApi(data) {
  return request({
    url: '/materialCenter/image/del',
    method: 'post',
    data
  })
}

export function restoreApi(data) {
  return request({
    url: '/materialCenter/image/restore',
    method: 'post',
    data
  })
}

export function thoroughDelApi(data) {
  return request({
    url: '/materialCenter/image/thoroughDel',
    returnError: true,
    method: 'post',
    data
  })
}

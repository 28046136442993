<template>
  <div class="image-container">
    <el-container>
      <el-aside width="200px" class="aside-menu">
        <el-container>
          <el-header height="40px" style="padding: 0">
            <el-input v-model="filterText" placeholder="搜索文件夹" style="margin-bottom:2px;" />
          </el-header>
          <el-main style="padding: 0">
            <el-tree
              ref="treeDataDir"
              :data="treeDataDirData"
              :props="defaultProps"
              :filter-node-method="filterNode"
              class="filter-tree"
              :highlight-current="true"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              node-key="id"
              :default-expanded-keys="defaultData"
              @node-click="nodeClick"
            >
              <span slot-scope="{ node }" class="custom-tree-node">
                <el-tooltip class="item" effect="dark" :content="node.label" :disabled="node.label | hoverStr(13)" placement="top-start">
                  <span> {{ node.label }} </span>
                </el-tooltip>
              </span>
            </el-tree>

            <el-row class="btn-row">
              <el-col :span="24">
                <el-button :disabled="is_recycle" icon="el-icon-plus" plain @click="dialogAddDir = true, dialogStatus='create'">创建文件夹</el-button>
              </el-col>
            </el-row>
          </el-main>

          <el-footer height="40px" style="padding: 0">
            <el-row class="btn-row">
              <el-col :span="24">
                <el-button type="info" icon="el-icon-delete" plain @click="recycleData">图片回收站</el-button>
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </el-aside>

      <el-dialog :title="dialogTitle[dialogStatus]" :visible.sync="dialogAddDir">
        <el-form ref="dirDataForm" :model="dirForm" :rules="dir_rules" label-width="80px">
          <el-form-item label="创建在">
            <el-select
              ref="selectUpResId"
              v-model="dirForm.parent_id"
              clearable
              placeholder="请选择"
              style="width: 100%"
              prop="parent_id"
              @clear="handleClear"
            >
              <el-option key="parentId" hidden :value="dirForm.parent_id" :label="dirForm.parent_name" />
              <el-tree
                ref="treeDataDirCheck"
                :data="treeDataDirData"
                :props="defaultProps"
                :highlight-current="true"
                :expand-on-click-node="false"
                :check-on-click-node="true"
                node-key="id"
                :default-expanded-keys="defaultData"
                @node-click="nodeClickCheck"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="dirForm.name" maxlength="20" show-word-limit />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogAddDir = false">取 消</el-button>
          <el-button type="primary" @click="createDir">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogUpload" style="text-align: center">
        <upload-qiniu :show-img="showImg" :confirm-upload="confirmUpload" :dir-name="dirName" @uploadComplete="uploadComplete" @onChange="onChange" />
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogUpload = false, showImg=false">取 消</el-button>
          <el-button type="primary" @click="confirmUploadFun">确 定</el-button>
        </div>
      </el-dialog>

      <el-container>
        <el-header height="80px">
          <template>
            <el-row style="background: #f6f6f6; padding: 8px 0 8px 6px;">
              <el-button :type="listType===1?'primary':'default'" :disabled="is_recycle" style="padding: 4px" icon="el-icon-s-grid" size="medium" @click="listType=1" />
              <el-button :type="listType===2?'primary':'default'" style="padding: 4px" icon="el-icon-s-operation" size="medium" @click="tableHeightFun" />

              <el-divider direction="vertical" />

              <el-dropdown @command="handleSort">
                <el-button style="padding: 4px" icon="el-icon-s-unfold" size="medium" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :icon="listQuery.sort === 'name+' || !listQuery.sort ? 'el-icon-check' : ''" command="name+">文件名升序</el-dropdown-item>
                  <el-dropdown-item :icon="listQuery.sort === 'name-' ? 'el-icon-check' : ''" command="name-">文件名降序</el-dropdown-item>
                  <el-dropdown-item :icon="listQuery.sort === 'create_at+' ? 'el-icon-check' : ''" command="create_at+">上传时间升序</el-dropdown-item>
                  <el-dropdown-item :icon="listQuery.sort === 'create_at-' ? 'el-icon-check' : ''" command="create_at-">上传时间降序</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <el-divider direction="vertical" />
              <el-input v-model="listQuery.keyword" prefix-icon="el-icon-search" placeholder="搜索图片或文件夹" style="width: 180px;margin-right: 20px;" @keyup.enter.native="searchName" />

              <el-button :disabled="is_recycle" icon="el-icon-plus" @click="dialogUpload = true, showImg=false, confirmUpload=false">上传文件</el-button>
              <el-button :disabled="is_recycle" icon="el-icon-folder-add" @click="dialogAddDir = true, dialogStatus='create'">新建文件夹</el-button>
            </el-row>
            <el-row style="margin-top: 12px">
              <el-col :span="16">
                <el-breadcrumb v-if="is_recycle" separator="/">
                  <el-breadcrumb-item>
                    <span>图片回收站（图片删除后，将保留7天，超时系统将自动清除。）</span>
                  </el-breadcrumb-item>
                </el-breadcrumb>
                <el-breadcrumb v-else-if="keywordSearch" separator="|">
                  <el-breadcrumb-item>
                    <el-link :underline="false" type="primary" @click="searchDown">返回上一级</el-link>
                  </el-breadcrumb-item>
                  <el-breadcrumb-item>
                    <span>搜索结果：“{{ listQuery.keyword }}”相关结果</span>
                  </el-breadcrumb-item>
                </el-breadcrumb>
                <el-breadcrumb v-else separator="/">
                  <el-breadcrumb-item v-for="(item, i) in clickParentData" :key="item.id">
                    <el-link v-show="i!==clickParentData.length-1" :underline="false" type="primary" @click="breadcrumbClick(item.id)">{{ item.name }}</el-link>
                    <span v-show="i===clickParentData.length-1">{{ item.name }}</span>
                  </el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-breadcrumb separator="/">
                    <el-breadcrumb-item>
                      <el-link :underline="false" type="primary">{{ percentageData.size }}</el-link>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                      <span>10Gb</span>
                    </el-breadcrumb-item>
                  </el-breadcrumb>
                </el-row>
                <el-row style="margin-top: 2px">
                  <el-progress v-model="percentageData.percentage" :text-inside="true" :stroke-width="18" :percentage="percentageData.percentage" />
                </el-row>
              </el-col>
            </el-row>
          </template>
        </el-header>

        <el-main v-loading="listLoading" :style="listType===2 ? 'overflow-y: hidden;' : ''">
          <template v-if="listType===1">
            <el-row v-show="total>0" ref="listList" :gutter="10" :style="{ minWidth: '700px', height: tableHeight }">
              <el-col v-for="item in list" :key="item.id" :xs="6" :sm="5" :md="4" :lg="3" style="text-align: center">
                <div style="height: 130px; background: #f9f9f9;line-height: 130px;" @dblclick="openFile(item.id, item.name, item.type)">
                  <el-image :src="item.url | imageFilter(130)" style="vertical-align: middle; line-height: 0" :preview-src-list="item.url | imagePreviewFilter">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" />
                    </div>
                  </el-image>
                </div>
                <div style="height: 30px; font-family: Hiragino Sans GB; font-size: Small; line-height: 1.7">
                  <el-tooltip v-show="editNameShowId!==item.id" class="item" effect="dark" :content="item.name" :disabled="item.name | hoverStr(6)" placement="top">
                    <span @click="editName(item.id, listType)">{{ item.name | hoverStrOmit(6) }}</span>
                  </el-tooltip>
                  <el-input v-show="editNameShowId===item.id" :ref="`inputEditNameCard` + item.id" v-model="item.name" maxlength="20" minlength="1" @blur="editNameShowId = 0" @keyup.enter.native="reName(item.id, item.parent_id, item.name, item.type)" />
                </div>
              </el-col>
            </el-row>

            <div v-show="total<1" style="text-align: center">
              这里空空的，没有找到图片
            </div>
          </template>

          <template v-if="listType===2">
            <el-table
              ref="tableList"
              v-loading="listLoading"
              :element-loading-text="listLoadingMessage"
              :data="list"
              :height="tableHeight"
              highlight-current-row
              style="width: 100%; overflow: auto !important;"
            >
              <el-table-column label="图片" prop="url" align="center" width="78px" class-name="v-align-t">
                <template slot-scope="{row}">
                  <span @dblclick="openFile(row.id, row.name, row.type)">
                    <el-image :src="row.url | imageFilter" :preview-src-list="row.url | imagePreviewFilter">
                      <div slot="error" class="image-slot">
                        图片加载失败
                      </div>
                    </el-image>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="名称" prop="name" align="center" min-width="120px">
                <template slot-scope="{row}">
                  <el-tooltip v-show="editNameShowId!==row.id" class="item" effect="dark" :content="row.name" :disabled="row.name | hoverStr(12)" placement="top">
                    <span v-if="is_recycle">{{ row.name | hoverStrOmit(12) }}</span>
                    <span v-else @click="editName(row.id, listType)">{{ row.name | hoverStrOmit(12) }}</span>
                  </el-tooltip>
                  <el-input v-show="editNameShowId===row.id" :ref="`inputEditName` + row.id" v-model="row.name" maxlength="20" minlength="1" @blur="editNameShowId = 0" @keyup.enter.native="reName(row.id, row.parent_id, row.name, row.type)" />
                </template>
              </el-table-column>
              <el-table-column label="大小" prop="size" align="center">
                <template slot-scope="{row}">
                  <span v-show="row.type===1">--</span>
                  <span v-show="row.type===2">{{ row.size | imageSize }}</span>
                </template>
              </el-table-column>
              <el-table-column label="图片尺寸" prop="widthHeight" align="center">
                <template slot-scope="{row}">
                  <span v-show="row.type===1">--</span>
                  <span v-show="row.type===2">{{ row.width }} X {{ row.height }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="is_recycle ? '删除时间' : '上传时间'" prop="updateDate" min-width="130px" align="center">
                <template slot-scope="{row}">
                  <span>{{ is_recycle ? row.deleted_date : row.updated_date }}</span>
                </template>
              </el-table-column>
              <el-table-column label="状态" prop="status" align="center">
                <template slot-scope="{row}">
                  <el-tag v-show="row.status===1">正常</el-tag>
                  <el-tag v-show="row.status===2" type="info">失效</el-tag>
                  <el-tag v-show="row.status===3" type="warning">回收</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="是否引用" prop="quoteStatus" align="center">
                <template slot-scope="{row}">
                  <span v-show="row.type===1">--</span>
                  <span v-show="row.type===2 && row.quote_times===0">未引用</span>
                  <span v-show="row.type===2 && row.quote_times>0"><a>已引用</a></span>
                </template>
              </el-table-column>
              <el-table-column label="操作" prop="id" align="center">
                <template slot-scope="{row}">
                  <template v-if="is_recycle">
                    <el-link type="primary" :underline="false" @click="restore(row.id, row.type)">还原</el-link>
                    <el-link type="primary" :underline="false" @click="thoroughDel(row.id)">彻底删除</el-link>
                  </template>

                  <el-dropdown v-else :split-button="false">
                    <span class="el-dropdown-link">
                      操作<i class="el-icon-arrow-down el-icon--right" />
                    </span>
                    <el-dropdown-menu v-if="row.type===1" slot="dropdown">
                      <el-dropdown-item @click.native="noDevelop">重命名</el-dropdown-item>
                      <el-dropdown-item @click.native="noDevelop">移动到</el-dropdown-item>
                      <el-dropdown-item @click.native="deleteData(row.id, row.parent_id, row.type)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                    <el-dropdown-menu v-if="row.type===2" slot="dropdown">
                      <el-dropdown-item @click.native="noDevelop">编辑</el-dropdown-item>
                      <el-dropdown-item @click.native="copyUrl(row.url)">复制链接</el-dropdown-item>
                      <el-dropdown-item @click.native="noDevelop">替换</el-dropdown-item>
                      <el-dropdown-item @click.native="noDevelop">查看引用</el-dropdown-item>
                      <el-dropdown-item @click.native="noDevelop">移动到</el-dropdown-item>
                      <el-dropdown-item @click.native="deleteData(row.id, row.parent_id)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-main>

        <el-footer height="64px" class="flex-footer">
          <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" :page-sizes.sync="pageSizes" @pagination="getList" />
        </el-footer>
      </el-container>

    </el-container>
  </div>
</template>

<script>
import { treeDirImage, addDirImage, addImage, listImage, updateName, delDataApi, restoreApi, thoroughDelApi, allSize } from '@/api/material-center'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import UploadQiniu from '@/components/Upload/Qiniu'
import { ellipsis, ellipsisBool } from '@/utils/validate'
import { copy } from '@/utils/copy'

export default {
  name: 'MaterialCenterImage',
  components: { Pagination, UploadQiniu },
  filters: {
    hoverStr(str, len) {
      return !ellipsisBool(str, len)
    },
    hoverStrOmit(str, len) {
      return ellipsis(str, len)
    },
    imageFilter(src, w) {
      let wd = '/wh78_q60.jpg'
      if (w === 130) {
        wd = '/wh130_q60.jpg'
      }
      if (src === '') {
        return process.env.VUE_APP_IMG_URL + '/system/icon-folder.png' + wd
      }
      return src + wd
    },
    imagePreviewFilter(src) {
      if (src === '') {
        return []
      } else {
        return [src + '/q70.jpg']
      }
    },
    imageSize(size) {
      let t = 'b' // eslint-disable-next-line no-unused-vars
      if (size > 1000) {
        size = size / 1024
        t = 'Kb'
      }
      if (size > 1000) {
        size = size / 1024
        t = 'Mb'
      }

      return Number(size).toFixed(1) + t
    }
  },
  data() {
    return {
      defaultData: [1],
      clickData: { id: 1, label: '全部图片' },
      clickParentData: [],
      filterText: '',
      percentageData: { percentage: 0, size: 0 },
      treeDataDirData: [{
        id: 1,
        label: 'Level one 1'
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dialogAddDir: false,
      dialogStatus: '',
      dialogTitle: {
        update: '编辑文件夹',
        create: '创建文件夹'
      },
      dirForm: {
        id: '',
        name: '',
        parent_id: 1,
        parent_name: '全部图片'
      },
      dir_rules: {
        name: [{ required: true, message: '名称必须填写', trigger: 'change' }]
      },
      listType: 2, // 1-缩略图，2-列表
      editNameShowId: 0,
      pageSizes: [20, 50, 100],
      tableHeight: 300,
      is_recycle: false,
      total: 0,
      list: null,
      listLoading: false,
      listLoadingMessage: '',
      listQuery: {
        parent_id: 1,
        page: 1,
        limit: 20,
        name: '',
        type: '',
        keyword: ''
      },
      keywordSearch: false,
      dirName: '',
      dialogUpload: false, // 上传对话框-控制弹出
      confirmUpload: false, // 确认上传
      showImg: false // 是否显示上传的图片
    }
  },
  watch: {
    filterText(val) {
      this.$refs.treeDataDir.filter(val)
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      if (self.listType === 2) {
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 130 - 12
      }
      // 监听窗口大小变化
      window.onresize = function() {
        if (self.listType === 2) {
          self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 46
        }
      }
    })
  },

  created() {
    this.getTree()
    this.getList()
    this.allFileSize()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },

  methods: {
    noDevelop() {
      this.$notify({
        // title: 'Success',
        message: '该功能暂未开发！敬请期待~',
        type: 'error',
        duration: 1500
      })
    },
    resetAllQuery() {
      this.keywordSearch = false
      this.listQuery = {
        parent_id: 1,
        page: 1,
        limit: 20,
        name: '',
        type: '',
        keyword: ''
      }
    },
    resetSearch() {
      this.keywordSearch = false
      this.listQuery.keyword = ''
    },
    searchName() {
      this.keywordSearch = true
      this.listQuery.page = 1
      this.getList()
    },
    searchDown() {
      this.resetSearch()
      this.getList()
    },
    handleSort(cmd) {
      this.listQuery.sort = cmd
      this.getList()
    },
    tableHeightFun() {
      this.listType = 2
      // nextTick，在dom加载完执行
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.$refs.tableList.$el.offsetTop - 64 - 46
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    getTree() {
      treeDirImage().then(response => {
        this.treeDataDirData = response.data
      })
    },
    allFileSize() {
      allSize().then(response => {
        this.percentageData = response.data
      })
    },
    copyUrl(url) {
      copy(url)
      this.$notify({
        // title: 'Success',
        message: '复制成功',
        type: 'success',
        duration: 1500
      })
    },
    nodeClick(obj, node, selfObj) {
      this.is_recycle = false
      this.listQuery.is_delete = 0
      this.clickData = { id: obj.id, label: obj.label }
      this.listQuery.parent_id = obj.id
      this.dirForm.parent_id = obj.id
      this.dirForm.parent_name = obj.label
      this.resetSearch()
      this.getList()
    },
    nodeClickCheck(obj, node, selfObj) {
      this.dirForm.parent_id = obj.id
      this.dirForm.parent_name = obj.label
      this.$refs.selectUpResId.blur()
    },
    handleClear() {
      // 将选择器的值置空
      this.dirForm.parent_id = 1
      this.dirForm.parent_name = '全部图片'
    },
    resetDirForm() {
      this.dirForm = {
        id: '',
        name: ' ',
        parent_id: this.clickData.id,
        parent_name: this.clickData.label
      }
    },
    createDir() {
      this.$refs['dirDataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.dirForm)
          addDirImage(tempData).then(response => {
            this.getTree()
            this.dialogAddDir = false
            this.defaultData = [1, tempData.parent_id]
            this.list.unshift(response.data)
            this.total++
            this.resetDirForm()
            this.$message({
              message: response.message,
              type: 'success',
              showClose: true,
              center: true,
              duration: 2500
            })
          })
        }
      })
    },
    deleteData(id, pid, type) {
      // 删除文件夹或文件
      delDataApi({ id: id, parent_id: pid }).then(response => {
        this.getList()
        if (type === 1) {
          this.getTree()
        }
        this.$notify({
          message: response.message,
          type: 'success',
          duration: 1500
        })
      })
    },
    restore(id, type) {
      // 还原、恢复
      restoreApi({ id: id }).then(response => {
        this.getList()
        if (type === 1) {
          this.getTree()
        }
        this.$notify({
          message: response.message,
          type: 'success',
          duration: 1500
        })
      })
    },
    thoroughDel(id) {
      // 彻底删除
      this.listLoading = true
      this.listLoadingMessage = '彻底删除中...'
      thoroughDelApi({ id: id }).then(response => {
        this.listLoading = false
        this.listLoadingMessage = ''
        let type
        if (response.status === 0) {
          this.getList()
          type = 'success'
        } else {
          type = 'error'
        }

        this.$notify({
          message: response.message,
          type: type,
          duration: 1500
        })
      })
    },
    breadcrumbClick(pid) {
      this.listQuery.parent_id = pid
      this.getList()
    },
    editName(id, type) {
      if (type === 1) {
        this.editNameShowId = id
        const childMessage = 'inputEditNameCard' + id
        this.$nextTick(() => {
          this.$refs[childMessage][0].focus()
        })
      }
      if (type === 2) {
        this.editNameShowId = id
        const childMessage = 'inputEditName' + id
        this.$nextTick(() => {
          this.$refs[childMessage].focus()
        })
      }
    },
    reName(id, parent_id, name, type) {
      if (name !== '') {
        updateName({ id: id, parent_id: parent_id, name: name }).then(response => {
          this.editNameShowId = 0
          if (type === 1) {
            this.defaultData = [1, id]
            this.getTree()
          }
          this.$message({
            message: response.message,
            type: 'success',
            showClose: true,
            center: true,
            duration: 2500
          })
        })
      }
    },
    openFile(id, name, type) {
      if (type === 1) {
        this.clickData = { id: id, label: name }
        this.dirForm.parent_id = id
        this.dirForm.parent_name = name
        this.listQuery.parent_id = id
        this.getList()
      }
    },
    recycleData() {
      this.resetAllQuery()
      this.listQuery.is_delete = 1
      this.is_recycle = true
      this.listType = 2
      this.getList()
    },
    getList() {
      this.listLoading = true
      listImage(this.listQuery).then(response => {
        this.clickParentData = response.data.breadcrumbArray
        this.dirName = response.data.dirName
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    onChange() {
      // 上传组件onChange返回数据
      this.showImg = true
    },
    confirmUploadFun() {
      // 确认上传，会上传图片到服务端
      this.confirmUpload = true
    },
    uploadComplete(res) {
      // 上传组件uploadComplete返回数据，保存数据到素材库
      res.parent_id = this.clickData.id
      const tempData = Object.assign({}, res)
      addImage(tempData).then(response => {
        this.list.unshift(response.data)
        this.total++
        this.dialogUpload = false
        this.showImg = false
        this.confirmUpload = false
        this.$message({
          message: response.message,
          type: 'success',
          showClose: true,
          center: true,
          duration: 2500
        })
      })
    }
  }
}
</script>

<style lang="scss">
  @import './styles/image.scss';
</style>

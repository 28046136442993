import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/login/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: { token }
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}

export function userRegisterApi(data) {
  return request({
    url: '/login/userRegister',
    method: 'post',
    data
  })
}

export function sendEmailCodeApi(data) {
  return request({
    url: '/login/sendEmailCode',
    method: 'post',
    data
  })
}

export function resetSendEmailCodeApi(data) {
  return request({
    url: '/login/resetSendEmailCode',
    method: 'post',
    data
  })
}

export function userResetApi(data) {
  return request({
    url: '/login/userReset',
    method: 'post',
    data
  })
}

export function userLoginApi(data) {
  return request({
    url: '/login/userLogin',
    method: 'post',
    data
  })
}

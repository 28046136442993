<template>
  <div class="app-container">
    <user-table-list :del-height="delHeight" />
  </div>
</template>

<script>
import UserTableList from '@/components/UserCenter/User/TableList'

export default {
  name: 'UserManager',
  components: { UserTableList },
  data() {
    return {
      delHeight: 64
    }
  }
}
</script>

<style scoped>
</style>


<template>
  <div class="app-container">
    <el-container class="manager-container">
      <el-aside width="220px">
        <div>
          <el-input v-model="filterText" placeholder="搜索分类" style="margin-bottom:2px;" />
        </div>
        <div>
          <el-tree
            ref="typeTreeData"
            :data="typeTreeData"
            :props="defaultProps"
            :filter-node-method="filterNode"
            class="filter-tree"
            :highlight-current="true"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            node-key="id"
            :default-expanded-keys="defaultData"
            @node-click="nodeClick"
          >
            <span slot-scope="{ node }" class="custom-tree-node">
              <el-tooltip class="item" effect="dark" :content="node.label" :disabled="node.label | hoverStr(13)" placement="top-start">
                <span> {{ node.label }} </span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
      </el-aside>

      <el-main class="article-main-content">
        <el-row :gutter="6" style="margin-bottom: 12px">
          <el-col :span="3">
            <el-input v-model="listQuery.id" placeholder="ID" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="listQuery.title" placeholder="标题" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="3">
            <el-select v-model="listQuery.status" placeholder="状态" clearable>
              <el-option v-for="item in statusOptions" :key="item.key" :label="item.display_name" :value="item.key" />
            </el-select>
          </el-col>

          <el-col :span="10">
            <el-button v-waves type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
            <el-button style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">发布新文章</el-button>
            <el-button v-waves type="info" icon="el-icon-search" @click="handleRecycleBin">回收站</el-button>
          </el-col>
        </el-row>
        <div style="margin: 12px 0">
          <el-breadcrumb v-if="!listQuery.is_deleted" separator="/">
            <el-breadcrumb-item v-for="(item, i) in checkTypeArray" :key="i">
              <el-link v-show="i!==checkTypeArray.length-1" :underline="false" type="primary" @click="breadcrumbClick(item.id, item.name)">{{ item.name }}</el-link>
              <span v-show="i===checkTypeArray.length-1">{{ item.name }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <el-breadcrumb v-else separator="*">
            <el-breadcrumb-item>
              回收站
            </el-breadcrumb-item>
            <el-breadcrumb-item>
              （数据在删除后30天将自动彻底删除！）
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-table
          ref="tableList"
          v-loading="listLoading"
          :data="list"
          :height="tableHeight"
          border
          fit
          highlight-current-row
          style="width: 100%; overflow: auto !important;"
          :default-sort="{prop: 'id', order: 'descending'}"
          @sort-change="sortChange"
        >
          <el-table-column label="ID" prop="id" align="center" sortable="custom" :class-name="getSortClass('id')">
            <template slot-scope="{row}">
              <div>{{ row.id }}</div>
              <div v-show="!listQuery.is_deleted"><el-tag :type="row.status === 1 ? 'success' : (row.status === 0 ? 'info' : 'warning')">{{ row.status | statusFilter }}</el-tag></div>
              <div v-show="row.status === 3&&!listQuery.is_deleted">{{ row.release_date }}</div>
              <div v-show="listQuery.is_deleted">
                <div><el-tag type="danger">彻底删除</el-tag></div>
                <div>{{ row.deleted_at | deletedAtFilter }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="标题" align="center" min-width="180px">
            <template slot-scope="{row}">
              <div>
                <el-link v-show="row.status===1" :underline="false" :href="websiteUrl+`/blog/`+row.title_url" target="_blank">{{ row.title }}</el-link>
                <el-link v-show="row.status!==1" :underline="false">{{ row.title }}</el-link>
              </div>
              <div><el-tag>{{ row.title_url }}</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="分类" align="center">
            <template slot-scope="{row}">
              <div>{{ row.article_type_name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="阅读数" prop="read_num" align="center" sortable="custom" :class-name="getSortClass('read_num')" />
          <el-table-column label="评论数" prop="comment_num" align="center" sortable="custom" :class-name="getSortClass('comment_num')" />
          <el-table-column label="时间" align="center" width="180px">
            <template slot-scope="{row}">
              <div v-show="listQuery.is_deleted">删除于 {{ row.deleted_date }}</div>
              <div v-show="!listQuery.is_deleted">创建于 {{ row.create_date }}</div>
              <div v-show="!listQuery.is_deleted">更新于 {{ row.updated_at>0 ? row.updated_date : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="{row,$index}">
              <el-dropdown :split-button="false">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu v-if="listQuery.is_deleted" slot="dropdown">
                  <el-dropdown-item @click.native="handleRestore(row.id, $index)">恢复</el-dropdown-item>
                  <el-dropdown-item @click.native="handleThoroughDel(row.id, $index)">彻底删除</el-dropdown-item>
                </el-dropdown-menu>
                <el-dropdown-menu v-if="row.status===0&&!listQuery.is_deleted" slot="dropdown">
                  <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
                  <el-dropdown-item @click.native="handleDelete(row.id, $index)">删除</el-dropdown-item>
                </el-dropdown-menu>
                <el-dropdown-menu v-if="row.status===1&&!listQuery.is_deleted" slot="dropdown">
                  <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
                  <el-dropdown-item @click.native="handleModifyStatus(row, 2, $index)">下架</el-dropdown-item>
                </el-dropdown-menu>
                <el-dropdown-menu v-if="row.status===2&&!listQuery.is_deleted" slot="dropdown">
                  <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
                  <el-dropdown-item @click.native="handleModifyStatus(row, 1, $index)">上架</el-dropdown-item>
                  <el-dropdown-item @click.native="handleModifyStatus(row, 3, $index)">定时上架</el-dropdown-item>
                  <el-dropdown-item @click.native="handleDelete(row.id, $index)">删除</el-dropdown-item>
                </el-dropdown-menu>
                <el-dropdown-menu v-if="row.status===3" slot="dropdown">
                  <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
                  <el-dropdown-item @click.native="handleModifyStatus(row, 1, $index)">立即上架</el-dropdown-item>
                  <el-dropdown-item @click.native="handleModifyStatus(row, 2, $index)">下架</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListData" />
      </el-main>
    </el-container>

    <el-dialog title="定时上架" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :model="temp" label-position="right" label-width="80px">
        <el-form-item label="ID" prop="id">
          <el-input v-model="temp.id" :disabled="true" />
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="temp.title" :disabled="true" />
        </el-form-item>
        <el-form-item label="发布日期" prop="release_date">
          <el-date-picker
            v-model="temp.release_date"
            type="datetime"
            placeholder="定时发布日期"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            :picker-options="pickerOptions"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="cronReleaseMethod">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { typeTreeListApi, listArticleApi, statusArticleApi, deleteArticleApi, restoreArticleApi, thoroughDelArticleApi } from '@/api/article'
import { ellipsis, ellipsisBool, dateFormat } from '@/utils/validate'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import { mapGetters } from 'vuex'

const statusOptions = [
  { key: '', display_name: '全部' },
  { key: 0, display_name: '待上架（草稿）' },
  { key: 1, display_name: '上架中' },
  { key: 2, display_name: '下架' },
  { key: 3, display_name: '定时上架' }
]
// arr to obj, such as { CN : "China", US : "USA" }
const statusTypeKeyValue = statusOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name
  return acc
}, {})

const sortOptions = [
  { key: 'id+', display_name: 'ID升序' },
  { key: 'id-', display_name: 'ID降序' },
  { key: 'read_num-', display_name: '阅读数' },
  { key: 'comment_num-', display_name: '评论数' },
  { key: 'updated_at-', display_name: '更新时间' }
]

export default {
  name: 'Article',
  components: { Pagination },
  directives: { waves },
  filters: {
    hoverStr(str, len) {
      return !ellipsisBool(str, len)
    },
    hoverStrOmit(str, len) {
      return ellipsis(str, len)
    },
    statusFilter(status) {
      if (statusTypeKeyValue[status]) {
        return statusTypeKeyValue[status]
      }
      return '未知'
    },
    deletedAtFilter(at) {
      // 时间戳转日期
      return dateFormat(at + 30 * 86400, 'Y-M-D H:I')
    }
  },
  data() {
    return {
      checkTypeArray: [{ name: '全部' }],
      typeTreeData: [{ id: 0, label: '全部' }],
      filterText: '',
      defaultData: [0, 0],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableHeight: 300,
      statusOptions,
      sortOptions,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        admin: true,
        type_id: '',
        type_name: '',
        page: 1,
        limit: 20,
        sort: 'id-',
        id: this.$route.params.id,
        is_deleted: ''
      },
      dialogFormVisible: false,
      temp: {
        id: 0,
        title: '',
        release_date: undefined
      },
      pickerOptions: {
        // disabled为函数，返回值为布尔值，禁选日期
        disabledDate: (time) => {
          const now_at = Date.now()
          const minTime = now_at + 10 * 60 * 1000
          const maxTime = now_at + 7 * 86400 * 1000
          return time.getTime() < minTime || time.getTime() > maxTime
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'websiteCode',
      'websiteUrl'
    ])
  },
  watch: {
    filterText(val) {
      this.$refs.typeTreeData.filter(val)
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 60
      // 监听窗口大小变化
      window.onresize = function() {
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 60
      }
    })
  },
  created() {
    this.getTreeData()
    this.getListData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getTreeData() {
      typeTreeListApi({ website_code: this.websiteCode }).then(response => {
        this.typeTreeData = response.data
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    nodeClick(obj, node, selfObj) {
      this.listQuery.id = null
      this.listQuery.type_id = obj.id
      this.listQuery.type_name = obj.label
      this.listQuery.is_deleted = ''
      const typeArray = [{ name: obj.name }]
      let parentData = node.parent
      while (parentData) {
        if (parentData.parent) {
          typeArray.unshift({ id: parentData.data.id, name: parentData.data.name })
        }
        parentData = parentData.parent
      }
      this.checkTypeArray = typeArray
      this.handleFilter()
    },
    breadcrumbClick(id, name) {
      this.listQuery.type_id = id
      this.listQuery.type_name = name
      this.listQuery.is_deleted = ''
      const typeArray = []
      for (const i in this.checkTypeArray) {
        if (id !== this.checkTypeArray[i].id) {
          typeArray.push(this.checkTypeArray[i])
        } else {
          typeArray.push({ name: name })
          break
        }
      }
      this.checkTypeArray = typeArray
      this.handleFilter()
    },
    getListData() {
      this.listLoading = true
      this.listQuery.website_code = this.websiteCode
      listArticleApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      // this.listQuery.id = null
      this.listQuery.page = 1
      this.getListData()
    },
    handleCreate() {
      this.$router.push({
        name: 'ArticleAdd',
        query: { type_id: this.listQuery.type_id, type_name: this.listQuery.type_name },
        params: { type_id: this.listQuery.type_id, type_name: this.listQuery.type_name }
      })
    },
    handleUpdate(row) {
      this.$router.push({
        name: 'ArticleUpdate',
        query: { id: row.id, type_id: row.article_type_id, type_name: row.article_type_name },
        params: { id: row.id, type_id: row.article_type_id, type_name: row.article_type_name }
      })
    },
    handleModifyStatus(row, status, index) {
      if (status === 3) {
        this.temp.id = row.id
        this.temp.title = row.title
        this.temp.release_date = ''
        this.dialogFormVisible = true
      } else {
        statusArticleApi({ id: row.id, status: status }).then(response => {
          this.list.splice(index, 1, response.data)
          this.$notify({
            // title: 'Success',
            message: response.message,
            type: 'success',
            duration: 1500
          })
        })
      }
    },
    handleDelete(id, index) {
      deleteArticleApi({ id: id }).then(response => {
        this.list.splice(index, 1)
        this.$notify({
          // title: 'Success',
          message: response.message,
          type: 'success',
          duration: 1500
        })
      })
    },
    handleRecycleBin() {
      this.listQuery.is_deleted = 1
      this.list = []
      this.handleFilter()
    },
    handleRestore(id, index) {
      restoreArticleApi({ id: id }).then(response => {
        this.list.splice(index, 1)
        this.$notify({
          // title: 'Success',
          message: response.message,
          type: 'success',
          duration: 1500
        })
      })
    },
    handleThoroughDel(id, index) {
      thoroughDelArticleApi({ id: id }).then(response => {
        this.list.splice(index, 1)
        this.$notify({
          // title: 'Success',
          message: response.message,
          type: 'success',
          duration: 1500
        })
      })
    },
    cronReleaseMethod() {
      let errorMessage = ''
      if (!this.temp.release_date) {
        errorMessage = '定时发布日期不能为空'
      } else {
        // 字符串转时间戳
        const release_at = new Date(this.temp.release_date.replace(/-/g, '/')).getTime()
        const now_at = Date.now()
        const minTime = now_at + 2 * 2 * 1000
        const maxTime = now_at + 7 * 86400 * 1000
        if (release_at < minTime || release_at > maxTime) {
          errorMessage = '定时发布，日期至少10分钟后，且不能超过7天'
        }
      }

      if (errorMessage) {
        this.$message({
          message: errorMessage,
          type: 'error'
        })
      } else {
        this.temp.status = 3
        const tempData = Object.assign({}, this.temp)
        statusArticleApi(tempData).then((response) => {
          const index = this.list.findIndex(v => v.id === this.temp.id)
          this.list.splice(index, 1, response.data)
          this.dialogFormVisible = false
          this.$message({
            // title: 'Success',
            message: response.message,
            type: 'success',
            duration: 1500
          })
        })
      }
    },
    // 排序
    sortChange(data) {
      const { prop, order } = data

      if (order === 'ascending') {
        this.listQuery.sort = '+' + prop
      } else {
        this.listQuery.sort = '-' + prop
      }

      this.handleFilter()
    },
    getSortClass: function(key) {
      const sort = this.listQuery.sort
      if (sort === `+${key}`) {
        return 'ascending'
      }
      if (sort === `-${key}`) {
        return 'descending'
      }
      return ''
    }
  }
}
</script>

<style scoped>
  .article-main-content {
    padding: 0 20px 0 8px !important;
  }
  .manager-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .manager-container .pagination-container {
    padding: 10px 0;
  }
  .tab-key-desc span {
    margin-right: 20px;
  }
</style>


var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("p", [_vm._v("数据库渲染视图")]),
      _c("h4", [_vm._v("id: " + _vm._s(_vm.queryData.id))]),
      _c("h4", [_vm._v("view_type: " + _vm._s(_vm.queryData.view_type))]),
      _vm._l(_vm.attributeDrawer, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "drawer-content-detail",
            attrs: { icon: item.icon },
          },
          [
            _c(
              "el-form",
              { attrs: { "label-position": "top" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: item.label } },
                  [
                    item.id === 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: item.value,
                                  callback: function ($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value",
                                },
                              },
                              [_vm._v("选项1")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2" },
                                model: {
                                  value: item.value,
                                  callback: function ($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value",
                                },
                              },
                              [_vm._v("选项2")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.id === 2
                      ? _c(
                          "div",
                          [
                            _c("el-checkbox", { attrs: { label: "1" } }, [
                              _vm._v("选择1"),
                            ]),
                            _c("el-checkbox", { attrs: { label: "2" } }, [
                              _vm._v("选择2"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.id === 3
                      ? _c("el-input", {
                          attrs: { placeholder: "请输入内容" },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
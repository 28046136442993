/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout'

const userCenterRouter = {
  path: '/user',
  component: Layout,
  redirect: '/user/role',
  name: 'User',
  meta: { title: '用户中心', icon: 'el-icon-s-custom' },
  children: [
    {
      path: 'power',
      name: 'Power',
      component: () => import('@/views/user-center/power'),
      meta: { title: '权限管理', icon: 'el-icon-coordinate' }
    },
    {
      path: 'role',
      name: 'Role',
      component: () => import('@/views/user-center/role'),
      meta: { title: '角色管理', icon: 'el-icon-user-solid' }
    },
    {
      path: 'organization',
      name: 'Organization',
      component: () => import('@/views/user-center/organization'),
      meta: { title: '组织管理', icon: 'el-icon-user-solid' }
    },
    {
      path: 'userGroup',
      name: 'UserGroup',
      component: () => import('@/views/user-center/user-group'),
      meta: { title: '用户分组', icon: 'el-icon-user-solid' }
    },
    {
      path: 'userManager',
      name: 'UserManager',
      component: () => import('@/views/user-center/user'),
      meta: { title: '用户管理', icon: 'el-icon-user-solid' }
    },
    {
      path: 'organizationUser',
      name: 'OrganizationUser',
      component: () => import('@/views/user-center/organization-user'),
      meta: { title: '组织用户', icon: 'el-icon-user-solid' }
    }
  ]
}
export default userCenterRouter

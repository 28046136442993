<template>
  <div>
    <el-select v-model="sValue" :disabled="disabled" filterable placeholder="请选择" @change="changeData">
      <el-option
        v-for="(item, $index) in options"
        :key="$index"
        :label="item.value + `-` + item.label"
        :value="item.key"
      />
    </el-select>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sValue: '',
      options: [
        { key: 10, value: 'Index', label: '普通索引' },
        { key: 11, value: 'Unique Key', label: '唯一索引' },
        { key: 12, value: 'Full Text', label: '全文索引' }
      ]
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.sValue = val
      }
    },
    disabled: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {}
    }
  },
  methods: {
    changeData(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
      </el-col>

      <el-col :span="20">
        <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        <el-button type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>
      </el-col>
    </el-row>

    <el-table
      ref="tableList"
      v-loading="listLoading"
      :data="list"
      :height="tableHeight"
      fit
      highlight-current-row
      style="width: 100%; overflow: auto !important;"
    >
      <el-table-column label="ID" align="center">
        <template slot-scope="{row}">
          <div>{{ row.id }}</div>
          <div>{{ row.code }}</div>
        </template>
      </el-table-column>
      <el-table-column label="名称" align="center">
        <template slot-scope="{row}">
          <div>{{ row.name }}</div>
          <div v-show="row.is_system===1"><el-tag>系统</el-tag></div>
        </template>
      </el-table-column>
      <el-table-column label="描述" align="center">
        <template slot-scope="{row}">
          <div>{{ row.desc_text }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{row, $index}">
          <div v-show="row.is_system!==1">
            <el-button v-show="row.id>0" size="mini" type="primary" style="margin-right: 12px" @click="handleUpdate(row)">编辑</el-button>
            <el-popover
              :ref="`popover` + $index"
              placement="top"
              width="240"
              :tabindex="$index"
            >
              <p>删除{{ row.name }} 吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="handleCancelVisible($index)">取消</el-button>
                <el-button size="mini" type="danger" @click="handleDel(row.id, row.name, $index)">确定</el-button>
              </div>
              <el-button slot="reference" size="mini">删除</el-button>
            </el-popover>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListData" />

    <el-dialog title="添加角色" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="60px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="temp.code" maxlength="8" show-word-limit />
        </el-form-item>
        <el-form-item label="描述" prop="code">
          <el-input
            v-model="temp.desc_text"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 3 }"
            placeholder="请输入内容"
            maxlength="100"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="createData">
          确认
        </el-button>
      </div>
    </el-dialog>

    <el-drawer
      :title="temp.name"
      :visible.sync="drawerVisible"
      direction="rtl"
      :show-close="false"
      size="70%"
    >
      <div class="drawer-content">
        <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="60px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="temp.name" maxlength="32" show-word-limit />
          </el-form-item>
          <el-form-item label="编码" prop="code">
            <el-input v-model="temp.code" maxlength="8" show-word-limit />
          </el-form-item>
          <el-form-item label="描述" prop="code">
            <el-input
              v-model="temp.desc_text"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 3 }"
              placeholder="请输入内容"
              maxlength="100"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="权限" prop="role" style="margin-bottom: 0">
            <power-tree-table-select :role-id="temp.id" @checkedPowerData="checkedPowerData" @checkedPowerBackData="checkedPowerBackData" />
          </el-form-item>
        </el-form>

        <div class="drawer-footer">
          <el-button @click="drawerVisible = false">取消</el-button>
          <el-button type="primary" @click="updateData">确认</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { roleListApi, roleAddApi, roleUpdateApi, roleDeleteApi } from '@/api/user-center'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import PowerTreeTableSelect from '@/components/UserCenter/Power/TreeTableSelect'

export default {
  name: 'UserRole',
  components: { Pagination, PowerTreeTableSelect },
  data() {
    return {
      tableHeight: 300,
      listLoading: false,
      list: null,
      listQuery: {
        page: 1,
        limit: 20,
        name: ''
      },
      total: 0,
      temp: {
        id: undefined,
        name: '',
        code: ''
      },
      dialogFormVisible: false,
      rules: {
        name: [{ required: true, message: '名称必须填写', trigger: 'change' }]
      },
      drawerVisible: false,
      autoParentId: [], // 自动选择的父祖ID
      powerArray: [], // 选中了的前台路由
      allPowerArray: [], // 翻了的前台所有路由
      powerBackArray: [], // 选中了的后台路由
      allPowerBackArray: [] // 翻了的后台所有路由
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 64
      // 监听窗口大小变化
      window.onresize = function() {
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 64
      }
    })
  },
  created() {
    this.getListData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getListData() {
      this.listLoading = true
      roleListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.getListData()
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        name: '',
        code: ''
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          roleAddApi(this.temp).then((response) => {
            this.temp = response.data
            this.handleUpdate(this.temp)
            this.getListData()
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.drawerVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    // 组件传回前台权限选择数据
    checkedPowerData({ powerArray, allPowerArray, autoParentId }) {
      this.powerArray = powerArray
      this.allPowerArray = allPowerArray
      this.autoParentId = autoParentId
    },
    // 组件传回后台权限选择数据
    checkedPowerBackData({ powerBackArray, allPowerBackArray }) {
      this.powerBackArray = powerBackArray
      this.allPowerBackArray = allPowerBackArray
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          // 组装前台路由ID参数
          const powerIdArray = []
          const allPowerIdArray = []
          // 按 parent_id 遍历
          for (const i in this.powerArray) {
            // 按 page 页码 遍历
            for (const j in this.powerArray[i]) {
              // 遍历
              for (const k in this.powerArray[i][j]) {
                powerIdArray.push(this.powerArray[i][j][k].id)
              }
            }
          }
          // 按 parent_id 遍历
          for (const i in this.allPowerArray) {
            // 按 page 页码 遍历
            for (const j in this.allPowerArray[i]) {
              // 遍历
              for (const k in this.allPowerArray[i][j]) {
                allPowerIdArray.push(this.allPowerArray[i][j][k].id)
              }
            }
          }
          for (const ai in this.autoParentId) {
            if (!powerIdArray.includes(this.autoParentId[ai])) {
              powerIdArray.push(this.autoParentId[ai])
            }
            if (!allPowerIdArray.includes(this.autoParentId[ai])) {
              allPowerIdArray.push(this.autoParentId[ai])
            }
          }

          // 组装后台路由ID参数
          const powerBackIdArray = []
          const allPowerBackIdArray = []
          // 按 id 遍历
          for (const i in this.powerBackArray) {
            // 遍历
            for (const j in this.powerBackArray[i]) {
              powerBackIdArray.push(this.powerBackArray[i][j].id)
            }
          }
          // 按 id 遍历
          for (const i in this.allPowerBackArray) {
            // 遍历
            for (const j in this.allPowerBackArray[i]) {
              allPowerBackIdArray.push(this.allPowerBackArray[i][j].id)
            }
          }

          this.temp.power_id = powerIdArray.join(',')
          this.temp.all_power_id = allPowerIdArray.join(',')
          this.temp.power_back_id = powerBackIdArray.join(',')
          this.temp.all_power_back_id = allPowerBackIdArray.join(',')

          const tempData = Object.assign({}, this.temp)
          roleUpdateApi(tempData).then((response) => {
            this.getListData()
            this.drawerVisible = false
            this.$message({ message: response.message, type: 'success', duration: 1500 })
          })
        }
      })
    },
    handleCancelVisible(index) {
      this.$refs[`popover` + index].doClose()
    },
    handleDel(id, name, index) {
      this.$refs[`popover` + index].doClose()
      roleDeleteApi({ id: id }).then(response => {
        this.getListData()
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>
  .main-tabs-content {
    padding: 0 20px 20px 8px !important;
  }
  .redis-manager-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .redis-manager-container .el-pagination {
    text-align: center;
    padding-top: 20px;
  }
  .tab-key-desc span {
    margin-right: 20px;
  }
  .redis-manager-container .tip {
    padding: 10px 0;
    text-align: center;
    font-family: "Hiragino Sans GB";
    font-size: 12px;
  }
  .drawer-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px 0 20px;
  }
  .drawer-content form {
    flex: 1;
  }
  .drawer-footer {
    text-align: center;
    display: flex;
    padding: 10px 0 10px 0;
    margin-left: 20%;
    margin-right: 20%;
  }
  .drawer-footer button {
    flex: 1;
  }
</style>


<template>
  <div>
    <el-select v-model="sValue" filterable :placeholder="placeholder" :multiple="multiple" :clearable="clearable" :disabled="disabled" :style="{width: width}" @change="changeData">
      <el-option
        v-for="(item, $index) in options"
        :key="$index"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [Number, String, Array],
      required: true,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100px'
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sValue: ''
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.sValue = val
      }
    },
    options: {
      immediate: true,
      handler(val) {}
    },
    disabled: {
      immediate: true,
      handler(val) {}
    }
  },
  methods: {
    changeData(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

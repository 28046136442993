import request from '@/utils/request'

export function nicheArticleArticleCommentAdminListApi(params) {
  return request({
    url: '/nicheArticle/articleCommentAdmin/list',
    method: 'get',
    params
  })
}

export function nicheArticleArticleCommentAdminDeleteApi(data) {
  return request({
    url: '/nicheArticle/articleCommentAdmin/delete',
    method: 'post',
    data
  })
}

export function nicheArticleArticleCommentAdminWriteUsListApi(params) {
  return request({
    url: '/nicheArticle/articleCommentAdmin/writeUsList',
    method: 'get',
    params
  })
}

<template>
  <div class="app-container">
    <p>数据库渲染视图</p>
    <h4>id: {{ queryData.id }}</h4>
    <h4>view_type: {{ queryData.view_type }}</h4>
    <div v-for="(item, index) in attributeDrawer" :key="index" :icon="item.icon" class="drawer-content-detail">
      <el-form label-position="top">
        <el-form-item :label="item.label">
          <div v-if="item.id===1">
            <el-radio v-model="item.value" label="1">选项1</el-radio>
            <el-radio v-model="item.value" label="2">选项2</el-radio>
          </div>
          <div v-if="item.id===2">
            <el-checkbox label="1">选择1</el-checkbox>
            <el-checkbox label="2">选择2</el-checkbox>
          </div>
          <el-input v-if="item.id===3" v-model="item.value" placeholder="请输入内容" />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { viewConfigDetailApi } from '@/api/config-center'

export default {
  name: 'NoRelease',
  data() {
    return {
      attributeDrawer: [],
      queryData: { id: 0, view_type: 0, type: 0 }
    }
  },
  created() {
    this.queryData = this.$route.meta.query
    this.getDetailData()
  },
  methods: {
    getDetailData() {
      this.queryData.type = this.queryData.view_type
      viewConfigDetailApi(this.queryData).then(response => {
        this.attributeDrawer = response.data
      })
    }
  }
}
</script>

<style scoped>
</style>


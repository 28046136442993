import request from '@/utils/request'

export function websiteAdminListApi(params) {
  return request({
    url: '/nicheArticle/websiteAdmin/list',
    method: 'get',
    params
  })
}

export function websiteAdminCreateApi(data) {
  return request({
    url: '/nicheArticle/websiteAdmin/create',
    method: 'post',
    data
  })
}

export function websiteAdminUpdateApi(data) {
  return request({
    url: '/nicheArticle/websiteAdmin/update',
    method: 'post',
    data
  })
}

export function websiteAdminDestroyApi(data) {
  return request({
    url: '/nicheArticle/websiteAdmin/destroy',
    method: 'post',
    data
  })
}

export function websiteAdminAllApi(params) {
  return request({
    url: '/nicheArticle/websiteAdmin/all',
    method: 'get',
    params
  })
}

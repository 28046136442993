<template>
  <div class="app-container">
    暂未开发
  </div>
</template>

<script>
export default {
  name: 'MaterialCenterVideo'
}
</script>


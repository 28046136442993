<template>
  <div>
    <el-select
      ref="selectRef"
      v-model="sValue"
      multiple
      clearable
      filterable
      placeholder="请选择"
      value-key="id"
      style="width: 100%;"
      @change="changeData"
    >
      <el-option-group
        v-for="(optionData, index) in optionGroup"
        :key="index"
        :label="optionData.name"
      >
        <el-option
          v-for="item in optionData.identity"
          :key="item.id"
          :label="item.name"
          :value="item"
        />
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
import { organizationTreeIdentityApi } from '@/api/user-center'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    userOrganization: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sValue: [], // 选中的数据 [{ id: 1, name: '' }, {}]
      optionGroup: []
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.sValue = val
      }
    },
    userOrganization: {
      immediate: true,
      handler(val) {
        const organizationIdArray = []
        for (const i in val) {
          organizationIdArray.push(val[i].id)
        }
        this.getTree(organizationIdArray.join(','))
      }
    }
  },
  methods: {
    getTree(organization_id) {
      organizationTreeIdentityApi({ organization_id: organization_id }).then(response => {
        this.optionGroup = response.data
        if (this.sValue) {
          const allIdentity = []
          const sValueTemp = []
          for (const i in this.optionGroup) {
            for (const j in this.optionGroup[i].identity) {
              allIdentity[this.optionGroup[i].identity[j].id] = true
            }
          }

          for (const k in this.sValue) {
            if (allIdentity[this.sValue[k].id]) {
              sValueTemp.push(this.sValue[k])
            }
          }
          this.sValue = sValueTemp
        }
      })
    },
    changeData(data) {
      this.$emit('input', data)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import qs from 'qs'
import { getToken } from '@/utils/auth'
const md5 = require('md5')
import { base64Encode } from '@/utils/tool'
import Cookies from 'js-cookie'

// 设置axios为form-data
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
axios.defaults.transformRequest = [function(data) {
  return qs.stringify(data)
}]

const authKey = 'hsxx'
const xy = window.location.protocol
let baseUrl = process.env.VUE_APP_BASE_API
if (xy === 'https:') {
  baseUrl = baseUrl.replace(/http:/, 'https:')
}
// create an axios instance
const service = axios.create({
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30 * 1000 // request timeout
})

// 排序的函数
function objKeySort(obj) {
  const newkey = Object.keys(obj).sort()
  // 先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  // 创建一个新的对象，用于存放排好序的键值对
  const newObj = {}
  // 遍历newkey数组
  for (let i = 0; i < newkey.length; i++) {
    // 向新创建的对象中按照排好的顺序依次增加键值对
    newObj[newkey[i]] = obj[newkey[i]]
  }
  return newObj
}

const websiteCode = Cookies.get('websiteCode') || 'Niche'

// 是否返回错误
let returnError = false
// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.returnError) {
      returnError = true
    } else {
      returnError = false
    }

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = getToken()
    }
    config.headers['Website-Code'] = websiteCode
    let dataArray = []
    for (const p in config.params) {
      dataArray[p] = config.params[p]
    }
    const dataObject = qs.parse(config.data)
    for (const d in dataObject) {
      dataArray[d] = dataObject[d]
    }

    dataArray = objKeySort(dataArray)
    let sign = ''
    const timestamp = new Date().getTime()
    for (const ds in dataArray) {
      if (ds === 'sign' || ds === 'timestamp') {
        continue
      }
      if (dataArray[ds] === undefined || dataArray[ds] === null) {
        continue
      }
      if ((typeof dataArray[ds]) === 'object' && dataArray[ds].length === 0) {
        continue
      }
      sign += ds + (dataArray[ds] === null ? '' : dataArray[ds])
    }
    sign = sign + authKey + timestamp
    sign = md5(sign)
    sign = base64Encode(sign, 3)
    if (config.params) {
      config.params.sign = sign
      config.params.timestamp = timestamp
    } else {
      config.params = { sign: sign, timestamp: timestamp }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */
  /**
   * Determine the request status by custom status
   * Here is just an example
   * You can also judge the status by HTTP Status status
   */
  response => {
    const res = response.data

    // if the custom status is not 0, it is judged as an error.
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.status === 50008 || res.status === 50012 || res.status === 50014) {
      // to re-login
      MessageBox.confirm('已退出登录，无法操作，请重新登录后操作', '未登录', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      })
      return Promise.reject(new Error(res.message || 'Error'))
    }

    // 控制返回非0错误码的结果到上一步
    if (res.status === 0 || returnError) {
      return res
    } else {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service

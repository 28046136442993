var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          style: { width: _vm.width },
          attrs: {
            filterable: "",
            placeholder: _vm.placeholder,
            multiple: _vm.multiple,
            clearable: _vm.clearable,
            disabled: _vm.disabled,
          },
          on: { change: _vm.changeData },
          model: {
            value: _vm.sValue,
            callback: function ($$v) {
              _vm.sValue = $$v
            },
            expression: "sValue",
          },
        },
        _vm._l(_vm.options, function (item, $index) {
          return _c("el-option", {
            key: $index,
            attrs: { label: item.label, value: item.value },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
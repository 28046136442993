<template>
  <div class="app-container">
    <h4>基本信息</h4>
    <el-form ref="dataForm" :rules="rules" :model="userInfo" label-position="right" label-width="100px">
      <el-form-item label="" prop="avatar">
        <el-avatar :size="80" :src="userInfo.avatar" style="position: relative;" @mouseover.native="overShow">
          <div slot="default">暂无</div>
        </el-avatar>
        <el-avatar v-show="showAvatar" :size="80" icon="el-icon-camera-solid" style="position: absolute; left: 0; background: rgba(0,0,0,.5); cursor:pointer;" @mouseout.native="outHide" @click.native="updateAvatarVisible = true, showImg=false, confirmUpload=false" />
      </el-form-item>
      <el-form-item label="昵称" prop="name">
        <el-input v-if="editData" v-model="userInfo.name" maxlength="32" show-word-limit style="width: 220px" />
        <span v-else>{{ userInfo.name }}</span>
      </el-form-item>
      <el-form-item label="账号" prop="login_name">
        <span>{{ userInfo.login_name }}</span>
      </el-form-item>
      <el-form-item label="出生日期" prop="login_name">
        <el-date-picker
          v-if="editData"
          v-model="userInfo.birthday"
          type="date"
          placeholder="选择日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        />
        <span v-else>{{ userInfo.birthday }}</span>
      </el-form-item>
      <el-form-item label="注册日期" prop="login_name">
        <span>{{ userInfo.create_date }}</span>
      </el-form-item>
    </el-form>
    <div v-if="editData" slot="footer" class="dialog-footer">
      <el-button @click="closeEdit">
        取消
      </el-button>
      <el-button type="primary" @click="updateData">
        确认
      </el-button>
    </div>
    <el-link v-if="!editData" icon="el-icon-edit" @click="openEdit">编辑基本资料</el-link>
    <el-divider content-position="left"><i class="el-icon-user" /></el-divider>
    <h4>账号设置</h4>
    <el-form ref="userDataForm" :rules="userDataRules" :model="userInfo" label-position="right" label-width="100px">
      <el-form-item label="密码" prop="password">
        <span v-if="!userInfo.has_password" style="padding-right: 10px"> 存在风险，请修改密码 </span>
        <el-link v-if="!editData" :underline="false" @click="openUpdatePassword(userInfo)">修改密码</el-link>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <international-telephone-code-select v-if="editData" v-model="userInfo.area_code" style="width: 140px;display: inline-block" />
        <el-input v-if="editData" v-model.trim="userInfo.phone" minlength="11" maxlength="11" show-word-limit style="width: 150px;display: inline-block" @input="userInfo.phone=userInfo.phone.replace(/[^\d]/g, '')" />
        <span v-else>{{ userInfo.phone }}</span>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-if="editData" v-model="userInfo.email" maxlength="32" show-word-limit style="width: 290px" />
        <span v-else>{{ userInfo.email }}</span>
      </el-form-item>
    </el-form>

    <!--修改头像-->
    <el-dialog title="上传头像" :visible.sync="updateAvatarVisible" style="text-align: center">
      <upload-qiniu :show-img="showImg" :confirm-upload="confirmUpload" :dir-name="dirName" @uploadComplete="uploadComplete" @onChange="onChange" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateAvatarVisible = false, showImg=false">取 消</el-button>
        <el-button type="primary" @click="confirmUploadFun">确 定</el-button>
      </div>
    </el-dialog>
    <!--修改免密-->
    <el-dialog title="修改密码" :visible.sync="updatePasswordVisible">
      <el-form ref="passwordDataForm" :rules="passwordRules" :model="passwordData" label-position="right" label-width="100px">
        <el-form-item v-if="passwordData.has_password" label="原密码" prop="ori_password">
          <el-input v-model="passwordData.ori_password" placeholder="请输入原密码" maxlength="32" show-password />
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="passwordData.password" placeholder="请输入新密码" maxlength="32" show-password />
        </el-form-item>
        <el-form-item label="确认新密码" prop="con_password">
          <el-input v-model="passwordData.con_password" placeholder="请再输入新密码" maxlength="32" show-password />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeUpdatePassword">
          取消
        </el-button>
        <el-button type="primary" @click="updatePassword">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { userInfoApi, userUpdateInfoApi, userUpdatePasswordApi, userUpdateAvatarApi } from '@/api/user-center'
import InternationalTelephoneCodeSelect from '@/components/ConfigCenter/InternationalTelephoneCode/Select'
import UploadQiniu from '@/components/Upload/Qiniu'

export default {
  name: 'MyUserInfo',
  components: { InternationalTelephoneCodeSelect, UploadQiniu },
  data() {
    return {
      userInfo: { name: '', id: null },
      showAvatar: false,
      updateAvatarVisible: false,
      dirName: 'user/avatar/', // 上传头像的文件夹
      confirmUpload: false, // 确认上传
      showImg: false, // 是否显示上传的图片
      rules: null,
      userDataRules: {
        phone: [{
          pattern: /^1[3-9]\d{9}$/,
          message: '手机号格式错误',
          trigger: 'blur'
        }],
        email: [{
          pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,8})$/,
          message: '邮箱格式错误',
          trigger: 'blur'
        }]
      },
      editData: false,
      pickerOptions: {
        // disabled为函数，返回值为布尔值，禁选日期
        disabledDate: (time) => {
          const now_at = Date.now()
          const minTime = now_at - 200 * 365 * 86400 * 1000 // 200年前的不可选
          return time.getTime() < minTime || time.getTime() > now_at // 大于当前不可选
        }
      },
      passwordRules: null,
      updatePasswordVisible: false,
      passwordData: { has_password: false, password: '', con_password: '', ori_password: '' }
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    overShow() {
      this.showAvatar = true
    },
    outHide() {
      this.showAvatar = false
    },
    onChange() {
      // 上传组件onChange返回数据
      this.showImg = true
    },
    confirmUploadFun() {
      // 确认上传，会上传图片到服务端
      this.confirmUpload = true
    },
    uploadComplete(res) {
      // 上传组件uploadComplete返回数据，保存数据到素材库
      userUpdateAvatarApi({ url: res.url }).then(response => {
        this.updateAvatarVisible = false
        this.showImg = false
        this.userInfo.avatar = res.url
        this.$store.dispatch('user/updateAvatar', res.url)
        this.$message({ type: 'success', message: response.message })
      })
    },
    openEdit() {
      this.editData = true
      this.rules = {
        name: [{ required: true, message: '昵称不能为空', trigger: 'change' }]
      }
    },
    closeEdit() {
      this.editData = false
      this.rules = null
    },
    getUserInfo() {
      userInfoApi().then(response => {
        this.userInfo = response.data
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$refs['userDataForm'].validate((userDataFormValid) => {
            if (userDataFormValid) {
              userUpdateInfoApi(this.userInfo).then(response => {
                this.closeEdit()
                this.$message({
                  type: 'success',
                  message: response.message
                })
              })
            }
          })
        }
      })
    },
    openUpdatePassword(userInfo) {
      this.updatePasswordVisible = true
      if (userInfo.has_password) {
        this.passwordData = { has_password: true, password: '', con_password: '', ori_password: '' }
        this.passwordRules = {
          ori_password: [{ required: true, message: '原密码不能为空', trigger: 'change' }],
          password: [
            { min: 6, max: 32, message: '长度在 6 到 32 个字符', trigger: 'blur' },
            {
              required: true,
              validator: this.passwordValidator,
              trigger: 'blur'
            }],
          con_password: [{
            required: true,
            validator: this.conPasswordValidator,
            trigger: 'blur'
          }]
        }
      } else {
        this.passwordData = { has_password: false, password: '', con_password: '', ori_password: '' }
        this.passwordRules = {
          password: [{
            required: true,
            validator: this.passwordValidator,
            trigger: 'blur'
          }],
          con_password: [{
            required: true,
            validator: this.conPasswordValidator,
            trigger: 'blur'
          }]
        }
      }
    },
    passwordValidator(rule, value, callback) {
      if (!value) {
        callback(new Error('新密码不能为空'))
      } else if (value !== this.passwordData.con_password) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    },
    conPasswordValidator(rule, value, callback) {
      if (!value) {
        callback(new Error('确认密码不能为空'))
      } else if (value !== this.passwordData.password) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    },
    closeUpdatePassword() {
      this.updatePasswordVisible = false
    },
    updatePassword() {
      this.$refs['passwordDataForm'].validate((valid) => {
        if (valid) {
          userUpdatePasswordApi(this.passwordData).then(response => {
            this.closeUpdatePassword()
            this.userInfo.has_password = true
            this.$message({
              type: 'success',
              message: response.message
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>


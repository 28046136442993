var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "12px" }, attrs: { gutter: 8 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "ID", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  },
                },
                model: {
                  value: _vm.listQuery.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "id", $$v)
                  },
                  expression: "listQuery.id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  },
                },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                attrs: { placeholder: "手机号", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  },
                },
                model: {
                  value: _vm.listQuery.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "phone", $$v)
                  },
                  expression: "listQuery.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "状态", clearable: "" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.display_name, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleFilter },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-edit" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "tableList",
          staticStyle: { width: "100%", overflow: "auto !important" },
          attrs: {
            data: _vm.list,
            height: _vm.tableHeight,
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(_vm._s(row.id))]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                row.status === 1
                                  ? "success"
                                  : row.status === 2
                                  ? "warning"
                                  : "danger",
                            },
                          },
                          [_vm._v(_vm._s(_vm._f("statusFilter")(row.status)))]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "账号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _vm._v(_vm._s(row.name) + " "),
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.is_system === 1,
                                expression: "row.is_system===1",
                              },
                            ],
                          },
                          [_vm._v("系统")]
                        ),
                      ],
                      1
                    ),
                    _c("div", [_vm._v(_vm._s(row.login_name))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "联系信息", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        "手机：" +
                          _vm._s(row.area_code) +
                          " " +
                          _vm._s(row.phone)
                      ),
                    ]),
                    _c("div", [_vm._v("邮箱：" + _vm._s(row.email))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "生日", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(_vm._s(row.birthday))]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm._f("ageByBirthdayFilter")(row.birthday))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "组织", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v("角色： "),
                      row.userRoleData && row.userRoleData.length > 0
                        ? _c(
                            "span",
                            _vm._l(row.userRoleData, function (item) {
                              return _c("span", { key: item.id }, [
                                _vm._v(_vm._s(item.name) + "; "),
                              ])
                            }),
                            0
                          )
                        : _c("span", [_vm._v("~")]),
                    ]),
                    _c("div", [
                      _vm._v("部门： "),
                      row.userOrganization && row.userOrganization.length > 0
                        ? _c(
                            "span",
                            _vm._l(row.userOrganization, function (item) {
                              return _c("span", { key: item.id }, [
                                _vm._v(_vm._s(item.name) + "; "),
                              ])
                            }),
                            0
                          )
                        : _c("span", [_vm._v("~")]),
                    ]),
                    _c("div", [
                      _vm._v("身份： "),
                      row.userIdentity && row.userIdentity.length > 0
                        ? _c(
                            "span",
                            _vm._l(row.userIdentity, function (item) {
                              return _c("span", { key: item.id }, [
                                _vm._v(_vm._s(item.name) + "; "),
                              ])
                            }),
                            0
                          )
                        : _c("span", [_vm._v("~")]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v("注册：" + _vm._s(row.create_date))]),
                    _c("div", [
                      _vm._v(
                        "更新：" +
                          _vm._s(row.updated_at > 0 ? row.updated_date : "-")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-dropdown",
                      { attrs: { "split-button": false } },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _vm._v(" 操作"),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]),
                        row.is_system === 1
                          ? _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleUpdate(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleUpdate(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.status === 1,
                                        expression: "row.status===1",
                                      },
                                    ],
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.statusData(row, 2)
                                      },
                                    },
                                  },
                                  [_vm._v("冻结")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.status === 1,
                                        expression: "row.status===1",
                                      },
                                    ],
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.statusData(row, 3)
                                      },
                                    },
                                  },
                                  [_vm._v("注销")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.status === 2,
                                        expression: "row.status===2",
                                      },
                                    ],
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.statusData(row, 1)
                                      },
                                    },
                                  },
                                  [_vm._v("解冻")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.status === 3,
                                        expression: "row.status===3",
                                      },
                                    ],
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.statusData(row, 1)
                                      },
                                    },
                                  },
                                  [_vm._v("恢复")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.status !== 1,
                                        expression: "row.status!==1",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        ref: "popover" + $index,
                                        attrs: {
                                          placement: "top",
                                          width: "240",
                                          tabindex: $index,
                                        },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "删除用户 `" +
                                              _vm._s(row.name) +
                                              "` 吗？"
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                              margin: "0",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCancelVisible(
                                                      $index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("取消")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDel(
                                                      row.id,
                                                      row.name,
                                                      $index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("确定")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getListData,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "user-info-dialog",
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                inline: true,
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "top",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "昵称", prop: "name" },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "32", "show-word-limit": "" },
                    model: {
                      value: _vm.temp.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "name", $$v)
                      },
                      expression: "temp.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "账号", prop: "login_name" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.temp.is_system ? true : false,
                      maxlength: "32",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.temp.login_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "login_name", $$v)
                      },
                      expression: "temp.login_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "手机号", prop: "phone" },
                },
                [
                  _c("international-telephone-code-select", {
                    staticStyle: { width: "30%", display: "inline-block" },
                    model: {
                      value: _vm.temp.area_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "area_code", $$v)
                      },
                      expression: "temp.area_code",
                    },
                  }),
                  _c("el-input", {
                    staticStyle: { width: "70%", display: "inline-block" },
                    attrs: {
                      minlength: "11",
                      maxlength: "11",
                      "show-word-limit": "",
                    },
                    on: {
                      input: function ($event) {
                        _vm.temp.phone = _vm.temp.phone.replace(/[^\d]/g, "")
                      },
                    },
                    model: {
                      value: _vm.temp.phone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.temp,
                          "phone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "temp.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "50%" },
                  attrs: { label: "邮箱", prop: "email" },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", "show-word-limit": "" },
                    on: {
                      input: function ($event) {
                        _vm.temp.email = _vm.temp.email.replace(
                          /[\u4E00-\u9FA5]/g,
                          ""
                        )
                      },
                    },
                    model: {
                      value: _vm.temp.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.temp,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "temp.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "45%" },
                  attrs: { label: "生日", prop: "birthday" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "选择日期",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickerOptions,
                    },
                    model: {
                      value: _vm.temp.birthday,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "birthday", $$v)
                      },
                      expression: "temp.birthday",
                    },
                  }),
                ],
                1
              ),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _c("i", { staticClass: "el-icon-suitcase" }),
                _vm._v(" 组织信息 "),
              ]),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "用户角色", prop: "role" },
                },
                [
                  _c("role-select", {
                    attrs: { disabled: _vm.temp.is_system ? true : false },
                    model: {
                      value: _vm.temp.userRoleData,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "userRoleData", $$v)
                      },
                      expression: "temp.userRoleData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "组织部门", prop: "organization" },
                },
                [
                  _c("organization-tree-select", {
                    model: {
                      value: _vm.temp.userOrganization,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "userOrganization", $$v)
                      },
                      expression: "temp.userOrganization",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "组织身份", prop: "organization" },
                },
                [
                  _c("identity-group-select", {
                    attrs: { "user-organization": _vm.temp.userOrganization },
                    model: {
                      value: _vm.temp.userIdentity,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "userIdentity", $$v)
                      },
                      expression: "temp.userIdentity",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request'

export function cronList(params) {
  return request({
    url: '/configCenter/cron/list',
    method: 'get',
    params
  })
}

export function createCron(data) {
  return request({
    url: '/configCenter/cron/add',
    method: 'post',
    data
  })
}

export function updateCron(data) {
  return request({
    url: '/configCenter/cron/update',
    method: 'post',
    data
  })
}

export function updateStatus(data) {
  return request({
    url: '/configCenter/cron/status',
    method: 'post',
    data
  })
}

export function importData() {
  return request({
    url: '/configCenter/cron/import',
    returnError: true,
    method: 'post'
  })
}

export function logData(data) {
  return request({
    url: '/configCenter/cron/log',
    method: 'post',
    data
  })
}

export function queueList(params) {
  return request({
    url: '/configCenter/queue/list',
    method: 'get',
    params
  })
}

export function queueErrorLog(params) {
  return request({
    url: '/configCenter/queue/errorLog',
    method: 'get',
    params
  })
}

export function variableListApi(params) {
  return request({
    url: '/configCenter/variable/list',
    method: 'get',
    params
  })
}

export function variableAddApi(data) {
  return request({
    url: '/configCenter/variable/add',
    method: 'post',
    data
  })
}

export function variableUpdateApi(data) {
  return request({
    url: '/configCenter/variable/update',
    method: 'post',
    data
  })
}

export function redisIndexApi() {
  return request({
    url: '/configCenter/redisManager/index',
    method: 'get'
  })
}

export function redisListApi(params) {
  return request({
    url: '/configCenter/redisManager/list',
    method: 'get',
    params
  })
}

export function redisDetailApi(params) {
  return request({
    url: '/configCenter/redisManager/detail',
    method: 'get',
    params
  })
}

export function redisDelApi(data) {
  return request({
    url: '/configCenter/redisManager/del',
    method: 'post',
    data
  })
}

export function internationalTelephoneCodeListApi() {
  return request({
    url: '/configCenter/internationalTelephoneCode/list',
    method: 'get'
  })
}

// 数据源配置
export function databaseMenuApi() {
  return request({
    url: '/configCenter/dataSource/databaseMenu',
    method: 'get'
  })
}

export function addDatabaseApi(data) {
  return request({
    url: '/configCenter/dataSource/addDatabase',
    method: 'post',
    data
  })
}

export function updateDatabaseApi(data) {
  return request({
    url: '/configCenter/dataSource/updateDatabase',
    method: 'post',
    data
  })
}

export function deleteDatabaseApi(data) {
  return request({
    url: '/configCenter/dataSource/deleteDatabase',
    method: 'post',
    data
  })
}

// 数据表配置
export function tableListApi(params) {
  return request({
    url: '/configCenter/dataSource/tableList',
    method: 'get',
    params
  })
}

export function addTableApi(data) {
  return request({
    url: '/configCenter/dataSource/addTable',
    method: 'post',
    data
  })
}

export function detailTableApi(params) {
  return request({
    url: '/configCenter/dataSource/detailTable',
    method: 'get',
    params
  })
}

export function updateTableApi(data) {
  return request({
    url: '/configCenter/dataSource/updateTable',
    method: 'post',
    data
  })
}

export function deleteTableApi(data) {
  return request({
    url: '/configCenter/dataSource/deleteTable',
    method: 'post',
    data
  })
}

// 视图配置
export function viewConfigDetailApi(params) {
  return request({
    url: '/configCenter/viewConfig/detail',
    method: 'get',
    params
  })
}

export function viewConfigSaveApi(data) {
  return request({
    url: '/configCenter/viewConfig/save',
    method: 'post',
    data
  })
}

// 数据表选择
export function dataSourceTableSelectApi(params) {
  return request({
    url: '/configCenter/dataSource/tableSelect',
    method: 'get',
    params
  })
}

export function dataSourceTableFieldIndexApi(params) {
  return request({
    url: '/configCenter/dataSource/tableFieldIndex',
    method: 'get',
    params
  })
}

/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout'

const configCenterRouter = {
  path: '/config',
  component: Layout,
  redirect: '/config/cron',
  name: 'Config',
  meta: { title: '运维中心', icon: 'el-icon-s-platform' },
  children: [
    {
      path: 'cron',
      name: 'Cron',
      component: () => import('@/views/config-center/cron'),
      meta: { title: '定时任务', icon: 'el-icon-timer' }
    },
    {
      path: 'queue',
      name: 'Queue',
      component: () => import('@/views/config-center/queue'),
      meta: { title: '队列任务', icon: 'el-icon-date' }
    },
    {
      path: 'variable',
      name: 'Variable',
      component: () => import('@/views/config-center/variable'),
      meta: { title: '全局变量', icon: 'el-icon-s-tools' }
    },
    {
      path: 'redisManager',
      name: 'RedisManager',
      component: () => import('@/views/config-center/redis-manager'),
      meta: { title: 'redis管理', icon: 'el-icon-cpu' }
    },
    {
      path: 'dataSource',
      name: 'DataSource',
      component: () => import('@/views/config-center/data-source'),
      meta: { title: '数据源配置', icon: 'el-icon-s-tools' }
    }
  ]
}
export default configCenterRouter

<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.code" placeholder="code" clearable @keyup.enter.native="handleFilter" />
      </el-col>

      <el-col :span="8">
        <el-button v-waves type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        <el-button style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>
      </el-col>
    </el-row>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" align="center">
        <template slot-scope="{row}">
          <div>{{ row.id }}</div>
        </template>
      </el-table-column>
      <el-table-column label="编码" min-width="168px" align="center">
        <template slot-scope="{row}">
          <div>{{ row.code }}</div>
        </template>
      </el-table-column>
      <el-table-column label="名称" min-width="168px" align="center">
        <template slot-scope="{row}">
          <div>{{ row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="URL" min-width="168px" align="center">
        <template slot-scope="{row}">
          <div><a :href="row.url" target="_blank">{{ row.url }}</a></div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="{row, $index}">
          <el-dropdown :split-button="false">
            <span class="el-dropdown-link">
              操作<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-link :href="baseUrl + `/nginx/` + row.code" target="_blank">查看NGINX报表</el-link>
              </el-dropdown-item>
              <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
              <el-dropdown-item>
                <el-popover
                  :ref="`popoverDel` + $index"
                  placement="top"
                  style="display: block; width: 100%;"
                >
                  <p>删除 '{{ row.name }}' 吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="handleCancelVisible($index)">取消</el-button>
                    <el-button size="mini" type="danger" @click="handleDel(row.id, $index)">确定</el-button>
                  </div>
                  <span slot="reference" style="display: block; width: 100%;">删除</span>
                </el-popover>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px">
        <el-form-item label="编码" prop="code">
          <el-input v-model="temp.code" :disabled="dialogStatus!=='create'" maxlength="8" show-word-limit />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="20" show-word-limit />
        </el-form-item>
        <el-form-item label="url" prop="url">
          <el-input v-model="temp.url" maxlength="200" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { websiteAdminListApi, websiteAdminCreateApi, websiteAdminUpdateApi, websiteAdminDestroyApi } from '@/api/website'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

const baseUrl = process.env.VUE_APP_BASE_API
export default {
  name: 'WebsiteInfo',
  components: { Pagination },
  directives: { waves },
  data() {
    return {
      baseUrl,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: { page: 1, limit: 20 },
      temp: { id: undefined, name: '', code: '', url: '' },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{ required: true, message: '名称必须填写', trigger: 'change' }],
        code: [{ type: 'string', required: true, message: '编码必须填写', trigger: 'change' }]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      websiteAdminListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => { this.listLoading = false }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        name: '',
        key: '',
        value: '',
        last_value: '',
        create_at: 0,
        updated_at: 0
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          websiteAdminCreateApi(this.temp).then((response) => {
            this.temp = response.data
            this.list.unshift(this.temp)
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          websiteAdminUpdateApi(tempData).then((response) => {
            const index = this.list.findIndex(v => v.id === this.temp.id)
            this.temp = response.data
            this.list.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleCancelVisible(index) {
      this.$refs[`popoverDel` + index].doClose()
    },
    handleDel(id, index) {
      this.$refs[`popoverDel` + index].doClose()
      websiteAdminDestroyApi({ id: id }).then(response => {
        this.list.splice(index, 1)
        this.$message({ message: response.message, type: 'success' })
      })
    }
  }
}
</script>

<style scoped>

</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        { staticClass: "organization-el-container" },
        [
          _c(
            "el-aside",
            {
              staticStyle: { "overflow-y": "hidden" },
              attrs: { width: "240px" },
            },
            [
              _c("organization-tree-list-menu", {
                attrs: {
                  height: _vm.tableHeight + 20 + "px",
                  refresh: _vm.refreshTree,
                },
                on: { nodeClick: _vm.nodeClick, treeData: _vm.treeData },
              }),
            ],
            1
          ),
          _c(
            "el-main",
            { staticClass: "main-tabs-content" },
            [
              _c("user-table-list", {
                attrs: {
                  "del-height": _vm.delHeight,
                  "refresh-user-list": _vm.refreshUserList,
                  "now-organization": _vm.nowOrganization,
                },
                on: {
                  tableHeightData: _vm.tableHeightData,
                  tableListData: _vm.tableListData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <el-input v-model="listQuery.id" placeholder="用户ID" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.name" placeholder="用户账号" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.email" placeholder="用户邮箱" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="8">
        <el-button v-waves type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-col>
    </el-row>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" align="center">
        <template slot-scope="{row}">
          <div>ID：<b>{{ row.id }}</b></div>
          <div><el-link :underline="false" type="primary" :href="websiteUrl + '/user/' + row.id" target="_blank">{{ row.name }}</el-link></div>
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="100px" align="center">
        <template slot-scope="{row}">
          <div>{{ row.nick_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="邮箱" min-width="100px" align="center">
        <template slot-scope="{row}">
          <div>{{ row.email }}</div>
        </template>
      </el-table-column>
      <el-table-column label="生日" min-width="100px" align="center">
        <template slot-scope="{row}">
          <div>{{ row.birthday }}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="100px" align="center">
        <template slot-scope="{row}">
          <div><el-tag :type="row.status===2 ? `warning` : (row.status===3 ? `danger` : ``)">{{ row.status | statusFilter }}</el-tag></div>
        </template>
      </el-table-column>
      <el-table-column label="时间" width="168px" align="center">
        <template slot-scope="{row}">
          <div>创建于：{{ row.create_date }}</div>
          <div>更新于：{{ row.updated_date }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="{row, $index}">
          <el-button v-if="row.status!==2" @click="handelStatus(row.id, 2, $index)">冻结</el-button>
          <el-button v-else @click="handelStatus(row.id, 4, $index)">解冻</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { nicheArticleUserAdminListApi, nicheArticleUserAdminStatusApi } from '@/api/article-user'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import { mapGetters } from 'vuex'

const statusOptions = [
  { key: 1, display_name: 'VIP' },
  { key: 2, display_name: '冻结' },
  { key: 3, display_name: '注销' },
  { key: 4, display_name: '普通用户' }
]
// arr to obj, such as { CN : "China", US : "USA" }
const statusTypeKeyValue = statusOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name
  return acc
}, {})

export default {
  name: 'ArticleUser',
  components: { Pagination },
  directives: { waves },
  filters: {
    statusFilter(status) {
      if (statusTypeKeyValue[status]) {
        return statusTypeKeyValue[status]
      }
      return '未知'
    }
  },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: { page: 1, limit: 20 }
    }
  },
  computed: {
    ...mapGetters([
      'websiteCode',
      'websiteUrl'
    ])
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      nicheArticleUserAdminListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => { this.listLoading = false }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handelStatus(id, status, index) {
      nicheArticleUserAdminStatusApi({ user_id: id, status: status }).then(response => {
        this.getList()
        this.$message({ message: response.message, type: 'success' })
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <span>站点：<b>{{ websiteName }}</b></span>
      </el-col>
      <el-col v-if="total === 0 && websiteCode !== ''" :span="8">
        <el-button v-waves type="primary" icon="el-icon-search" @click="handleCreate">创建</el-button>
      </el-col>
      <el-col v-if="total > 0 && websiteCode !== ''" :span="8">
        <el-button v-waves type="primary" icon="el-icon-search" @click="handleUpdate">编辑</el-button>
      </el-col>
    </el-row>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="类型" align="center">
        <template slot-scope="{row}">
          <div><b>{{ row.type }}</b></div>
        </template>
      </el-table-column>
      <el-table-column label="文章" min-width="168px" align="left">
        <template slot-scope="{row}">
          <div>ID：{{ row.id }}</div>
          <div><el-link :href="websiteUrl + `/blog/` + row.title_url" target="_blank">{{ row.title }}</el-link></div>
        </template>
      </el-table-column>
      <el-table-column label="时间" width="168px" align="center">
        <template slot-scope="{row}">
          <div>{{ row.updated_date }}</div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="configData" label-position="right" label-width="100px">
        <el-form-item label="顶部文章ID" prop="top_article_id">
          <el-input v-model="configData.top_article_id" maxlength="10" show-word-limit />
        </el-form-item>
        <el-form-item label="滚动文章ID" prop="item_id_array">
          <el-input v-model="configData.item_id_array" maxlength="200" show-word-limit placeholder="使用逗号隔开" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { homeConfigApi, homeConfigAddApi, homeConfigUpdateApi } from '@/api/article'
import waves from '@/directive/waves' // waves directive
import { mapGetters } from 'vuex'
const blogUrl = process.env.VUE_APP_BLOG_URL

export default {
  name: 'ArticleHomeConfig',
  directives: { waves },
  data() {
    return {
      blogUrl,
      configData: { top_article_id: '', item_id_array: '' },
      list: null,
      total: 0,
      listLoading: true,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        top_article_id: [{ required: true, message: '顶部文章ID必须填写', trigger: 'change' }],
        item_id_array: [{ type: 'string', required: true, message: '滚动文章ID必须填写', trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapGetters([
      'websiteName',
      'websiteCode',
      'websiteUrl'
    ])
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      homeConfigApi({ website_code: this.websiteCode }).then(response => {
        this.list = response.data.list
        this.configData = response.data.config
        this.total = response.data.list.length

        // Just to simulate the time of the request
        setTimeout(() => { this.listLoading = false }, 0.5 * 1000)
      })
    },
    handleCreate() {
      this.configData = { top_article_id: '', item_id_array: '' }
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.configData.website_code = this.websiteCode
          homeConfigAddApi(this.configData).then((response) => {
            this.getList()
            this.dialogFormVisible = false
            this.$message({ message: response.message, type: 'success', duration: 1500 })
          })
        }
      })
    },
    handleUpdate() {
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.configData.website_code = this.websiteCode
          homeConfigUpdateApi(this.configData).then((response) => {
            this.getList()
            this.dialogFormVisible = false
            this.$message({ message: response.message, type: 'success', duration: 1500 })
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

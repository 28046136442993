import request from '@/utils/request'

export function typeTreeListApi(params) {
  return request({
    url: '/nicheArticle/articleAdmin/typeList',
    method: 'get',
    params
  })
}

export function typeAddApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/addType',
    method: 'post',
    data
  })
}

export function typeUpdateApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/updateType',
    method: 'post',
    data
  })
}

export function typeDeleteApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/deleteType',
    method: 'post',
    data
  })
}

export function listArticleApi(params) {
  return request({
    url: '/nicheArticle/articleAdmin/listArticle',
    method: 'get',
    params
  })
}

export function addArticleApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/addArticle',
    method: 'post',
    data
  })
}

export function statusArticleApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/statusArticle',
    method: 'post',
    data
  })
}

export function detailArticleApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/detailArticle',
    method: 'post',
    data
  })
}

export function updateArticleApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/updateArticle',
    method: 'post',
    data
  })
}

export function deleteArticleApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/deleteArticle',
    method: 'post',
    data
  })
}

export function restoreArticleApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/restoreArticle',
    method: 'post',
    data
  })
}

export function thoroughDelArticleApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/thoroughDelArticle',
    method: 'post',
    data
  })
}

export function homeConfigApi(params) {
  return request({
    url: '/nicheArticle/articleAdmin/homeConfig',
    method: 'get',
    params
  })
}

export function homeConfigAddApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/homeConfigAdd',
    method: 'post',
    data
  })
}

export function homeConfigUpdateApi(data) {
  return request({
    url: '/nicheArticle/articleAdmin/homeConfigUpdate',
    method: 'post',
    data
  })
}

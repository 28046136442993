var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-demo",
          attrs: {
            drag: true,
            multiple: false,
            "show-file-list": false,
            accept: "image/*",
            action: "",
            "auto-upload": false,
            "on-change": _vm.onChange,
          },
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("将文件拖到此处，或"),
            _c("em", [_vm._v("点击上传")]),
          ]),
          _vm.percentage > 0 && _vm.percentage < 100
            ? _c("el-progress", {
                staticClass: "el-upload_progress",
                attrs: {
                  "text-inside": true,
                  "stroke-width": 18,
                  percentage: _vm.percentage,
                },
                model: {
                  value: _vm.progressStatus,
                  callback: function ($$v) {
                    _vm.progressStatus = $$v
                  },
                  expression: "progressStatus",
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [
              _vm._v(
                " 只能上传jpg/png等图片文件，且不超过" +
                  _vm._s(_vm.maxSize) +
                  "kb "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.tempShowImg
        ? _c(
            "el-image",
            {
              staticStyle: {
                width: "160px",
                "max-height": "480px",
                margin: "12px",
                "border-radius": "12px",
              },
              attrs: {
                lazy: false,
                src: _vm.imgUrl,
                "preview-src-list": _vm.imgListUrl,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "placeholder" },
                  slot: "placeholder",
                },
                [
                  _vm._v(" 加载中"),
                  _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "error" },
                  slot: "error",
                },
                [_c("i", { staticClass: "el-icon-picture-outline" })]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
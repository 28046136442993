import request from '@/utils/request'

export function nicheArticleUserAdminListApi(params) {
  return request({
    url: '/nicheArticle/userAdmin/list',
    method: 'get',
    params
  })
}

export function nicheArticleUserAdminStatusApi(data) {
  return request({
    url: '/nicheArticle/userAdmin/status',
    method: 'post',
    data
  })
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-develop" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            [
              _c(
                "el-row",
                { staticStyle: { padding: "10px 0", "line-height": "32px" } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("span", [_vm._v("库销存")]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                    _vm._v(" "),
                    _c("span", [_vm._v("测试表单")]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-edit" }),
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-link",
                        {
                          staticClass: "el-icon-document",
                          attrs: { underline: false },
                        },
                        [_vm._v("表单设计")]
                      ),
                      _c(
                        "el-link",
                        {
                          staticClass: "el-icon-setting",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { underline: false },
                        },
                        [_vm._v("页面设置")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { round: "" }, on: { click: _vm.handelView } },
                        [_vm._v("预览")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.handelSave },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
            ],
            1
          ),
          _c(
            "el-container",
            [
              _c(
                "el-aside",
                { attrs: { width: "auto" } },
                [
                  _c(
                    "el-tabs",
                    {
                      attrs: { "tab-position": "left" },
                      model: {
                        value: _vm.menuName,
                        callback: function ($$v) {
                          _vm.menuName = $$v
                        },
                        expression: "menuName",
                      },
                    },
                    [
                      _c("el-tab-pane", { attrs: { name: "menu1" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "left-menu",
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "大纲树",
                                  placement: "right",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-s-unfold" })]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { width: "200px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("h4", [_vm._v("大纲树")]),
                                ]),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    attrs: { span: 12 },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { cursor: "pointer" } },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-close",
                                          on: {
                                            click: function ($event) {
                                              _vm.menuName = null
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" 大纲树 "),
                            _c("h3", [_vm._v(_vm._s(_vm.id))]),
                          ],
                          1
                        ),
                      ]),
                      _c("el-tab-pane", { attrs: { name: "menu2" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "left-menu",
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "组件库",
                                  placement: "right",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-menu" })]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { width: "240px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 12 } }, [
                                  _c("h4", [_vm._v("组件库")]),
                                ]),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    attrs: { span: 12 },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { cursor: "pointer" } },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-close",
                                          on: {
                                            click: function ($event) {
                                              _vm.menuName = null
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "draggable",
                                  {
                                    attrs: {
                                      sort: false,
                                      options: {
                                        group: { name: "form", pull: "clone" },
                                        sort: true,
                                      },
                                    },
                                    on: { end: _vm.handelEnd },
                                    model: {
                                      value: _vm.attributeData,
                                      callback: function ($$v) {
                                        _vm.attributeData = $$v
                                      },
                                      expression: "attributeData",
                                    },
                                  },
                                  _vm._l(
                                    _vm.attributeData,
                                    function (item, index) {
                                      return _c(
                                        "el-button",
                                        {
                                          key: index,
                                          attrs: { icon: item.icon },
                                        },
                                        [_vm._v(_vm._s(item.label))]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-tab-pane", { attrs: { name: "menu3" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "left-menu",
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "数据源",
                                  placement: "right",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-coin" })]
                            ),
                          ],
                          1
                        ),
                        _c("div", [
                          _c(
                            "div",
                            { staticStyle: { width: "auto" } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("h4", [_vm._v("数据源")]),
                                  ]),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 12 },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { cursor: "pointer" } },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                            on: {
                                              click: function ($event) {
                                                _vm.menuName = null
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: { "max-width": "300px" },
                                      attrs: { gutter: 10 },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 10 } },
                                        [
                                          _c("common-select", {
                                            attrs: {
                                              options: _vm.dataSelectOptions,
                                            },
                                            model: {
                                              value: _vm.dataFilter.select,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataFilter,
                                                  "select",
                                                  $$v
                                                )
                                              },
                                              expression: "dataFilter.select",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 14 } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "搜索",
                                              "suffix-icon": "el-icon-search",
                                            },
                                            model: {
                                              value: _vm.dataFilter.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataFilter,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "dataFilter.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { margin: "10px 0" } },
                                    [
                                      _c(
                                        "el-dropdown",
                                        { attrs: { trigger: "click" } },
                                        [
                                          _c(
                                            "el-button",
                                            { attrs: { type: "primary" } },
                                            [_vm._v("添加")]
                                          ),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c("el-dropdown-item", [
                                                _vm._v("变量"),
                                              ]),
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handelAddMainTable(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("主表")]
                                              ),
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handelAddSecondTable(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("从表")]
                                              ),
                                              _c("el-dropdown-item", [
                                                _vm._v("API"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "scroll-bar",
                                      style: {
                                        height: _vm.attributeHeight - 60 + "px",
                                        overflow: "auto",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-collapse",
                                        { attrs: { accordion: "" } },
                                        _vm._l(
                                          _vm.dataSourceData,
                                          function (item, index) {
                                            return _c(
                                              "el-collapse-item",
                                              { key: index },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [
                                                    _c(
                                                      "el-tag",
                                                      {
                                                        staticStyle: {
                                                          "margin-right":
                                                            "12px",
                                                        },
                                                        attrs: {
                                                          type: _vm._f(
                                                            "sourceTypeTagFilter"
                                                          )(item.type),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "sourceTypeFilter"
                                                            )(item.type)
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: item.type === 2,
                                                        expression:
                                                          "item.type===2",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        "描述：" +
                                                          _vm._s(item.describe)
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        "表：" +
                                                          _vm._s(
                                                            item.database_name
                                                          ) +
                                                          "." +
                                                          _vm._s(
                                                            item.table_name
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: item.type === 3,
                                                        expression:
                                                          "item.type===3",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        "描述：" +
                                                          _vm._s(item.describe)
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        "主表：" +
                                                          _vm._s(
                                                            item.main_table
                                                          )
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        "从表：" +
                                                          _vm._s(
                                                            item.database_name
                                                          ) +
                                                          "." +
                                                          _vm._s(
                                                            item.table_name
                                                          )
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        "连表类型：" +
                                                          _vm._s(
                                                            _vm._f(
                                                              "joinTypeDataFilter"
                                                            )(item.join_type)
                                                          )
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        "连表表达式：" +
                                                          _vm._s(
                                                            item.join_expression
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-main", { staticStyle: { "overflow-y": "hidden" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "right",
                      padding: "4px 8px 8px 8px",
                      "box-shadow":
                        "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "drawer-top-tool",
                        staticStyle: { cursor: "pointer", "font-size": "18px" },
                      },
                      [_c("i", { staticClass: "el-icon-refresh-left" })]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "drawer-top-tool",
                        staticStyle: { cursor: "pointer", "font-size": "18px" },
                      },
                      [_c("i", { staticClass: "el-icon-refresh-right" })]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-link",
                      {
                        staticClass: "drawer-top-tool",
                        attrs: { underline: false },
                      },
                      [_vm._v("表单设置")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticClass: "drawer-top-tool",
                        attrs: { underline: false },
                      },
                      [_vm._v("页面属性")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "canvas-content scroll-bar" },
                  [
                    _c(
                      "draggable",
                      {
                        style: {
                          height: 10 + _vm.attributeHeight + "px",
                          paddingBottom: "10px",
                        },
                        attrs: { group: "form", sort: true },
                        on: { end: _vm.handelEndDrawer },
                        model: {
                          value: _vm.attributeDrawer,
                          callback: function ($$v) {
                            _vm.attributeDrawer = $$v
                          },
                          expression: "attributeDrawer",
                        },
                      },
                      _vm._l(_vm.attributeDrawer, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "canvas-content-detail",
                            attrs: { icon: item.icon },
                          },
                          [
                            _c(
                              "el-form",
                              { attrs: { "label-position": "top" } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: item.label } },
                                  [
                                    item.id === 1
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "1" } },
                                              [_vm._v("选项1")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "2" } },
                                              [_vm._v("选项2")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id === 2
                                      ? _c(
                                          "div",
                                          [
                                            _c("el-checkbox", [
                                              _vm._v("选择1"),
                                            ]),
                                            _c("el-checkbox", [
                                              _vm._v("选择2"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id === 3
                                      ? _c("el-input", {
                                          attrs: { placeholder: "请输入内容" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-aside", { attrs: { width: "280px" } }, [
                _c("div", { staticStyle: { "overflow-y": "hidden" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "0 10px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _c("h5", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("属性"),
                      ]),
                      _c(
                        "el-breadcrumb",
                        { attrs: { separator: "/" } },
                        [
                          _c(
                            "el-breadcrumb-item",
                            { attrs: { to: { path: "/" } } },
                            [_vm._v("首页")]
                          ),
                          _c("el-breadcrumb-item", [
                            _c("a", { attrs: { href: "/" } }, [
                              _vm._v("活动管理"),
                            ]),
                          ]),
                          _c("el-breadcrumb-item", [_vm._v("活动列表")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      ref: "attributeList",
                      staticClass: "attribute-list scroll-bar",
                      style: {
                        height: _vm.attributeHeight + "px",
                        overflow: "auto",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 10px" } },
                        [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.viewType === 1 ? "普通表单" : "其他")
                            ),
                          ]),
                          _c(
                            "el-collapse",
                            {
                              model: {
                                value: _vm.activeNames,
                                callback: function ($$v) {
                                  _vm.activeNames = $$v
                                },
                                expression: "activeNames",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                { attrs: { name: "1" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("h4", [_vm._v("PC端设置")]),
                                  ]),
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 8 } },
                                    [
                                      _c("el-col", { attrs: { span: 10 } }, [
                                        _c("span", [_vm._v("列数")]),
                                      ]),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                          attrs: { span: 14 },
                                        },
                                        [
                                          _c("el-button", [_vm._v("1列")]),
                                          _c("el-button", [_vm._v("2列")]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "el-collapse-item",
                                { attrs: { title: "表单校验", name: "2" } },
                                [
                                  _c("div", [_vm._v("公式校验")]),
                                  _c(
                                    "div",
                                    [_c("el-button", [_vm._v("添加公式")])],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "el-collapse-item",
                                { attrs: { title: "表单事件", name: "3" } },
                                [
                                  _c("div", [_vm._v("工时执行")]),
                                  _c("div", [
                                    _vm._v("运行失败时，终止后续规则"),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("el-button", [
                                        _vm._v("添加业务关联规则"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("div", [_vm._v("已配置0条集成&自动化")]),
                                ]
                              ),
                              _c(
                                "el-collapse-item",
                                { attrs: { title: "高级", name: "4" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._v("提交文案 "),
                                      _c("el-input", {
                                        attrs: { value: "提交" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", [_vm._v("表单提交前")]),
                                  _c(
                                    "div",
                                    [_c("el-button", [_vm._v("绑定动作")])],
                                    1
                                  ),
                                  _c("div", [_vm._v("表单提交后")]),
                                  _c(
                                    "div",
                                    [_c("el-button", [_vm._v("绑定动作")])],
                                    1
                                  ),
                                  _c("div", [_vm._v("表单数据源")]),
                                  _c(
                                    "div",
                                    [_c("el-button", [_vm._v("编辑代码")])],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "数据源-主表",
            visible: _vm.mainTableDrawer,
            direction: "ltr",
            size: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.mainTableDrawer = $event
            },
          },
        },
        [
          _c("div", { staticClass: "drawer-content" }, [
            _c(
              "div",
              { staticClass: "drawer-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "mainTableForm",
                    staticStyle: { padding: "0 12px" },
                    attrs: {
                      rules: _vm.mainTableRules,
                      model: _vm.tmpSource,
                      "label-position": "right",
                      "label-width": "80px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "名称", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "20", "show-word-limit": "" },
                          model: {
                            value: _vm.tmpSource.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tmpSource, "name", $$v)
                            },
                            expression: "tmpSource.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "描述", prop: "describe" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "50", "show-word-limit": "" },
                          model: {
                            value: _vm.tmpSource.describe,
                            callback: function ($$v) {
                              _vm.$set(_vm.tmpSource, "describe", $$v)
                            },
                            expression: "tmpSource.describe",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "数据表", prop: "table_name" } },
                      [
                        _c("el-cascader", {
                          attrs: {
                            options: _vm.tableOptions,
                            filterable: "",
                            clearable: "",
                          },
                          on: { change: _vm.handelChangeTable },
                          model: {
                            value: _vm.tmpSource.table_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tmpSource, "table_name", $$v)
                            },
                            expression: "tmpSource.table_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form",
                  {
                    staticClass: "scroll-bar",
                    style: {
                      padding: "0 12px",
                      height: _vm.attributeHeight - 135 + "px",
                      overflow: "auto",
                    },
                    attrs: { "label-position": "top" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTable.table_id > 0,
                            expression: "selectTable.table_id>0",
                          },
                        ],
                        attrs: { label: "主表" },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.selectTable.database) +
                              "." +
                              _vm._s(_vm.selectTable.table_name)
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTable.table_id > 0,
                            expression: "selectTable.table_id>0",
                          },
                        ],
                        attrs: { label: "字段" },
                      },
                      [
                        _c(
                          "el-table",
                          { attrs: { data: _vm.tableFieldIndex.fieldList } },
                          [
                            _c("el-table-column", {
                              attrs: { label: "表名", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [_vm._v(_vm._s(row.name))]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "类型", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("mysqlTypeFilter")(row.type)
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTable.table_id > 0,
                            expression: "selectTable.table_id>0",
                          },
                        ],
                        attrs: { label: "索引" },
                      },
                      [
                        _c(
                          "el-table",
                          { attrs: { data: _vm.tableFieldIndex.indexList } },
                          [
                            _c("el-table-column", {
                              attrs: { label: "索引名", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [_vm._v(_vm._s(row.name))]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "字段", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [_vm._v(_vm._s(row.field))]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "drawer-footer" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.mainTableDrawer = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handelSaveMainTable },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "数据源-从表",
            visible: _vm.secondTableDrawer,
            direction: "ltr",
            size: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.secondTableDrawer = $event
            },
          },
        },
        [
          _c("div", { staticClass: "drawer-content" }, [
            _c(
              "div",
              { staticClass: "drawer-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "secondTableForm",
                    staticStyle: { padding: "0 12px" },
                    attrs: {
                      rules: _vm.secondTableRules,
                      model: _vm.tmpSource,
                      "label-position": "right",
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "名称", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "20", "show-word-limit": "" },
                          model: {
                            value: _vm.tmpSource.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tmpSource, "name", $$v)
                            },
                            expression: "tmpSource.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "描述", prop: "describe" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "50", "show-word-limit": "" },
                          model: {
                            value: _vm.tmpSource.describe,
                            callback: function ($$v) {
                              _vm.$set(_vm.tmpSource, "describe", $$v)
                            },
                            expression: "tmpSource.describe",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "主表", prop: "main_table" } },
                      [
                        _c("common-select", {
                          attrs: { options: _vm.mainTableList },
                          model: {
                            value: _vm.tmpSource.main_table,
                            callback: function ($$v) {
                              _vm.$set(_vm.tmpSource, "main_table", $$v)
                            },
                            expression: "tmpSource.main_table",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "数据表", prop: "table_name" } },
                      [
                        _c("el-cascader", {
                          attrs: {
                            options: _vm.tableOptions,
                            filterable: "",
                            clearable: "",
                          },
                          on: { change: _vm.handelChangeTable },
                          model: {
                            value: _vm.tmpSource.table_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tmpSource, "table_name", $$v)
                            },
                            expression: "tmpSource.table_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "连表类型", prop: "join_type" } },
                      [
                        _c("common-select", {
                          attrs: { options: _vm.joinTypeData },
                          model: {
                            value: _vm.tmpSource.join_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.tmpSource, "join_type", $$v)
                            },
                            expression: "tmpSource.join_type",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "连表表达式", prop: "join_expression" },
                      },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "100", "show-word-limit": "" },
                          model: {
                            value: _vm.tmpSource.join_expression,
                            callback: function ($$v) {
                              _vm.$set(_vm.tmpSource, "join_expression", $$v)
                            },
                            expression: "tmpSource.join_expression",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form",
                  {
                    staticClass: "scroll-bar",
                    style: {
                      padding: "0 12px",
                      height: _vm.attributeHeight - 300 + "px",
                      overflow: "auto",
                    },
                    attrs: { "label-position": "top" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTable.table_id > 0,
                            expression: "selectTable.table_id>0",
                          },
                        ],
                        attrs: { label: "主表" },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.tmpSource.main_table))])]
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTable.table_id > 0,
                            expression: "selectTable.table_id>0",
                          },
                        ],
                        attrs: { label: "从表" },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.selectTable.database) +
                              "." +
                              _vm._s(_vm.selectTable.table_name)
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTable.table_id > 0,
                            expression: "selectTable.table_id>0",
                          },
                        ],
                        attrs: { label: "字段" },
                      },
                      [
                        _c(
                          "el-table",
                          { attrs: { data: _vm.tableFieldIndex.fieldList } },
                          [
                            _c("el-table-column", {
                              attrs: { label: "表名", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [_vm._v(_vm._s(row.name))]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "类型", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("mysqlTypeFilter")(row.type)
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTable.table_id > 0,
                            expression: "selectTable.table_id>0",
                          },
                        ],
                        attrs: { label: "索引" },
                      },
                      [
                        _c(
                          "el-table",
                          { attrs: { data: _vm.tableFieldIndex.indexList } },
                          [
                            _c("el-table-column", {
                              attrs: { label: "索引名", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [_vm._v(_vm._s(row.name))]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "字段", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [_vm._v(_vm._s(row.field))]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "drawer-footer" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.secondTableDrawer = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handelSaveSecondTable },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
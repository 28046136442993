<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <el-input v-model="listQuery.user_id" placeholder="用户ID" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.name" placeholder="Name" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.email" placeholder="Email" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="8">
        <el-button v-waves type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-col>
    </el-row>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" align="center">
        <template slot-scope="{row}">
          <div>ID：<b>{{ row.id }}</b></div>
          <div>站点编码：<b>{{ row.website_code }}</b></div>
        </template>
      </el-table-column>
      <el-table-column label="用户" min-width="168px" align="left">
        <template slot-scope="{row}">
          <div>用户ID：{{ row.user_id }}</div>
          <div>用户名称：{{ row.name }}</div>
          <div>邮箱：{{ row.email }}</div>
        </template>
      </el-table-column>
      <el-table-column label="内容" min-width="168px" align="left">
        <template slot-scope="{row}">
          <div style="white-space: pre-wrap;">{{ row.message }}</div>
        </template>
      </el-table-column>
      <el-table-column label="时间" width="168px" align="center">
        <template slot-scope="{row}">
          <div>{{ row.create_date }}</div>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { nicheArticleArticleCommentAdminWriteUsListApi } from '@/api/article-comment'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

const blogUrl = process.env.VUE_APP_BLOG_URL

export default {
  name: 'ArticleWriteUs',
  components: { Pagination },
  directives: { waves },
  data() {
    return {
      blogUrl,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: { page: 1, limit: 20 }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      nicheArticleArticleCommentAdminWriteUsListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => { this.listLoading = false }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <el-input v-model="listQuery.id" placeholder="ID" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.phone" placeholder="手机号" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-select v-model="listQuery.status" placeholder="状态" clearable>
          <el-option v-for="item in statusOptions" :key="item.key" :label="item.display_name" :value="item.key" />
        </el-select>
      </el-col>

      <el-col :span="8">
        <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        <el-button type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>
      </el-col>
    </el-row>

    <el-table
      ref="tableList"
      v-loading="listLoading"
      :data="list"
      :height="tableHeight"
      fit
      highlight-current-row
      style="width: 100%; overflow: auto !important;"
    >
      <el-table-column label="ID" align="center">
        <template slot-scope="{row}">
          <div>{{ row.id }}</div>
          <div>
            <el-tag :type="row.status === 1 ? 'success' : (row.status === 2 ? 'warning' : 'danger')">{{ row.status | statusFilter }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="账号" align="center">
        <template slot-scope="{row}">
          <div>{{ row.name }} <el-tag v-show="row.is_system===1">系统</el-tag></div>
          <div>{{ row.login_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="联系信息" align="center">
        <template slot-scope="{row}">
          <div>手机：{{ row.area_code }} &nbsp; {{ row.phone }}</div>
          <div>邮箱：{{ row.email }}</div>
        </template>
      </el-table-column>
      <el-table-column label="生日" align="center">
        <template slot-scope="{row}">
          <div>{{ row.birthday }}</div>
          <div>{{ row.birthday | ageByBirthdayFilter }}</div>
        </template>
      </el-table-column>
      <el-table-column label="组织" align="center">
        <template slot-scope="{row}">
          <div>角色：
            <span v-if="row.userRoleData && row.userRoleData.length>0">
              <span v-for="item in row.userRoleData" :key="item.id">{{ item.name }}; </span>
            </span>
            <span v-else>~</span>
          </div>
          <div>部门：
            <span v-if="row.userOrganization && row.userOrganization.length>0">
              <span v-for="item in row.userOrganization" :key="item.id">{{ item.name }}; </span>
            </span>
            <span v-else>~</span>
          </div>
          <div>身份：
            <span v-if="row.userIdentity && row.userIdentity.length>0">
              <span v-for="item in row.userIdentity" :key="item.id">{{ item.name }}; </span>
            </span>
            <span v-else>~</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="时间" align="center">
        <template slot-scope="{row}">
          <div>注册：{{ row.create_date }}</div>
          <div>更新：{{ row.updated_at>0?row.updated_date:`-` }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{row, $index}">
          <el-dropdown :split-button="false">
            <span class="el-dropdown-link">
              操作<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu v-if="row.is_system===1" slot="dropdown">
              <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
            </el-dropdown-menu>
            <el-dropdown-menu v-else slot="dropdown">
              <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
              <el-dropdown-item v-show="row.status===1" @click.native="statusData(row, 2)">冻结</el-dropdown-item>
              <el-dropdown-item v-show="row.status===1" @click.native="statusData(row, 3)">注销</el-dropdown-item>
              <el-dropdown-item v-show="row.status===2" @click.native="statusData(row, 1)">解冻</el-dropdown-item>
              <el-dropdown-item v-show="row.status===3" @click.native="statusData(row, 1)">恢复</el-dropdown-item>
              <el-dropdown-item v-show="row.status!==1">
                <el-popover
                  :ref="`popover` + $index"
                  placement="top"
                  width="240"
                  :tabindex="$index"
                >
                  <p>删除用户 `{{ row.name }}` 吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="handleCancelVisible($index)">取消</el-button>
                    <el-button size="mini" type="danger" @click="handleDel(row.id, row.name, $index)">确定</el-button>
                  </div>
                  <span slot="reference">删除</span>
                </el-popover>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListData" />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="500px" class="user-info-dialog">
      <el-form ref="dataForm" :inline="true" :rules="rules" :model="temp" label-position="top" label-width="100px">
        <el-form-item label="昵称" prop="name" style="width: 100%">
          <el-input v-model="temp.name" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="账号" prop="login_name" style="width: 100%">
          <el-input v-model="temp.login_name" :disabled="temp.is_system ? true : false" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="手机号" prop="phone" style="width: 100%;">
          <international-telephone-code-select v-model="temp.area_code" style="width: 30%;display: inline-block" />
          <el-input v-model.trim="temp.phone" minlength="11" maxlength="11" show-word-limit style="width: 70%;display: inline-block" @input="temp.phone=temp.phone.replace(/[^\d]/g, '')" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email" style="width: 50%">
          <el-input v-model.trim="temp.email" maxlength="50" show-word-limit @input="temp.email=temp.email.replace(/[\u4E00-\u9FA5]/g, '')" />
        </el-form-item>
        <el-form-item label="生日" prop="birthday" style="width: 45%">
          <el-date-picker
            v-model="temp.birthday"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-divider content-position="left"><i class="el-icon-suitcase" /> 组织信息 </el-divider>
        <el-form-item label="用户角色" prop="role" style="width: 100%;">
          <role-select v-model="temp.userRoleData" :disabled="temp.is_system ? true : false" />
        </el-form-item>
        <el-form-item label="组织部门" prop="organization" style="width: 100%;">
          <organization-tree-select v-model="temp.userOrganization" />
        </el-form-item>
        <el-form-item label="组织身份" prop="organization" style="width: 100%;">
          <identity-group-select v-model="temp.userIdentity" :user-organization="temp.userOrganization" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { userListApi, userAddApi, userUpdateApi, userDeleteApi, userStatusApi } from '@/api/user-center'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import InternationalTelephoneCodeSelect from '@/components/ConfigCenter/InternationalTelephoneCode/Select'
import OrganizationTreeSelect from '@/components/UserCenter/Organization/TreeSelect'
import IdentityGroupSelect from '@/components/UserCenter/Identity/GroupSelect'
import RoleSelect from '@/components/UserCenter/Role/Select'
import { ageByBirthday } from '@/utils/validate'

const statusOptions = [
  { key: '', display_name: '全部' },
  { key: 1, display_name: '正常' },
  { key: 2, display_name: '冻结' },
  { key: 3, display_name: '注销' }
]
// arr to obj, such as { CN : "China", US : "USA" }
const statusTypeKeyValue = statusOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name
  return acc
}, {})

export default {
  components: { Pagination, InternationalTelephoneCodeSelect, OrganizationTreeSelect, IdentityGroupSelect, RoleSelect },
  filters: {
    ageByBirthdayFilter(birthday) {
      return ageByBirthday(birthday)
    },
    statusFilter(status) {
      if (statusTypeKeyValue[status]) {
        return statusTypeKeyValue[status]
      }
      return '未知'
    }
  },
  props: {
    delHeight: {
      type: Number,
      default: 0
    },
    refreshUserList: {
      type: Boolean,
      default: false
    },
    nowOrganization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tableHeight: 300,
      statusOptions,
      listLoading: false,
      list: null,
      listQuery: {
        page: 1,
        limit: 20,
        name: '',
        status: 1
      },
      total: 0,
      dialogStatus: '',
      dialogFormVisible: false,
      textMap: {
        update: '编辑',
        create: '创建'
      },
      temp: {
        id: undefined,
        name: '',
        login_name: '',
        phone: '',
        area_code: '+86'
      },
      rules: {
        name: [
          { required: true, message: '昵称必须填写', trigger: 'change' },
          { min: 2, max: 32, message: '长度在 2 到 32 个字符', trigger: 'blur' }
        ],
        login_name: [
          { required: true, message: '账号必须填写', trigger: 'change' },
          { required: true, min: 5, max: 32, message: '长度在 5 到 32 个字符', trigger: 'blur' }
        ],
        phone: [{
          pattern: /^1[3-9]\d{9}$/,
          message: '手机号格式错误',
          trigger: 'blur'
        }],
        email: [{
          pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,8})$/,
          message: '邮箱格式错误',
          trigger: 'blur'
        }]
      },
      pickerOptions: {
        // disabled为函数，返回值为布尔值，禁选日期
        disabledDate: (time) => {
          const now_at = Date.now()
          const minTime = now_at - 300 * 365 * 86400 * 1000 // 300年前的不可选
          return time.getTime() < minTime || time.getTime() > now_at // 大于当前不可选
        }
      }
    }
  },
  watch: {
    delHeight: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {}
    },
    refreshUserList: {
      immediate: false,
      handler(val) {
        if (val) {
          this.getListData()
        }
      }
    },
    nowOrganization: {
      immediate: false,
      handler(val) {}
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - self.delHeight
      self.$emit('tableHeightData', self.tableHeight)
      // 监听窗口大小变化
      window.onresize = () => {
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - self.delHeight
        self.$emit('tableHeightData', self.tableHeight)
      }
    })
  },
  created() {
    if (!this.refreshUserList) {
      this.getListData()
    }
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getListData() {
      this.listLoading = true
      if (this.nowOrganization) {
        this.listQuery.organization_id = this.nowOrganization.id
      }
      userListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        if (this.nowOrganization) {
          this.$emit('tableListData', this.total)
        }
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      if (this.listQuery.id > 0) {
        const id = this.listQuery.id
        this.listQuery = { page: 1, limit: 20, name: '', phone: '', id: id }
      }
      this.getListData()
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        name: '',
        login_name: '',
        code: '',
        area_code: '+86',
        userOrganization: this.nowOrganization ? [this.nowOrganization] : []
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const userRoleData = JSON.stringify(this.temp.userRoleData)
          const userOrganization = JSON.stringify(this.temp.userOrganization)
          const userIdentity = JSON.stringify(this.temp.userIdentity)
          const tempData = Object.assign({}, this.temp)
          tempData.userRoleData = userRoleData
          tempData.userOrganization = userOrganization
          tempData.userIdentity = userIdentity
          userAddApi(tempData).then((response) => {
            this.temp = response.data
            this.getListData()
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleUpdate(row) {
      if (!row.area_code) {
        row.area_code = '+86'
      }
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const userRoleData = JSON.stringify(this.temp.userRoleData)
          const userOrganization = JSON.stringify(this.temp.userOrganization)
          const userIdentity = JSON.stringify(this.temp.userIdentity)
          const tempData = Object.assign({}, this.temp)
          tempData.userRoleData = userRoleData
          tempData.userOrganization = userOrganization
          tempData.userIdentity = userIdentity
          userUpdateApi(tempData).then((response) => {
            this.dialogFormVisible = false
            this.getListData()
            this.$message({
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    statusData(row, status) {
      userStatusApi({ id: row.id, status: status }).then(response => {
        this.getListData()
        this.$message({
          type: 'success',
          message: response.message
        })
      })
    },
    handleCancelVisible(index) {
      this.$refs[`popover` + index].doClose()
    },
    handleDel(id, name, index) {
      this.$refs[`popover` + index].doClose()
      userDeleteApi({ id: id }).then(response => {
        this.getListData()
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    }
  }
}
</script>

<style lang="scss">
  .user-info-dialog .el-dialog .el-dialog__body {
    padding: 6px 20px !important;
  }
</style>

import { render, staticRenderFns } from "./IndexTypeSelect.vue?vue&type=template&id=5f8cdd92&scoped=true&"
import script from "./IndexTypeSelect.vue?vue&type=script&lang=js&"
export * from "./IndexTypeSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f8cdd92",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/nicheWeb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f8cdd92')) {
      api.createRecord('5f8cdd92', component.options)
    } else {
      api.reload('5f8cdd92', component.options)
    }
    module.hot.accept("./IndexTypeSelect.vue?vue&type=template&id=5f8cdd92&scoped=true&", function () {
      api.rerender('5f8cdd92', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BaseData/MySQL/IndexTypeSelect.vue"
export default component.exports
import { constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, { roles, rolesData }) {
    return new Promise(resolve => {
      let accessedRoutes = []

      // 完全后端控制菜单路由
      accessedRoutes = powerMenu(rolesData, accessedRoutes)

      if (roles.includes('admin')) {
        // 超管
      }

      // 加404路由， 404 page must be placed at the end !!!
      // accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
      accessedRoutes.push({ path: '*', redirect: '/admin', hidden: true })
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

// 递归 后端数据计算出前端路由
function powerMenu(rolesData, accessedRoutes) {
  let view = ''
  let layout = ''
  let hidden = false
  let menu = {}
  for (const i in rolesData) {
    view = rolesData[i].view
    if (rolesData[i].is_release_view === 0 && rolesData[i].is_system === 0) {
      view = 'config-center/no_release'
    }
    layout = rolesData[i].layout === '' ? 'blank' : rolesData[i].layout
    hidden = rolesData[i].is_hidden === 1
    // 链接 - 空白布局
    menu = {
      path: rolesData[i].path,
      name: rolesData[i].en_name,
      hidden: hidden,
      meta: { title: rolesData[i].name, icon: rolesData[i].icon },
      children: []
    }
    if (rolesData[i].type === 1) {
      // 基础布局
      menu = {
        path: rolesData[i].path,
        name: rolesData[i].en_name,
        redirect: rolesData[i].redirect,
        hidden: hidden,
        component: loadLayout(layout),
        meta: { title: rolesData[i].name, icon: rolesData[i].icon },
        children: []
      }
    }
    if (rolesData[i].type === 2) {
      // 视图
      menu = {
        path: rolesData[i].path,
        name: rolesData[i].en_name,
        hidden: hidden,
        component: loadView(view),
        meta: { title: rolesData[i].name, icon: rolesData[i].icon, query: { id: rolesData[i].id, view_type: rolesData[i].view_type }},
        children: []
      }
    }

    // 拥有子级
    if (rolesData[i].children && rolesData[i].children.length > 0) {
      // 多级遍历
      menu.children = powerMenu(rolesData[i].children, menu.children)
    }
    if (rolesData[i].type === 1 || rolesData[i].type === 2 || rolesData[i].type === 3) {
      accessedRoutes.push(menu)
    }
  }

  return accessedRoutes
}

// 路由懒加载
export const loadView = (view) => {
  return () => Promise.resolve(require(`@/views/${view}`).default)
}

export const loadLayout = (layout) => {
  return () => Promise.resolve(require(`@/layout/${layout}`).default)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

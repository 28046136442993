import { render, staticRenderFns } from "./Select.vue?vue&type=template&id=544a0856&scoped=true&"
import script from "./Select.vue?vue&type=script&lang=js&"
export * from "./Select.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544a0856",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/nicheWeb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('544a0856')) {
      api.createRecord('544a0856', component.options)
    } else {
      api.reload('544a0856', component.options)
    }
    module.hot.accept("./Select.vue?vue&type=template&id=544a0856&scoped=true&", function () {
      api.rerender('544a0856', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UserCenter/Role/Select.vue"
export default component.exports
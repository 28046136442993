import request from '@/utils/request'

export function goodsAdminCategoryListApi() {
  return request({
    url: '/nicheGoods/goodsAdmin/categoryList',
    method: 'get'
  })
}

export function goodsAdminAddCategoryApi(data) {
  return request({
    url: '/nicheGoods/goodsAdmin/addCategory',
    method: 'post',
    data
  })
}

export function goodsAdminUpdateCategoryApi(data) {
  return request({
    url: '/nicheGoods/goodsAdmin/updateCategory',
    method: 'post',
    data
  })
}

export function goodsAdminDeleteCategoryApi(data) {
  return request({
    url: '/nicheGoods/goodsAdmin/deleteCategory',
    method: 'post',
    data
  })
}

export function goodsAdminListGoodsApi(params) {
  return request({
    url: '/nicheGoods/goodsAdmin/listGoods',
    method: 'get',
    params
  })
}

export function goodsAdminAddGoodsApi(data) {
  return request({
    url: '/nicheGoods/goodsAdmin/addGoods',
    method: 'post',
    data
  })
}

export function goodsAdminUpdateGoodsApi(data) {
  return request({
    url: '/nicheGoods/goodsAdmin/updateGoods',
    method: 'post',
    data
  })
}

export function goodsAdminDeleteGoodsApi(data) {
  return request({
    url: '/nicheGoods/goodsAdmin/deleteGoods',
    method: 'post',
    data
  })
}

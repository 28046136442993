import request from '@/utils/request'

/* power - 用户权限管理 */
export function menuListApi() {
  return request({
    url: '/userCenter/power/menuList',
    method: 'get'
  })
}

export function powerTreeListApi() {
  return request({
    url: '/userCenter/power/treeList',
    method: 'get'
  })
}

export function powerListApi(params) {
  return request({
    url: '/userCenter/power/list',
    method: 'get',
    params
  })
}

export function powerAddApi(data) {
  return request({
    url: '/userCenter/power/add',
    method: 'post',
    data
  })
}

export function powerUpdateApi(data) {
  return request({
    url: '/userCenter/power/update',
    method: 'post',
    data
  })
}

export function powerDeleteApi(data) {
  return request({
    url: '/userCenter/power/delete',
    method: 'post',
    data
  })
}

export function powerRestoreApi(data) {
  return request({
    url: '/userCenter/power/restore',
    method: 'post',
    data
  })
}

export function powerDestroyApi(data) {
  return request({
    url: '/userCenter/power/destroy',
    method: 'post',
    data
  })
}

export function powerBackAddApi(data) {
  return request({
    url: '/userCenter/power/backAdd',
    method: 'post',
    data
  })
}

export function powerBackUpdateApi(data) {
  return request({
    url: '/userCenter/power/backUpdate',
    method: 'post',
    data
  })
}

export function powerBackDeleteApi(data) {
  return request({
    url: '/userCenter/power/backDelete',
    method: 'post',
    data
  })
}

/* role - 用户角色管理 */
export function roleListApi(params) {
  return request({
    url: '/userCenter/role/list',
    method: 'get',
    params
  })
}

export function roleListAllApi(params) {
  return request({
    url: '/userCenter/role/listAll',
    method: 'get',
    params
  })
}

export function roleAddApi(data) {
  return request({
    url: '/userCenter/role/add',
    method: 'post',
    data
  })
}

export function roleUpdateApi(data) {
  return request({
    url: '/userCenter/role/update',
    method: 'post',
    data
  })
}

export function roleDeleteApi(data) {
  return request({
    url: '/userCenter/role/delete',
    method: 'post',
    data
  })
}

export function roleRestoreApi(data) {
  return request({
    url: '/userCenter/role/restore',
    method: 'post',
    data
  })
}

export function roleDestroyApi(data) {
  return request({
    url: '/userCenter/role/destroy',
    method: 'post',
    data
  })
}

/* organization - 组织架构管理 */
export function organizationTreeMenuApi() {
  return request({
    url: '/userCenter/organization/treeMenu',
    method: 'get'
  })
}

export function organizationAddApi(data) {
  return request({
    url: '/userCenter/organization/add',
    method: 'post',
    data
  })
}

export function organizationUpdateApi(data) {
  return request({
    url: '/userCenter/organization/update',
    method: 'post',
    data
  })
}

export function organizationDeleteApi(data) {
  return request({
    url: '/userCenter/organization/delete',
    method: 'post',
    data
  })
}

export function organizationRestoreApi(data) {
  return request({
    url: '/userCenter/organization/restore',
    method: 'post',
    data
  })
}

export function organizationDestroyApi(data) {
  return request({
    url: '/userCenter/organization/destroy',
    method: 'post',
    data
  })
}

export function organizationListRoleApi(params) {
  return request({
    url: '/userCenter/organization/listRole',
    method: 'get',
    params
  })
}

export function organizationDetailRoleApi(data) {
  return request({
    url: '/userCenter/organization/detailRole',
    method: 'post',
    data
  })
}

export function organizationAddRoleApi(data) {
  return request({
    url: '/userCenter/organization/addRole',
    method: 'post',
    data
  })
}

export function organizationDestroyRoleApi(data) {
  return request({
    url: '/userCenter/organization/destroyRole',
    method: 'post',
    data
  })
}

/* organization - 组织身份管理 */
export function organizationListIdentityApi(params) {
  return request({
    url: '/userCenter/organization/listIdentity',
    method: 'get',
    params
  })
}

export function organizationTreeIdentityApi(data) {
  return request({
    url: '/userCenter/organization/treeIdentity',
    method: 'post',
    data
  })
}

export function organizationAddIdentityApi(data) {
  return request({
    url: '/userCenter/organization/addIdentity',
    method: 'post',
    data
  })
}

export function organizationDetailIdentityApi(data) {
  return request({
    url: '/userCenter/organization/detailIdentity',
    method: 'post',
    data
  })
}

export function organizationUpdateIdentityApi(data) {
  return request({
    url: '/userCenter/organization/updateIdentity',
    method: 'post',
    data
  })
}

export function organizationRemoveIdentityRoleApi(data) {
  return request({
    url: '/userCenter/organization/removeIdentityRole',
    method: 'post',
    data
  })
}

export function organizationDeleteIdentityApi(data) {
  return request({
    url: '/userCenter/organization/deleteIdentity',
    method: 'post',
    data
  })
}

/* userManager - 用户管理 */
export function userListApi(params) {
  return request({
    url: '/userCenter/user/list',
    method: 'get',
    params
  })
}

export function userAddApi(data) {
  return request({
    url: '/userCenter/user/add',
    method: 'post',
    data
  })
}

export function userUpdateApi(data) {
  return request({
    url: '/userCenter/user/update',
    method: 'post',
    data
  })
}

export function userStatusApi(data) {
  return request({
    url: '/userCenter/user/status',
    method: 'post',
    data
  })
}

export function userDeleteApi(data) {
  return request({
    url: '/userCenter/user/delete',
    method: 'post',
    data
  })
}

export function userRestoreApi(data) {
  return request({
    url: '/userCenter/user/restore',
    method: 'post',
    data
  })
}

export function userDestroyApi(data) {
  return request({
    url: '/userCenter/user/destroy',
    method: 'post',
    data
  })
}

export function userInfoApi(params) {
  return request({
    url: '/userCenter/user/info',
    method: 'get',
    params
  })
}

export function userCheckLoginNameApi(data) {
  return request({
    url: '/userCenter/user/checkLoginName',
    method: 'post',
    data
  })
}

export function userCheckPhoneApi(data) {
  return request({
    url: '/userCenter/user/checkPhone',
    method: 'post',
    data
  })
}

export function userCheckEmailApi(data) {
  return request({
    url: '/userCenter/user/checkEmail',
    method: 'post',
    data
  })
}

export function userUpdateAvatarApi(data) {
  return request({
    url: '/userCenter/user/updateAvatar',
    method: 'post',
    data
  })
}

export function userUpdateBaseInfoApi(data) {
  return request({
    url: '/userCenter/user/updateBaseInfo',
    method: 'post',
    data
  })
}

export function userUpdateInfoApi(data) {
  return request({
    url: '/userCenter/user/updateInfo',
    method: 'post',
    data
  })
}

export function userUpdatePasswordApi(data) {
  return request({
    url: '/userCenter/user/updatePassword',
    method: 'post',
    data
  })
}

export function userSendEmailCodeApi(data) {
  return request({
    url: '/userCenter/user/sendEmailCode',
    method: 'post',
    data
  })
}

export function userUpdateEmailApi(data) {
  return request({
    url: '/userCenter/user/updateEmail',
    method: 'post',
    data
  })
}

/* user-group - 用户组管理 */
export function userGroupListApi(params) {
  return request({
    url: '/userCenter/userGroup/list',
    method: 'get',
    params
  })
}

export function userGroupAddApi(data) {
  return request({
    url: '/userCenter/userGroup/add',
    method: 'post',
    data
  })
}

export function userGroupDetailApi(data) {
  return request({
    url: '/userCenter/userGroup/detail',
    method: 'post',
    data
  })
}

export function userGroupUpdateApi(data) {
  return request({
    url: '/userCenter/userGroup/update',
    method: 'post',
    data
  })
}

export function userGroupDeleteApi(data) {
  return request({
    url: '/userCenter/userGroup/delete',
    method: 'post',
    data
  })
}

export function userGroupRestoreApi(data) {
  return request({
    url: '/userCenter/userGroup/restore',
    method: 'post',
    data
  })
}

export function userGroupDestroypi(data) {
  return request({
    url: '/userCenter/userGroup/destroy',
    method: 'post',
    data
  })
}

<template>
  <div>
    <el-select v-model="sValue" filterable placeholder="请选择" @change="changeData">
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.label + item.value"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import { internationalTelephoneCodeListApi } from '@/api/config-center'

export default {
  props: {
    value: {
      type: String || Number,
      default: '+86'
    }
  },
  data() {
    return {
      sValue: '',
      options: []
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.sValue = val
      }
    }
  },
  created() {
    this.getListData()
  },
  methods: {
    getListData() {
      internationalTelephoneCodeListApi().then(response => {
        this.options = response.data
      })
    },
    changeData(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

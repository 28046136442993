var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-container" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            { staticClass: "aside-menu", attrs: { width: "200px" } },
            [
              _c(
                "el-container",
                [
                  _c(
                    "el-header",
                    {
                      staticStyle: { padding: "0" },
                      attrs: { height: "40px" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-bottom": "2px" },
                        attrs: { placeholder: "搜索文件夹" },
                        model: {
                          value: _vm.filterText,
                          callback: function ($$v) {
                            _vm.filterText = $$v
                          },
                          expression: "filterText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    { staticStyle: { padding: "0" } },
                    [
                      _c("el-tree", {
                        ref: "treeDataDir",
                        staticClass: "filter-tree",
                        attrs: {
                          data: _vm.treeDataDirData,
                          props: _vm.defaultProps,
                          "filter-node-method": _vm.filterNode,
                          "highlight-current": true,
                          "expand-on-click-node": false,
                          "check-on-click-node": true,
                          "node-key": "id",
                          "default-expanded-keys": _vm.defaultData,
                        },
                        on: { "node-click": _vm.nodeClick },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var node = ref.node
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: node.label,
                                        disabled: _vm._f("hoverStr")(
                                          node.label,
                                          13
                                        ),
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" " + _vm._s(node.label) + " "),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            },
                          },
                        ]),
                      }),
                      _c(
                        "el-row",
                        { staticClass: "btn-row" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.is_recycle,
                                    icon: "el-icon-plus",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      ;(_vm.dialogAddDir = true),
                                        (_vm.dialogStatus = "create")
                                    },
                                  },
                                },
                                [_vm._v("创建文件夹")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-footer",
                    {
                      staticStyle: { padding: "0" },
                      attrs: { height: "40px" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "btn-row" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "info",
                                    icon: "el-icon-delete",
                                    plain: "",
                                  },
                                  on: { click: _vm.recycleData },
                                },
                                [_vm._v("图片回收站")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogTitle[_vm.dialogStatus],
                visible: _vm.dialogAddDir,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogAddDir = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dirDataForm",
                  attrs: {
                    model: _vm.dirForm,
                    rules: _vm.dir_rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建在" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "selectUpResId",
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择",
                            prop: "parent_id",
                          },
                          on: { clear: _vm.handleClear },
                          model: {
                            value: _vm.dirForm.parent_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.dirForm, "parent_id", $$v)
                            },
                            expression: "dirForm.parent_id",
                          },
                        },
                        [
                          _c("el-option", {
                            key: "parentId",
                            attrs: {
                              hidden: "",
                              value: _vm.dirForm.parent_id,
                              label: _vm.dirForm.parent_name,
                            },
                          }),
                          _c("el-tree", {
                            ref: "treeDataDirCheck",
                            attrs: {
                              data: _vm.treeDataDirData,
                              props: _vm.defaultProps,
                              "highlight-current": true,
                              "expand-on-click-node": false,
                              "check-on-click-node": true,
                              "node-key": "id",
                              "default-expanded-keys": _vm.defaultData,
                            },
                            on: { "node-click": _vm.nodeClickCheck },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20", "show-word-limit": "" },
                        model: {
                          value: _vm.dirForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.dirForm, "name", $$v)
                          },
                          expression: "dirForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogAddDir = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createDir },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticStyle: { "text-align": "center" },
              attrs: { visible: _vm.dialogUpload },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogUpload = $event
                },
              },
            },
            [
              _c("upload-qiniu", {
                attrs: {
                  "show-img": _vm.showImg,
                  "confirm-upload": _vm.confirmUpload,
                  "dir-name": _vm.dirName,
                },
                on: {
                  uploadComplete: _vm.uploadComplete,
                  onChange: _vm.onChange,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          ;(_vm.dialogUpload = false), (_vm.showImg = false)
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmUploadFun },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-container",
            [
              _c(
                "el-header",
                { attrs: { height: "80px" } },
                [
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: {
                          background: "#f6f6f6",
                          padding: "8px 0 8px 6px",
                        },
                      },
                      [
                        _c("el-button", {
                          staticStyle: { padding: "4px" },
                          attrs: {
                            type: _vm.listType === 1 ? "primary" : "default",
                            disabled: _vm.is_recycle,
                            icon: "el-icon-s-grid",
                            size: "medium",
                          },
                          on: {
                            click: function ($event) {
                              _vm.listType = 1
                            },
                          },
                        }),
                        _c("el-button", {
                          staticStyle: { padding: "4px" },
                          attrs: {
                            type: _vm.listType === 2 ? "primary" : "default",
                            icon: "el-icon-s-operation",
                            size: "medium",
                          },
                          on: { click: _vm.tableHeightFun },
                        }),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _c(
                          "el-dropdown",
                          { on: { command: _vm.handleSort } },
                          [
                            _c("el-button", {
                              staticStyle: { padding: "4px" },
                              attrs: {
                                icon: "el-icon-s-unfold",
                                size: "medium",
                              },
                            }),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      icon:
                                        _vm.listQuery.sort === "name+" ||
                                        !_vm.listQuery.sort
                                          ? "el-icon-check"
                                          : "",
                                      command: "name+",
                                    },
                                  },
                                  [_vm._v("文件名升序")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      icon:
                                        _vm.listQuery.sort === "name-"
                                          ? "el-icon-check"
                                          : "",
                                      command: "name-",
                                    },
                                  },
                                  [_vm._v("文件名降序")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      icon:
                                        _vm.listQuery.sort === "create_at+"
                                          ? "el-icon-check"
                                          : "",
                                      command: "create_at+",
                                    },
                                  },
                                  [_vm._v("上传时间升序")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      icon:
                                        _vm.listQuery.sort === "create_at-"
                                          ? "el-icon-check"
                                          : "",
                                      command: "create_at-",
                                    },
                                  },
                                  [_vm._v("上传时间降序")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _c("el-input", {
                          staticStyle: {
                            width: "180px",
                            "margin-right": "20px",
                          },
                          attrs: {
                            "prefix-icon": "el-icon-search",
                            placeholder: "搜索图片或文件夹",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchName($event)
                            },
                          },
                          model: {
                            value: _vm.listQuery.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "keyword", $$v)
                            },
                            expression: "listQuery.keyword",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.is_recycle,
                              icon: "el-icon-plus",
                            },
                            on: {
                              click: function ($event) {
                                ;(_vm.dialogUpload = true),
                                  (_vm.showImg = false),
                                  (_vm.confirmUpload = false)
                              },
                            },
                          },
                          [_vm._v("上传文件")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.is_recycle,
                              icon: "el-icon-folder-add",
                            },
                            on: {
                              click: function ($event) {
                                ;(_vm.dialogAddDir = true),
                                  (_vm.dialogStatus = "create")
                              },
                            },
                          },
                          [_vm._v("新建文件夹")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticStyle: { "margin-top": "12px" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _vm.is_recycle
                              ? _c(
                                  "el-breadcrumb",
                                  { attrs: { separator: "/" } },
                                  [
                                    _c("el-breadcrumb-item", [
                                      _c("span", [
                                        _vm._v(
                                          "图片回收站（图片删除后，将保留7天，超时系统将自动清除。）"
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm.keywordSearch
                              ? _c(
                                  "el-breadcrumb",
                                  { attrs: { separator: "|" } },
                                  [
                                    _c(
                                      "el-breadcrumb-item",
                                      [
                                        _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "primary",
                                            },
                                            on: { click: _vm.searchDown },
                                          },
                                          [_vm._v("返回上一级")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-breadcrumb-item", [
                                      _c("span", [
                                        _vm._v(
                                          "搜索结果：“" +
                                            _vm._s(_vm.listQuery.keyword) +
                                            "”相关结果"
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-breadcrumb",
                                  { attrs: { separator: "/" } },
                                  _vm._l(
                                    _vm.clickParentData,
                                    function (item, i) {
                                      return _c(
                                        "el-breadcrumb-item",
                                        { key: item.id },
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    i !==
                                                    _vm.clickParentData.length -
                                                      1,
                                                  expression:
                                                    "i!==clickParentData.length-1",
                                                },
                                              ],
                                              attrs: {
                                                underline: false,
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.breadcrumbClick(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    i ===
                                                    _vm.clickParentData.length -
                                                      1,
                                                  expression:
                                                    "i===clickParentData.length-1",
                                                },
                                              ],
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-breadcrumb",
                                  { attrs: { separator: "/" } },
                                  [
                                    _c(
                                      "el-breadcrumb-item",
                                      [
                                        _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.percentageData.size)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-breadcrumb-item", [
                                      _c("span", [_vm._v("10Gb")]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              { staticStyle: { "margin-top": "2px" } },
                              [
                                _c("el-progress", {
                                  attrs: {
                                    "text-inside": true,
                                    "stroke-width": 18,
                                    percentage: _vm.percentageData.percentage,
                                  },
                                  model: {
                                    value: _vm.percentageData.percentage,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.percentageData,
                                        "percentage",
                                        $$v
                                      )
                                    },
                                    expression: "percentageData.percentage",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _c(
                "el-main",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  style: _vm.listType === 2 ? "overflow-y: hidden;" : "",
                },
                [
                  _vm.listType === 1
                    ? [
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.total > 0,
                                expression: "total>0",
                              },
                            ],
                            ref: "listList",
                            style: {
                              minWidth: "700px",
                              height: _vm.tableHeight,
                            },
                            attrs: { gutter: 10 },
                          },
                          _vm._l(_vm.list, function (item) {
                            return _c(
                              "el-col",
                              {
                                key: item.id,
                                staticStyle: { "text-align": "center" },
                                attrs: { xs: 6, sm: 5, md: 4, lg: 3 },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "130px",
                                      background: "#f9f9f9",
                                      "line-height": "130px",
                                    },
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.openFile(
                                          item.id,
                                          item.name,
                                          item.type
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          "vertical-align": "middle",
                                          "line-height": "0",
                                        },
                                        attrs: {
                                          src: _vm._f("imageFilter")(
                                            item.url,
                                            130
                                          ),
                                          "preview-src-list": _vm._f(
                                            "imagePreviewFilter"
                                          )(item.url),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "error" },
                                            slot: "error",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-picture-outline",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "font-family": "Hiragino Sans GB",
                                      "font-size": "Small",
                                      "line-height": "1.7",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.editNameShowId !== item.id,
                                            expression:
                                              "editNameShowId!==item.id",
                                          },
                                        ],
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: item.name,
                                          disabled: _vm._f("hoverStr")(
                                            item.name,
                                            6
                                          ),
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.editName(
                                                  item.id,
                                                  _vm.listType
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("hoverStrOmit")(
                                                  item.name,
                                                  6
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("el-input", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.editNameShowId === item.id,
                                          expression:
                                            "editNameShowId===item.id",
                                        },
                                      ],
                                      ref: "inputEditNameCard" + item.id,
                                      refInFor: true,
                                      attrs: {
                                        maxlength: "20",
                                        minlength: "1",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          _vm.editNameShowId = 0
                                        },
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.reName(
                                            item.id,
                                            item.parent_id,
                                            item.name,
                                            item.type
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.total < 1,
                                expression: "total<1",
                              },
                            ],
                            staticStyle: { "text-align": "center" },
                          },
                          [_vm._v(" 这里空空的，没有找到图片 ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.listType === 2
                    ? [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            ref: "tableList",
                            staticStyle: {
                              width: "100%",
                              overflow: "auto !important",
                            },
                            attrs: {
                              "element-loading-text": _vm.listLoadingMessage,
                              data: _vm.list,
                              height: _vm.tableHeight,
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "图片",
                                prop: "url",
                                align: "center",
                                width: "78px",
                                "class-name": "v-align-t",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              dblclick: function ($event) {
                                                return _vm.openFile(
                                                  row.id,
                                                  row.name,
                                                  row.type
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                attrs: {
                                                  src: _vm._f("imageFilter")(
                                                    row.url
                                                  ),
                                                  "preview-src-list": _vm._f(
                                                    "imagePreviewFilter"
                                                  )(row.url),
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "image-slot",
                                                    attrs: { slot: "error" },
                                                    slot: "error",
                                                  },
                                                  [_vm._v(" 图片加载失败 ")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2062114943
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "名称",
                                prop: "name",
                                align: "center",
                                "min-width": "120px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.editNameShowId !== row.id,
                                                expression:
                                                  "editNameShowId!==row.id",
                                              },
                                            ],
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: row.name,
                                              disabled: _vm._f("hoverStr")(
                                                row.name,
                                                12
                                              ),
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _vm.is_recycle
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("hoverStrOmit")(
                                                        row.name,
                                                        12
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editName(
                                                          row.id,
                                                          _vm.listType
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("hoverStrOmit")(
                                                          row.name,
                                                          12
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        ),
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.editNameShowId === row.id,
                                              expression:
                                                "editNameShowId===row.id",
                                            },
                                          ],
                                          ref: "inputEditName" + row.id,
                                          attrs: {
                                            maxlength: "20",
                                            minlength: "1",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              _vm.editNameShowId = 0
                                            },
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.reName(
                                                row.id,
                                                row.parent_id,
                                                row.name,
                                                row.type
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.name,
                                            callback: function ($$v) {
                                              _vm.$set(row, "name", $$v)
                                            },
                                            expression: "row.name",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3549720933
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "大小",
                                prop: "size",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.type === 1,
                                                expression: "row.type===1",
                                              },
                                            ],
                                          },
                                          [_vm._v("--")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.type === 2,
                                                expression: "row.type===2",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("imageSize")(row.size)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2304326949
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "图片尺寸",
                                prop: "widthHeight",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.type === 1,
                                                expression: "row.type===1",
                                              },
                                            ],
                                          },
                                          [_vm._v("--")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.type === 2,
                                                expression: "row.type===2",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(row.width) +
                                                " X " +
                                                _vm._s(row.height)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3306561651
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.is_recycle ? "删除时间" : "上传时间",
                                prop: "updateDate",
                                "min-width": "130px",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.is_recycle
                                                ? row.deleted_date
                                                : row.updated_date
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4236124079
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "状态",
                                prop: "status",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-tag",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.status === 1,
                                                expression: "row.status===1",
                                              },
                                            ],
                                          },
                                          [_vm._v("正常")]
                                        ),
                                        _c(
                                          "el-tag",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.status === 2,
                                                expression: "row.status===2",
                                              },
                                            ],
                                            attrs: { type: "info" },
                                          },
                                          [_vm._v("失效")]
                                        ),
                                        _c(
                                          "el-tag",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.status === 3,
                                                expression: "row.status===3",
                                              },
                                            ],
                                            attrs: { type: "warning" },
                                          },
                                          [_vm._v("回收")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                832196518
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "是否引用",
                                prop: "quoteStatus",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.type === 1,
                                                expression: "row.type===1",
                                              },
                                            ],
                                          },
                                          [_vm._v("--")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  row.type === 2 &&
                                                  row.quote_times === 0,
                                                expression:
                                                  "row.type===2 && row.quote_times===0",
                                              },
                                            ],
                                          },
                                          [_vm._v("未引用")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  row.type === 2 &&
                                                  row.quote_times > 0,
                                                expression:
                                                  "row.type===2 && row.quote_times>0",
                                              },
                                            ],
                                          },
                                          [_c("a", [_vm._v("已引用")])]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2820871996
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                prop: "id",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm.is_recycle
                                          ? [
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    underline: false,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.restore(
                                                        row.id,
                                                        row.type
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("还原")]
                                              ),
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    underline: false,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.thoroughDel(
                                                        row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("彻底删除")]
                                              ),
                                            ]
                                          : _c(
                                              "el-dropdown",
                                              {
                                                attrs: {
                                                  "split-button": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-dropdown-link",
                                                  },
                                                  [
                                                    _vm._v(" 操作"),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-arrow-down el-icon--right",
                                                    }),
                                                  ]
                                                ),
                                                row.type === 1
                                                  ? _c(
                                                      "el-dropdown-menu",
                                                      {
                                                        attrs: {
                                                          slot: "dropdown",
                                                        },
                                                        slot: "dropdown",
                                                      },
                                                      [
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.noDevelop(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("重命名")]
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.noDevelop(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("移动到")]
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteData(
                                                                  row.id,
                                                                  row.parent_id,
                                                                  row.type
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                row.type === 2
                                                  ? _c(
                                                      "el-dropdown-menu",
                                                      {
                                                        attrs: {
                                                          slot: "dropdown",
                                                        },
                                                        slot: "dropdown",
                                                      },
                                                      [
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.noDevelop(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("编辑")]
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.copyUrl(
                                                                  row.url
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("复制链接")]
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.noDevelop(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("替换")]
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.noDevelop(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("查看引用")]
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.noDevelop(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("移动到")]
                                                        ),
                                                        _c(
                                                          "el-dropdown-item",
                                                          {
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteData(
                                                                  row.id,
                                                                  row.parent_id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3663508934
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-footer",
                { staticClass: "flex-footer", attrs: { height: "64px" } },
                [
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total>0",
                      },
                    ],
                    attrs: {
                      total: _vm.total,
                      page: _vm.listQuery.page,
                      limit: _vm.listQuery.limit,
                      "page-sizes": _vm.pageSizes,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "page", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "limit", $event)
                      },
                      "update:pageSizes": function ($event) {
                        _vm.pageSizes = $event
                      },
                      "update:page-sizes": function ($event) {
                        _vm.pageSizes = $event
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
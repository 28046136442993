<template>
  <div>
    <div>
      <el-input v-model="filterText" placeholder="搜索数据库" style="margin-bottom:2px;" />
    </div>
    <div>
      <el-tree
        ref="treeDataRef"
        :data="treeData"
        :props="defaultProps"
        :filter-node-method="filterNode"
        class="filter-tree"
        :highlight-current="true"
        :expand-on-click-node="false"
        :check-on-click-node="true"
        node-key="id"
        :default-expanded-keys="defaultExpandedData"
        :style="{ height: treeHeight, overflowY: 'auto' }"
        @node-click="nodeClick"
      >
        <span slot-scope="{ node }" class="custom-tree-node">
          <el-tooltip class="item" effect="dark" :content="node.label" :disabled="node.label | hoverStr(20)" placement="top-start">
            <span> {{ node.label }} </span>
          </el-tooltip>
        </span>
      </el-tree>
    </div>
  </div>
</template>

<script>
import { databaseMenuApi } from '@/api/config-center'
import { ellipsisBool } from '@/utils/validate'

export default {
  filters: {
    hoverStr(str, len) {
      return !ellipsisBool(str, len)
    }
  },
  props: {
    height: {
      type: String,
      default: '400px'
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeHeight: '400px',
      filterText: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'database_name'
      },
      defaultExpandedData: [1]
    }
  },
  watch: {
    height: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.treeHeight = val
      }
    },
    filterText(val) {
      this.$refs.treeDataRef.filter(val)
    },
    refresh: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getTree()
        }
      }
    }
  },
  created() {
    this.getTree()
  },
  methods: {
    getTree() {
      databaseMenuApi().then(response => {
        this.treeData = response.data
        this.defaultExpandedData.push(response.data[0].id)
        this.$emit('treeData', response.data)
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.database_name.indexOf(value) !== -1
    },
    nodeClick(obj, node, selfObj) {
      this.defaultExpandedData[1] = obj.id
      this.$emit('nodeClick', obj, node, selfObj)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.key" placeholder="key" clearable @keyup.enter.native="handleFilter" />
      </el-col>

      <el-col :span="8">
        <el-button v-waves type="primary" icon="el-icon-search" @click="handleFilter">
          搜索
        </el-button>
        <el-button style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">
          添加
        </el-button>
      </el-col>
    </el-row>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="名称" align="center">
        <template slot-scope="{row}">
          <div>名称：<b>{{ row.name }}</b></div>
          <div>key：<el-link :underline="false" type="primary">{{ row.key }}</el-link></div>
        </template>
      </el-table-column>
      <el-table-column label="value" min-width="168px" align="left">
        <template slot-scope="{row}">
          <div>{{ row.value }}</div>
        </template>
      </el-table-column>
      <el-table-column label="last_value" min-width="168px" align="left">
        <template slot-scope="{row}">
          <div>{{ row.last_value }}</div>
        </template>
      </el-table-column>
      <el-table-column label="时间" width="168px" align="center">
        <template slot-scope="{row}">
          <div>创建于：{{ row.create_date }}</div>
          <div>更新于：{{ row.updated_at > 0 ? row.updated_date : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="{row}">
          <el-button @click="handleUpdate(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="16" show-word-limit />
        </el-form-item>
        <el-form-item label="key" prop="key">
          <el-input v-model="temp.key" :disabled="dialogStatus==='update'" maxlength="16" show-word-limit />
        </el-form-item>
        <el-form-item label="value" prop="value">
          <el-input v-model="temp.value" type="textarea" autosize maxlength="2000" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          确认
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { variableListApi, variableAddApi, variableUpdateApi } from '@/api/config-center'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

export default {
  name: 'Variable',
  components: { Pagination },
  directives: { waves },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        status: 1
      },
      temp: {
        id: undefined,
        name: '',
        key: '',
        value: '',
        last_value: '',
        create_at: 0,
        updated_at: 0
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{ required: true, message: '名称必须填写', trigger: 'change' }],
        key: [{ type: 'string', required: true, message: 'key必须填写', trigger: 'change' }]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      variableListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        name: '',
        key: '',
        value: '',
        last_value: '',
        create_at: 0,
        updated_at: 0
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          variableAddApi(this.temp).then((response) => {
            this.temp = response.data
            this.list.unshift(this.temp)
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          variableUpdateApi(tempData).then((response) => {
            const index = this.list.findIndex(v => v.id === this.temp.id)
            this.temp = response.data
            this.list.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>


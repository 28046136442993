var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          ref: "selectRef",
          staticStyle: { width: "100%" },
          attrs: {
            multiple: "",
            clearable: "",
            placeholder: "请选择",
            "value-key": "id",
          },
          on: { "remove-tag": _vm.removeTagData, clear: _vm.handleClear },
          model: {
            value: _vm.sValue,
            callback: function ($$v) {
              _vm.sValue = $$v
            },
            expression: "sValue",
          },
        },
        [
          _vm._l(_vm.sValue, function (item) {
            return _c("el-option", {
              key: item.id,
              attrs: { label: item.name, value: item, hidden: "" },
            })
          }),
          _c(
            "el-option",
            {
              staticStyle: { height: "auto", padding: "0" },
              attrs: { value: _vm.sValue },
            },
            [
              _c("el-tree", {
                ref: "treeDataCheck",
                attrs: {
                  data: _vm.treeData,
                  props: _vm.defaultProps,
                  "show-checkbox": "",
                  "check-strictly": "",
                  "node-key": "id",
                  "default-expanded-keys": _vm.defaultExpandedData,
                  "default-checked-keys": _vm.checkedId,
                },
                on: { check: _vm.checkData },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <el-select v-model="sValue" filterable placeholder="请选择" @change="changeData">
      <el-option
        v-for="(item, $index) in options"
        :key="$index"
        :label="item"
        :value="item"
      />
    </el-select>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: 'utf8mb4'
    }
  },
  data() {
    return {
      sValue: '',
      options: [
        'armscii8',
        'ascii',
        'big5',
        'binary',
        'cp1250',
        'cp1251',
        'cp1256',
        'cp1257',
        'cp850',
        'cp852',
        'cp866',
        'cp932',
        'dec8',
        'eucjpms',
        'euckr',
        'gb18030',
        'gb2312',
        'gbk',
        'geostd8',
        'greek',
        'hebrew',
        'hp8',
        'keybcs2',
        'koi8r',
        'koi8u',
        'latin1',
        'latin2',
        'latin5',
        'latin7',
        'macce',
        'macroman',
        'sjis',
        'swe7',
        'tis620',
        'ucs2',
        'ujis',
        'utf16',
        'utf16le',
        'utf32',
        'utf8',
        'utf8mb4'
      ]
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.sValue = val
      }
    }
  },
  methods: {
    changeData(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="article-release-container">
    <div class="release-form-data">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="temp.title" maxlength="180" show-word-limit />
        </el-form-item>
        <el-form-item label="URL标题" prop="title_url">
          <el-input v-model="temp.title_url" maxlength="180" show-word-limit />
          <el-button @click="handleCreateTitleUrl">生成URL标题</el-button>
        </el-form-item>
        <el-form-item label="类目" prop="type_id">
          <el-select
            ref="selectUpResId"
            v-model="temp.type_id"
            prop="type_id"
          >
            <el-option key="typeId" hidden :value="temp.type_id" :label="temp.type_name" />
            <el-tree
              ref="treeDataDirCheck"
              :data="typeTreeData"
              :props="defaultProps"
              :highlight-current="true"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              node-key="id"
              :default-expanded-keys="defaultData"
              @node-click="nodeClickCheck"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="搜索标签" prop="search_label">
          <el-input v-model="temp.search_label" placeholder="搜索关键词，逗号分隔开多个" maxlength="100" show-word-limit />
        </el-form-item>
        <el-form-item label="最新封面" prop="new_photo">
          <el-upload
            class="avatar-uploader"
            :action="baseUrl + '/fileCenter/image/upload?adminUpload=hsxx&path=new_photo'"
            :headers="uploadHeaders"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="(file) => { return beforeAvatarUpload(file, 80, 80)}"
          >
            <img v-if="temp.new_photo" :src="temp.new_photo" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
          <span>80*80</span>
        </el-form-item>
        <el-form-item label="首页封面" prop="home_photo">
          <el-upload
            class="avatar-uploader"
            :action="baseUrl + '/fileCenter/image/upload?adminUpload=hsxx&path=home_photo'"
            :headers="uploadHeaders"
            :show-file-list="false"
            :on-success="handleHomePhotoSuccess"
            :before-upload="(file) => { return beforeAvatarUpload(file, 550, 400)}"
          >
            <img v-if="temp.home_photo" :src="temp.home_photo" class="home-avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
          <span>550*400</span>
        </el-form-item>
        <el-form-item label="正文" prop="text">
          <wang-editor v-model="temp.text" :height="700" :z-index="100" />
        </el-form-item>
      </el-form>
    </div>

    <div class="release-footer-menu">
      <el-button @click="saveDraftMethod">
        保存草稿
      </el-button>
      <el-date-picker
        v-model="temp.release_date"
        type="datetime"
        placeholder="定时发布日期"
        format="yyyy-MM-dd HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        :picker-options="pickerOptions"
        style="margin-left: 12px;width: 180px"
      />
      <el-button type="war" @click="cronReleaseMethod">
        定时发布
      </el-button>
      <el-button type="primary" style="margin-right: 120px" @click="releaseNowMethod">
        立即发布
      </el-button>
    </div>
  </div>
</template>

<script>
import { typeTreeListApi, addArticleApi, detailArticleApi, updateArticleApi } from '@/api/article'
import WangEditor from '@/components/WangEditor'
import pinyin from 'js-pinyin'
import { mapGetters } from 'vuex'

const baseUrl = process.env.VUE_APP_BASE_API

export default {
  name: 'ArticleEditor',
  components: { WangEditor },
  data() {
    return {
      baseUrl,
      type: 'add',
      uploadHeaders: {},
      temp: {
        title: '',
        title_url: '',
        new_photo: '',
        home_photo: '',
        type_id: this.$route.query.type_id || this.$route.params.type_id,
        type_name: this.$route.query.type_name || this.$route.params.type_name,
        search_label: '',
        text: '',
        release_date: '',
        status: undefined
      },
      oldData: {
        title: '',
        title_url: '',
        new_photo: '',
        home_photo: '',
        type_id: this.$route.query.type_id || this.$route.params.type_id,
        type_name: this.$route.query.type_name || this.$route.params.type_name,
        search_label: '',
        text: ''
      },
      rules: {
        title: [{ required: true, message: '标题必须填写', trigger: 'blur' }],
        title_url: [{ required: true, message: 'URL标题必须填写', trigger: 'blur' }]
      },
      typeTreeData: [{ id: 0, label: '全部' }],
      defaultProps: { children: 'children', label: 'label' },
      defaultData: [this.$route.query.type_id || this.$route.params.type_id],
      pickerOptions: {
        // disabled为函数，返回值为布尔值，禁选日期
        disabledDate: (time) => {
          const now_at = Date.now()
          const minTime = now_at + 10 * 60 * 1000
          const maxTime = now_at + 7 * 86400 * 1000
          return time.getTime() < minTime || time.getTime() > maxTime
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'websiteCode',
      'token'
    ])
  },
  created() {
    this.uploadHeaders = { 'X-Token': this.token }
    this.getTreeData()
    this.getDetailData()
  },
  mounted() {
    window.addEventListener('keydown', this.handleEvent)
    const _this = this
    window.onbeforeunload = function(e) {
      if (_this.hasChange()) {
        e = e || window.event
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = '关闭提示'
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return '关闭提示'
      } else {
        window.onbeforeunload = null
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleEvent) // 在页面销毁的时候记得解除
    window.onbeforeunload = null
  },
  // 组件内导航钩子，处理未保存退出的情况
  beforeRouteLeave: function(to, from, next) {
    if (this.hasChange()) {
      next(false)
      this.$confirm('您有内容还未保存，确定退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 选择确定
        next()
      })
    } else {
      next()
    }
  },
  methods: {
    async handleEvent(event) {
      if (event.ctrlKey) {
        switch (event.keyCode) {
          case 83:
            // Ctrl+s 保存
            event.preventDefault()
            event.returnValue = false // 阻止直接保存网页
            this.saveDraftMethod()
            break
          default:
            break
        }
      }
    },
    hasChange() {
      if (this.oldData.title !== this.temp.title) {
        return true
      }
      if (this.oldData.title_url !== this.temp.title_url) {
        return true
      }
      if (this.oldData.new_photo !== this.temp.new_photo) {
        return true
      }
      if (this.oldData.home_photo !== this.temp.home_photo) {
        return true
      }
      if (this.oldData.type_id !== this.temp.type_id) {
        return true
      }
      if (this.oldData.type_name !== this.temp.type_name) {
        return true
      }
      if (this.oldData.search_label !== this.temp.search_label) {
        return true
      }
      if (this.oldData.text !== this.temp.text) {
        return true
      }
      return false
    },
    getTreeData() {
      typeTreeListApi({ website_code: this.websiteCode }).then(response => {
        this.typeTreeData = response.data[0].children
      })
    },
    getDetailData() {
      const id = this.$route.query.id || this.$route.params.id
      if (id) {
        detailArticleApi({ id: id }).then(response => {
          this.type = 'update'
          this.temp.website_code = response.data.website_code
          this.temp.title = response.data.title
          this.temp.title_url = response.data.title_url
          this.temp.new_photo = response.data.new_photo
          this.temp.home_photo = response.data.home_photo
          this.temp.type_id = response.data.article_type_id
          this.temp.type_name = response.data.article_type_name
          this.temp.search_label = response.data.search_label
          this.temp.text = response.data.text

          this.oldData = JSON.parse(JSON.stringify(this.temp))
        })
      }
    },
    nodeClickCheck(obj, node, selfObj) {
      this.temp.type_id = obj.id
      this.temp.type_name = obj.name
      this.$refs.selectUpResId.blur()
    },
    draftRule() {
      this.rules = {
        title: [{ required: true, message: '标题必须填写', trigger: 'blur' }],
        title_url: [{ required: true, message: 'URL标题必须填写', trigger: 'blur' }]
      }
    },
    releaseRule() {
      this.rules = {
        title: [{ required: true, message: '标题必须填写', trigger: 'blur' }],
        new_photo: [{ required: true, message: '封面图片必须上传', trigger: 'blur' }],
        home_photo: [{ required: true, message: '首页图片必须上传', trigger: 'blur' }],
        title_url: [{ required: true, message: 'URL标题必须填写', trigger: 'blur' }],
        type_id: [{ required: true, message: '类目必须填写', trigger: 'blur' }],
        text: [{ required: true, message: '发布正文必须填写', trigger: 'blur' }]
      }
    },
    saveDraftMethod() {
      this.temp.status = 0
      this.draftRule()
      this.addArticleMethod(false)
    },
    handleCreateTitleUrl() {
      pinyin.setOptions({ checkPolyphone: false, charCase: 0 })
      let s = pinyin.getFullChars(this.temp.title)
      s = s.replace(/[`:_.~!@#$%^&*() \+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*（）—— \+ ={}|《》？：“”【】、；‘’，。、]/g, ' ')
      s = s.replace(/([a-z])([A-Z])/g, '$1 $2')
      s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      s = s.replace(/ /g, '-')
      s = s.toLowerCase()
      this.temp.title_url = s
    },
    cronReleaseMethod() {
      let errorMessage = ''
      if (!this.temp.release_date) {
        errorMessage = '定时发布日期不能为空'
      } else {
        // 字符串转时间戳
        const release_at = new Date(this.temp.release_date.replace(/-/g, '/')).getTime()
        const now_at = Date.now()
        const minTime = now_at + 2 * 2 * 1000
        const maxTime = now_at + 7 * 86400 * 1000
        if (release_at < minTime || release_at > maxTime) {
          errorMessage = '定时发布，日期至少10分钟后，且不能超过7天'
        }
      }

      if (errorMessage) {
        this.$message({
          message: errorMessage,
          type: 'error'
        })
      } else {
        this.temp.status = 3
        this.releaseRule()
        this.addArticleMethod(true)
      }
    },
    releaseNowMethod() {
      this.temp.status = 1
      this.releaseRule()
      this.addArticleMethod(true)
    },
    addArticleMethod(route) {
      if (this.type === 'update') {
        const id = this.$route.query.id || this.$route.params.id || this.temp.id
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.temp.id = id
            this.temp.website_code = this.websiteCode
            updateArticleApi(this.temp).then(response => {
              this.$message({
                message: response.message,
                type: 'success'
              })

              this.oldData = JSON.parse(JSON.stringify(this.temp))
              if (route) {
                this.$router.push({
                  name: 'ArticleList',
                  params: { id: this.temp.id }
                })
              }
            })
          }
        })
      } else {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.temp.website_code = this.websiteCode
            addArticleApi(this.temp).then(response => {
              this.$message({
                message: response.message,
                type: 'success'
              })
              this.type = 'update'
              this.temp.id = response.data.id
              this.oldData = JSON.parse(JSON.stringify(this.temp))
              if (route) {
                this.$router.push({
                  name: 'ArticleList',
                  params: { id: response.data.id }
                })
              }
            })
          }
        })
      }
    },
    handleAvatarSuccess(v) {
      this.temp.new_photo = v.data[0].url || ''
    },
    handleHomePhotoSuccess(v) {
      this.temp.home_photo = v.data[0].url || ''
    },
    // 文件上传前钩子
    // 在上传图片之前，将图片大小压缩为不超过80*80，质量压缩为0.9，格式改为.jpeg
    // md 最大宽，mh 最大高
    beforeAvatarUpload(file, md, mh) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' // 图片格式是否为png或jpg
      const isLt10M = file.size / 1024 < 500 // 判断图片大小是否超过500KB
      if (!isJpgOrPng) {
        this.$message.error('文件格式错误！')
      } else if (!isLt10M) {
        this.$message.error('文件过大！')
      } else {
        const _this = this
        return new Promise(resolve => {
          const reader = new FileReader()
          const image = new Image()
          image.onload = (imageEvent) => {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            const originWidth = image.width
            const originHeight = image.height
            // 最大尺寸限制，可通过设置宽高来实现图片压缩程度
            const maxWidth = md; const maxHeight = mh
            // 目标尺寸
            let targetWidth = originWidth; let targetHeight = originHeight
            // 图片尺寸超过80x80的限制
            if (originWidth > maxWidth || originHeight > maxHeight) {
              if (originWidth / originHeight > maxWidth / maxHeight) {
                // 更宽，按照宽度限定尺寸
                targetWidth = maxWidth
                targetHeight = Math.round(maxWidth * (originHeight / originWidth))
              } else {
                targetHeight = maxHeight
                targetWidth = Math.round(maxHeight * (originWidth / originHeight))
              }
            }
            canvas.width = targetWidth
            canvas.height = targetHeight
            context.clearRect(0, 0, targetWidth, targetHeight)
            context.drawImage(image, 0, 0, targetWidth, targetHeight)
            const dataUrl = canvas.toDataURL('image/jpeg', 0.9)
            const blobData = _this.dataURLtoBlob(dataUrl, 'image/jpeg')
            resolve(blobData)
          }
          reader.onload = e => { image.src = e.target.result }
          reader.readAsDataURL(file)
        })
      }
      return isJpgOrPng && isLt10M
    },
    // 图片转二进制
    dataURLtoBlob(dataURL, type) {
      const binary = atob(dataURL.split(',')[1])
      const array = []
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      return new Blob([new Uint8Array(array)], { type: type })
    }
  }
}
</script>

<style scoped>
  .article-release-container {
    padding: 20px 20px 0 0;
  }

  .release-form-data {
    margin-bottom: 80px;
  }

  .release-footer-menu {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 60px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    line-height: 60px;
    z-index: 10010; /* 全屏editor 10002 */
  }
</style>

<style>
  .avatar-uploader .el-upload {
    border: 2px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
  .home-avatar {
    width: 110px;
    height: 80px;
    display: block;
  }
</style>

<template>
  <div class="app-container">
    <el-container class="power-manager-container">
      <el-aside width="180px">
        <div>
          <el-input v-model="filterText" placeholder="搜索权限" style="margin-bottom:2px;" />
        </div>
        <div>
          <el-tree
            ref="powerTreeData"
            :data="typeTreeData"
            :props="defaultProps"
            :filter-node-method="filterNode"
            class="filter-tree"
            :highlight-current="true"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            node-key="id"
            :default-expanded-keys="defaultData"
            @node-click="nodeClick"
          >
            <span slot-scope="{ node }" class="custom-tree-node">
              <el-tooltip class="item" effect="dark" :content="node.label" :disabled="node.label | hoverStr(13)" placement="top-start">
                <span> {{ node.label }} </span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
      </el-aside>

      <el-main class="main-tabs-content">
        <el-row :gutter="8">
          <el-col :span="4">
            <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="3">
            <el-input v-model="listQuery.code" placeholder="编码" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="listQuery.path" placeholder="路由path" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="5">
            <el-input v-model="listQuery.en_name" placeholder="路由name" clearable @keyup.enter.native="handleFilter" />
          </el-col>

          <el-col :span="8">
            <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          </el-col>
        </el-row>

        <div style="margin-top: 12px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, i) in checkTypeArray" :key="i">
              <el-link v-show="i!==checkTypeArray.length-1" :underline="false" type="primary" @click="breadcrumbClick(item.id, item.name)">{{ item.name }}</el-link>
              <span v-show="i===checkTypeArray.length-1">{{ item.name }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <el-table
          ref="tableList"
          v-loading="listLoading"
          :data="list"
          :height="tableHeight"
          fit
          highlight-current-row
          style="width: 100%; overflow: auto !important;"
        >
          <el-table-column type="expand">
            <template slot-scope="prow">
              <el-table
                :data="prow.row.children"
                fit
                highlight-current-row
              >
                <el-table-column label="权限" align="center">
                  <template slot-scope="cData">
                    <div>{{ cData.row.name }}</div>
                    <div>{{ cData.row.code }} <el-tag v-show="cData.row.is_system===1">系统</el-tag></div>
                  </template>
                </el-table-column>
                <el-table-column label="路由" align="center">
                  <template slot-scope="cData">
                    <div>name: {{ cData.row.en_name }}</div>
                    <div>path: {{ cData.row.path }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="参数" align="center">
                  <template slot-scope="cData">
                    <div>{{ cData.row.params }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="{row, $index}">
                    <div v-show="row.is_system!==1">
                      <el-dropdown :split-button="false">
                        <span class="el-dropdown-link">
                          操作<i class="el-icon-arrow-down el-icon--right" />
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="handleUpdateBack(row)">编辑</el-dropdown-item>
                          <el-dropdown-item>
                            <el-popover
                              :ref="`popoverBack` + $index"
                              placement="top"
                              :tabindex="$index"
                              style="display: block; width: 100%;"
                            >
                              <p>删除权限 `{{ row.name }}` 吗？</p>
                              <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="handleCancelVisibleBack($index)">取消</el-button>
                                <el-button size="mini" type="danger" @click="handleDelBack(row, $index)">确定</el-button>
                              </div>
                              <span slot="reference" style="display: block; width: 100%;">删除</span>
                            </el-popover>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="名称" align="center">
            <template slot-scope="{row}">
              <div>{{ row.name }}</div>
              <div>{{ row.code }} <el-tag v-show="row.is_system===1">系统</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="路由path" align="center">
            <template slot-scope="{row}">
              <div>{{ row.path }}</div>
            </template>
          </el-table-column>
          <el-table-column label="路由name" align="center">
            <template slot-scope="{row}">
              <div>{{ row.en_name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{row}">
              <el-dropdown :split-button="false">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="handleCreateBack(row.id)">添加权限</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListData" />
      </el-main>
    </el-container>

    <!--后台权限-->
    <el-dialog :title="textMap[dialogStatus] + `-后台权限`" :visible.sync="dialogFormVisibleBack">
      <el-form ref="backDataForm" :rules="rules" :model="temp" label-position="right" label-width="110px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="temp.code" maxlength="8" show-word-limit />
        </el-form-item>
        <el-form-item label="后台路由path" prop="path">
          <el-input v-model="temp.path" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item label="后台路由name" prop="en_name">
          <el-input v-model="temp.en_name" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item label="参数" prop="params">
          <el-input v-model="temp.params" maxlength="255" show-word-limit />
        </el-form-item>
        <el-form-item label="数据权限" prop="is_view_data">
          <el-switch v-model="temp.is_view_data" :active-value="switchData.open" :inactive-value="switchData.close" />
          <span style="margin-left: 20px">开启后，只显示对应级别的数据</span>
        </el-form-item>
        <el-form-item v-if="temp.is_view_data === `1` || temp.is_view_data === 1" label="数据级别" prop="is_view_organization">
          <el-tooltip :content="(temp.view_data_type === `1` || temp.view_data_type === 1) ? '只显示用户自己创建的数据' : '显示用户组织及下属组织创建的数据'" placement="top">
            <el-switch v-model="temp.view_data_type" active-color="#ff4949" inactive-color="#13ce66" :active-value="switchTypeData.user" :inactive-value="switchTypeData.organization" active-text="用户级" inactive-text="组织级" />
          </el-tooltip>
        </el-form-item>
        <el-form-item v-if="(temp.is_view_data === `1` || temp.is_view_data === 1) && (temp.view_data_type === `1` || temp.view_data_type === 1)" label="数据校验字段" prop="view_user_field">
          <el-input v-model="temp.view_user_field" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item v-if="(temp.is_view_data === `1` || temp.is_view_data === 1) && (temp.view_data_type === `2` || temp.view_data_type === 2)" label="数据校验字段" prop="view_organization_field">
          <el-input v-model="temp.view_organization_field" maxlength="50" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleBack = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createDataBack():updateDataBack()">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { powerTreeListApi, powerListApi, powerBackAddApi, powerBackUpdateApi, powerBackDeleteApi } from '@/api/user-center'
import { ellipsis, ellipsisBool } from '@/utils/validate'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

export default {
  name: 'UserPower',
  components: { Pagination },
  filters: {
    hoverStr(str, len) {
      return !ellipsisBool(str, len)
    },
    hoverStrOmit(str, len) {
      return ellipsis(str, len)
    }
  },
  data() {
    return {
      switchData: { open: 1, close: 0 },
      switchTypeData: { user: 1, organization: 2 },
      checkTypeArray: [{ name: '全部' }],
      typeTreeData: [{ id: 0, label: '全部' }],
      filterText: '',
      defaultData: [0, 0],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableHeight: 300,
      listLoading: false,
      list: [],
      listQuery: {
        page: 1,
        limit: 20,
        parent_id: 0,
        name: ''
      },
      total: 0,
      temp: {
        id: undefined,
        parent_id: undefined,
        name: '',
        code: ''
      },
      tempParent: { id: 0, name: '全部' },
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{ required: true, message: '必须填写', trigger: 'change' }],
        path: [{ required: true, message: '必须填写', trigger: 'change' }]
      },
      dialogFormVisibleBack: false
    }
  },
  watch: {
    filterText(val) {
      this.$refs.powerTreeData.filter(val)
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 10 - 56
      // 监听窗口大小变化
      window.onresize = function() {
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 10 - 56
      }
    })
  },
  created() {
    this.getTreeData()
    this.getListData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getTreeData() {
      powerTreeListApi().then(response => {
        this.typeTreeData = response.data
      })
    },
    getListData() {
      this.listLoading = true
      powerListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.getListData()
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    resetListQuery() {
      this.listQuery = {
        page: 1,
        limit: 20,
        parent_id: 0,
        name: ''
      }
    },
    nodeClick(obj, node, selfObj) {
      this.resetListQuery()
      const typeArray = [{ name: obj.name }]
      let parentData = node.parent
      while (parentData) {
        if (parentData.parent) {
          typeArray.unshift({ id: parentData.data.id, name: parentData.data.name })
        }
        parentData = parentData.parent
      }
      this.checkTypeArray = typeArray
      this.defaultData[1] = obj.id
      this.tempParent = { id: obj.id, name: obj.name }

      this.listQuery.parent_id = obj.id
      this.handleFilter()
    },
    breadcrumbClick(id, name) {
      const typeArray = []
      let listData = this.typeTreeData
      for (const i in this.checkTypeArray) {
        for (const j in listData) {
          if (listData[j].id === this.checkTypeArray[i].id) {
            listData = listData[j].children
            break
          }
          if (listData[j].id === id) {
            listData = listData[j].children
            name = this.checkTypeArray[i].name
            break
          }
        }

        if (id !== this.checkTypeArray[i].id) {
          typeArray.push(this.checkTypeArray[i])
        } else {
          typeArray.push({ name: name })
          break
        }
      }

      this.checkTypeArray = typeArray
      this.defaultData[1] = id
      this.tempParent = { id: id, name: name }

      this.listQuery.parent_id = id
      this.handleFilter()
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        parent_id: undefined,
        name: '',
        code: ''
      }
    },
    // 后台权限
    handleCreateBack(power_id) {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisibleBack = true
      this.temp.power_id = power_id
      this.temp.view_user_field = 'create_user_id'
      this.temp.view_organization_field = 'create_organization_id'
      this.$nextTick(() => {
        this.$refs['backDataForm'].clearValidate()
      })
    },
    createDataBack() {
      this.$refs['backDataForm'].validate((valid) => {
        if (valid) {
          powerBackAddApi(this.temp).then((response) => {
            this.dialogFormVisibleBack = false
            this.handleFilter()
            this.$message({
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleUpdateBack(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisibleBack = true
      this.$nextTick(() => {
        this.$refs['backDataForm'].clearValidate()
      })
    },
    updateDataBack() {
      this.$refs['backDataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          powerBackUpdateApi(tempData).then((response) => {
            this.handleFilter()
            this.dialogFormVisibleBack = false
            this.$message({
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleCancelVisibleBack(index) {
      this.$refs[`popoverBack` + index].doClose()
    },
    handleDelBack(row, index) {
      this.$refs[`popoverBack` + index].doClose()
      powerBackDeleteApi({ id: row.id }).then(response => {
        this.handleFilter()
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>
  .main-tabs-content {
    padding: 0 20px 0 8px !important;
  }
  .power-manager-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .power-manager-container .el-pagination {
    text-align: center;
    padding-top: 20px;
  }
  .tab-key-desc span {
    margin-right: 20px;
  }
  .power-manager-container .tip {
    padding: 10px 0;
    text-align: center;
    font-family: "Hiragino Sans GB";
    font-size: 12px;
  }
</style>


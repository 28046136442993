import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/', '/index', '/404'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)
  if (to.meta.specialTitle != null && to.meta.specialTitle !== '') {
    document.title = to.meta.specialTitle
  }

  // set page keywords
  if (to.meta.keywords != null && to.meta.keywords !== '') {
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords)
  }
  if (to.meta.description != null && to.meta.description !== '') {
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
  }

  // set page favicon icon
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  if (to.meta.favicon != null && to.meta.favicon !== '') {
    link.href = '/favicon/' + to.meta.favicon + '.ico'
  } else {
    link.href = '/favicon/favicon.ico'
  }
  document.getElementsByTagName('head')[0].appendChild(link)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login' || to.path === '/') {
      // if is logged in, redirect to the home page
      next('/admin')
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { roles, rolesData } = await store.dispatch('user/getInfo')

          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', { roles, rolesData })

          // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    if (to.matched.length === 0) {
      next(`/`) // 404
      NProgress.done()
    } else {
      let a = 1
      for (const i in whiteList) {
        if (whiteList[i].indexOf('*') === whiteList[i].length - 1) {
          if (to.path.indexOf(whiteList[i].replace(/\*/, '')) === 0) {
            a = 2
            break
          }
        } else {
          if (whiteList[i] === to.path) {
            a = 2
            break
          }
        }
      }
      if (a === 2) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

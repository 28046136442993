<template>
  <div class="app-container">
    <el-result icon="warning" title="警告提示" sub-title="请检查路由菜单配置是否正确" />
  </div>
</template>

<script>

export default {
  name: 'Index'
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="view-develop">
    <el-container>
      <el-header>
        <el-row style="padding: 10px 0;line-height: 32px;">
          <el-col :span="6">
            <span>库销存</span> &nbsp;&nbsp; <i class="el-icon-arrow-right" /> &nbsp;&nbsp; <span>测试表单</span> &nbsp; <i class="el-icon-edit" />
          </el-col>
          <el-col :span="12" style="text-align: center">
            <el-link class="el-icon-document" :underline="false">表单设计</el-link>
            <el-link class="el-icon-setting" :underline="false" style="margin-left: 10px">页面设置</el-link>
          </el-col>
          <el-col :span="6" style="text-align: right">
            <el-button round @click="handelView">预览</el-button>
            <el-button type="primary" round @click="handelSave">保存</el-button>
          </el-col>
        </el-row>
        <el-divider />
      </el-header>
      <el-container>
        <el-aside width="auto">
          <el-tabs v-model="menuName" tab-position="left">
            <el-tab-pane name="menu1">
              <span slot="label" class="left-menu">
                <el-tooltip class="item" effect="dark" content="大纲树" placement="right">
                  <i class="el-icon-s-unfold" />
                </el-tooltip>
              </span>
              <div style="width: 200px">
                <el-row>
                  <el-col :span="12"><h4>大纲树</h4></el-col>
                  <el-col :span="12" style="text-align: right">
                    <span style="cursor:pointer;"><i class="el-icon-close" @click="menuName = null" /></span>
                  </el-col>
                </el-row>
                大纲树
                <h3>{{ id }}</h3>
              </div>
            </el-tab-pane>
            <el-tab-pane name="menu2">
              <span slot="label" class="left-menu">
                <el-tooltip class="item" effect="dark" content="组件库" placement="right">
                  <i class="el-icon-menu" />
                </el-tooltip>
              </span>
              <div style="width: 240px">
                <el-row>
                  <el-col :span="12"><h4>组件库</h4></el-col>
                  <el-col :span="12" style="text-align: right">
                    <span style="cursor:pointer;"><i class="el-icon-close" @click="menuName = null" /></span>
                  </el-col>
                </el-row>
                <div>
                  <draggable v-model="attributeData" :sort="false" :options="{group: {name: 'form', pull: 'clone'}, sort: true}" @end="handelEnd">
                    <el-button v-for="(item, index) in attributeData" :key="index" :icon="item.icon">{{ item.label }}</el-button>
                  </draggable>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="menu3">
              <span slot="label" class="left-menu">
                <el-tooltip class="item" effect="dark" content="数据源" placement="right">
                  <i class="el-icon-coin" />
                </el-tooltip>
              </span>
              <div>
                <div style="width: auto">
                  <el-row>
                    <el-col :span="12"><h4>数据源</h4></el-col>
                    <el-col :span="12" style="text-align: right">
                      <span style="cursor:pointer;"><i class="el-icon-close" @click="menuName = null" /></span>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row :gutter="10" style="max-width: 300px">
                      <el-col :span="10"><common-select v-model="dataFilter.select" :options="dataSelectOptions" /></el-col>
                      <el-col :span="14"><el-input v-model="dataFilter.name" placeholder="搜索" suffix-icon="el-icon-search" /></el-col>
                    </el-row>
                    <div style="margin: 10px 0">
                      <el-dropdown trigger="click">
                        <el-button type="primary">添加</el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item>变量</el-dropdown-item>
                          <el-dropdown-item @click.native="handelAddMainTable">主表</el-dropdown-item>
                          <el-dropdown-item @click.native="handelAddSecondTable">从表</el-dropdown-item>
                          <el-dropdown-item>API</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    <div class="scroll-bar" :style="{height: (attributeHeight - 60) + 'px', overflow: 'auto'}">
                      <el-collapse accordion>
                        <el-collapse-item v-for="(item,index) in dataSourceData" :key="index">
                          <template slot="title">
                            <el-tag :type="item.type | sourceTypeTagFilter" style="margin-right: 12px">{{ item.type | sourceTypeFilter }}</el-tag>
                            {{ item.name }}
                          </template>
                          <div v-show="item.type===2">
                            <div>描述：{{ item.describe }}</div>
                            <div>表：{{ item.database_name }}.{{ item.table_name }}</div>
                          </div>
                          <div v-show="item.type===3">
                            <div>描述：{{ item.describe }}</div>
                            <div>主表：{{ item.main_table }}</div>
                            <div>从表：{{ item.database_name }}.{{ item.table_name }}</div>
                            <div>连表类型：{{ item.join_type | joinTypeDataFilter }}</div>
                            <div>连表表达式：{{ item.join_expression }}</div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-aside>
        <el-main style="overflow-y: hidden">
          <div style="text-align: right; padding: 4px 8px 8px 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
            <span class="drawer-top-tool" style="cursor:pointer; font-size: 18px"><i class="el-icon-refresh-left" /></span>
            <span class="drawer-top-tool" style="cursor:pointer; font-size: 18px"><i class="el-icon-refresh-right" /></span>
            <el-divider direction="vertical" />
            <el-link class="drawer-top-tool" :underline="false">表单设置</el-link>
            <el-link class="drawer-top-tool" :underline="false">页面属性</el-link>
          </div>
          <div class="canvas-content scroll-bar">
            <draggable v-model="attributeDrawer" group="form" :sort="true" :style="{height: (10 + attributeHeight)+`px`, paddingBottom: `10px`}" @end="handelEndDrawer">
              <div v-for="(item, index) in attributeDrawer" :key="index" :icon="item.icon" class="canvas-content-detail">
                <el-form label-position="top">
                  <el-form-item :label="item.label">
                    <div v-if="item.id===1">
                      <el-radio label="1">选项1</el-radio>
                      <el-radio label="2">选项2</el-radio>
                    </div>
                    <div v-if="item.id===2">
                      <el-checkbox>选择1</el-checkbox>
                      <el-checkbox>选择2</el-checkbox>
                    </div>
                    <el-input v-if="item.id===3" placeholder="请输入内容" />
                  </el-form-item>
                </el-form>
              </div>
            </draggable>
          </div>
        </el-main>
        <el-aside width="280px">
          <div style="overflow-y: hidden">
            <div style="padding: 0 10px; margin-bottom: 10px">
              <h5 style="text-align: center">属性</h5>
              <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
                <el-breadcrumb-item>活动列表</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div ref="attributeList" class="attribute-list scroll-bar" :style="{height: attributeHeight+`px`, overflow: 'auto'}">
              <div style="padding: 0 10px;">
                <h3>{{ viewType===1 ? '普通表单' : '其他' }}</h3>
                <el-collapse v-model="activeNames">
                  <el-collapse-item name="1">
                    <template slot="title">
                      <h4>PC端设置</h4>
                    </template>
                    <el-row :gutter="8">
                      <el-col :span="10"><span>列数</span></el-col>
                      <el-col :span="14" style="text-align: right">
                        <el-button>1列</el-button>
                        <el-button>2列</el-button>
                      </el-col>
                    </el-row>
                  </el-collapse-item>
                  <el-collapse-item title="表单校验" name="2">
                    <div>公式校验</div>
                    <div><el-button>添加公式</el-button></div>
                  </el-collapse-item>
                  <el-collapse-item title="表单事件" name="3">
                    <div>工时执行</div>
                    <div>运行失败时，终止后续规则</div>
                    <div><el-button>添加业务关联规则</el-button></div>
                    <div>已配置0条集成&自动化</div>
                  </el-collapse-item>
                  <el-collapse-item title="高级" name="4">
                    <div>提交文案 <el-input value="提交" /></div>
                    <div>表单提交前</div>
                    <div><el-button>绑定动作</el-button></div>
                    <div>表单提交后</div>
                    <div><el-button>绑定动作</el-button></div>
                    <div>表单数据源</div>
                    <div><el-button>编辑代码</el-button></div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
        </el-aside>
      </el-container>
    </el-container>

    <!-- 数据源-主表 -->
    <el-drawer
      title="数据源-主表"
      :visible.sync="mainTableDrawer"
      direction="ltr"
      size="40%"
    >
      <div class="drawer-content">
        <div class="drawer-form">
          <el-form ref="mainTableForm" :rules="mainTableRules" :model="tmpSource" label-position="right" label-width="80px" style="padding: 0 12px">
            <el-form-item label="名称" prop="name">
              <el-input v-model="tmpSource.name" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item label="描述" prop="describe">
              <el-input v-model="tmpSource.describe" maxlength="50" show-word-limit />
            </el-form-item>
            <el-form-item label="数据表" prop="table_name">
              <el-cascader v-model="tmpSource.table_name" :options="tableOptions" filterable clearable @change="handelChangeTable" />
            </el-form-item>
          </el-form>
          <el-form label-position="top" class="scroll-bar" :style="{padding: '0 12px', height: (attributeHeight - 135) + 'px', overflow: 'auto'}">
            <el-form-item v-show="selectTable.table_id>0" label="主表">
              <span>{{ selectTable.database }}.{{ selectTable.table_name }}</span>
            </el-form-item>
            <el-form-item v-show="selectTable.table_id>0" label="字段">
              <el-table :data="tableFieldIndex.fieldList">
                <el-table-column label="表名" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.name }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="类型" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.type | mysqlTypeFilter }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item v-show="selectTable.table_id>0" label="索引">
              <el-table :data="tableFieldIndex.indexList">
                <el-table-column label="索引名" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.name }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="字段" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.field }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-footer">
          <el-button @click="mainTableDrawer = false">取消</el-button>
          <el-button type="primary" @click="handelSaveMainTable">保存</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 数据源-从表 -->
    <el-drawer
      title="数据源-从表"
      :visible.sync="secondTableDrawer"
      direction="ltr"
      size="40%"
    >
      <div class="drawer-content">
        <div class="drawer-form">
          <el-form ref="secondTableForm" :rules="secondTableRules" :model="tmpSource" label-position="right" label-width="100px" style="padding: 0 12px">
            <el-form-item label="名称" prop="name">
              <el-input v-model="tmpSource.name" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item label="描述" prop="describe">
              <el-input v-model="tmpSource.describe" maxlength="50" show-word-limit />
            </el-form-item>
            <el-form-item label="主表" prop="main_table">
              <common-select v-model="tmpSource.main_table" :options="mainTableList" />
            </el-form-item>
            <el-form-item label="数据表" prop="table_name">
              <el-cascader v-model="tmpSource.table_name" :options="tableOptions" filterable clearable @change="handelChangeTable" />
            </el-form-item>
            <el-form-item label="连表类型" prop="join_type">
              <common-select v-model="tmpSource.join_type" :options="joinTypeData" />
            </el-form-item>
            <el-form-item label="连表表达式" prop="join_expression">
              <el-input v-model="tmpSource.join_expression" maxlength="100" show-word-limit />
            </el-form-item>
          </el-form>
          <el-form label-position="top" class="scroll-bar" :style="{padding: '0 12px', height: (attributeHeight - 300) + 'px', overflow: 'auto'}">
            <el-form-item v-show="selectTable.table_id>0" label="主表">
              <span>{{ tmpSource.main_table }}</span>
            </el-form-item>
            <el-form-item v-show="selectTable.table_id>0" label="从表">
              <span>{{ selectTable.database }}.{{ selectTable.table_name }}</span>
            </el-form-item>
            <el-form-item v-show="selectTable.table_id>0" label="字段">
              <el-table :data="tableFieldIndex.fieldList">
                <el-table-column label="表名" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.name }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="类型" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.type | mysqlTypeFilter }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item v-show="selectTable.table_id>0" label="索引">
              <el-table :data="tableFieldIndex.indexList">
                <el-table-column label="索引名" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.name }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="字段" align="center">
                  <template slot-scope="{row}">
                    <div>{{ row.field }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-footer">
          <el-button @click="secondTableDrawer = false">取消</el-button>
          <el-button type="primary" @click="handelSaveSecondTable">保存</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { viewConfigDetailApi, viewConfigSaveApi, dataSourceTableSelectApi, dataSourceTableFieldIndexApi } from '@/api/config-center'
import CommonSelect from '@/components/BaseData/CommonSelect'
import { typeFilter } from '@/components/BaseData/MySQL/filterData'

const sourceTypeOptions = [{ label: '变量', value: 1, type: 'success' }, { label: '数据主表', value: 2, type: 'primary' }, { label: '数据从表', value: 3, type: 'warning' }, { label: 'API', value: 4, type: 'error' }]
const sourceTypeOptionsKeyValue = sourceTypeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.label
  return acc
}, {})
const sourceTypeOptionsTag = sourceTypeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.type
  return acc
}, {})
const joinTypeData = [{ label: '左连接', value: 1 }, { label: '右连接', value: 2 }, { label: '内连接', value: 3 }]
const joinTypeDataKeyValue = joinTypeData.reduce((acc, cur) => {
  acc[cur.value] = cur.label
  return acc
}, {})

export default {
  name: 'ViewDevelop',
  components: { draggable, CommonSelect },
  filters: {
    mysqlTypeFilter(v) {
      return typeFilter(v)
    },
    sourceTypeFilter(v) {
      return sourceTypeOptionsKeyValue[v]
    },
    sourceTypeTagFilter(v) {
      return sourceTypeOptionsTag[v]
    },
    joinTypeDataFilter(v) {
      return joinTypeDataKeyValue[v]
    }
  },
  data() {
    return {
      id: null,
      viewType: null,
      menuName: null,
      showMenu: false,
      sourceTypeOptions,
      activeNames: ['1', '2', '3', '4'],
      attributeHeight: 300,
      attributeData: [{ id: 1, label: '单选', icon: 'el-icon-edit' }, { id: 2, label: '多选', icon: 'el-icon-search' }, { id: 3, label: '单行文本', icon: 'el-icon-search' }],
      attributeDrawer: [],
      dataFilter: { select: '', name: '' },
      dataSelectOptions: [{ label: '全部', value: '' }, { label: '数据表', value: '1' }, { label: 'API', value: '2' }],
      dataSourceData: [],
      mainTableDrawer: false,
      secondTableDrawer: false,
      mainTableRules: {
        name: [
          { required: true, message: '必须填写', trigger: 'change' },
          { pattern: /^[A-Za-z]+[A-Za-z0-9_]*$/, message: '命名规则错误', trigger: 'blur' }
        ],
        describe: [{ required: true, message: '描述必须填写', trigger: 'change' }],
        table_name: [{ required: true, message: '表必须选择', trigger: 'change' }]
      },
      secondTableRules: {
        name: [
          { required: true, message: '必须填写', trigger: 'change' },
          { pattern: /^[A-Za-z]+[A-Za-z0-9_]*$/, message: '命名规则错误', trigger: 'blur' }
        ],
        describe: [{ required: true, message: '描述必须填写', trigger: 'change' }],
        table_name: [{ required: true, message: '表必须选择', trigger: 'change' }],
        main_table: [{ required: true, message: '主表必须选择', trigger: 'change' }],
        join_type: [{ required: true, message: '连表方式必须选择', trigger: 'change' }],
        join_expression: [{ required: true, message: '必须填写', trigger: 'change' }]
      },
      tmpSource: { name: '', describe: '' },
      tableOptions: [],
      selectTable: { database: '', table_id: 0, table_name: '' },
      tableFieldIndex: { fieldList: [], indexList: [] },
      mainTableList: [],
      joinTypeData
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      self.attributeHeight = window.innerHeight - 150
      // 监听窗口大小变化
      window.onresize = function() {
        self.attributeHeight = window.innerHeight - 150
      }
    })
  },
  created() {
    const id = this.$route.query.id
    const type = this.$route.query.type
    if (!id || !type) {
      this.$message({ type: 'error', message: '参数不能为空' })
    } else {
      this.id = id
      this.viewType = type
    }
    this.getDetailData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getDetailData() {
      viewConfigDetailApi({ id: this.id, type: this.viewType }).then(response => {
        this.attributeDrawer = response.data
      })
    },
    handelSave() {
      const jsonData = JSON.stringify(this.attributeDrawer)
      viewConfigSaveApi({ id: this.id, type: this.viewType, json_data: jsonData }).then(response => {
        this.$message({ type: 'success', message: '保存成功' })
      })
    },
    handelEnd(e) {
    },
    handelEndDrawer(e) {
    },
    handelView() {
    },
    resetTmpSource() {
      this.tmpSource = { name: '', describe: '' }
      this.selectTable = { database: '', table_id: 0, table_name: '' }
    },
    // 添加主表
    handelAddMainTable() {
      this.resetTmpSource()
      dataSourceTableSelectApi().then(response => {
        this.tableOptions = response.data
        this.mainTableDrawer = true
      })
    },
    handelChangeTable(e) {
      if (e && e.length === 2) {
        this.selectTable.database = e[0]
        const tableArray = e[1].split(' ')
        this.selectTable.table_id = tableArray[0]
        this.selectTable.table_name = tableArray[1]
        dataSourceTableFieldIndexApi({ table_id: tableArray[0] }).then(response => {
          this.tableFieldIndex.fieldList = response.data.fieldList
          this.tableFieldIndex.indexList = response.data.indexList
        })
      } else {
        this.selectTable = { database: '', table_id: 0, table_name: '' }
      }
    },
    // 保存主表
    handelSaveMainTable() {
      this.$refs['mainTableForm'].validate((valid) => {
        if (valid) {
          this.dataSourceData.push({
            power_id: this.id,
            type: 2,
            name: this.tmpSource.name,
            describe: this.tmpSource.describe,
            table_id: this.selectTable.table_id,
            table_name: this.selectTable.table_name,
            database_name: this.selectTable.database
          })
          this.mainTableList.push({
            label: this.tmpSource.name,
            value: this.selectTable.database + '.' + this.selectTable.table_name
          })
          this.mainTableDrawer = false
        }
      })
    },
    // 添加从表
    handelAddSecondTable() {
      this.resetTmpSource()
      dataSourceTableSelectApi().then(response => {
        this.tableOptions = response.data
        this.secondTableDrawer = true
      })
    },
    // 保存从表
    handelSaveSecondTable() {
      this.$refs['secondTableForm'].validate((valid) => {
        if (valid) {
          this.dataSourceData.push({
            power_id: this.id,
            type: 3,
            name: this.tmpSource.name,
            describe: this.tmpSource.describe,
            table_id: this.selectTable.table_id,
            table_name: this.selectTable.table_name,
            database_name: this.selectTable.database,
            main_table: this.tmpSource.main_table,
            join_type: this.tmpSource.join_type,
            join_expression: this.tmpSource.join_expression
          })

          this.secondTableDrawer = false
        }
      })
    }
  }
}
</script>

<style scoped>
  .view-develop .el-divider--horizontal {
    margin: 0 !important;
  }
  .left-menu {
    font-size: 24px;
  }
  .view-develop .el-main {
    padding: 0 6px;
  }
  .drawer-top-tool {
    margin: 0 8px;
  }
  .canvas-content {
    overflow-y: auto;
    margin: 10px 0;
    padding: 10px 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .scroll-bar::-webkit-scrollbar {
    width: 4px;
    height: auto;
    display: block;
  }
  .scroll-bar::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px rgba(82, 82, 82, 0.7);
    background: rgba(82, 82, 82, .7);
  }
  .canvas-content-detail {
    cursor:pointer;
    border: 2px solid transparent;
  }
  .canvas-content-detail:hover {
    border: 2px solid #1f6feb;
  }
  .drawer-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px 0 20px;
  }
  .drawer-content .drawer-form {
    flex: 1;
  }
  .drawer-footer {
    text-align: center;
    display: flex;
    padding: 10px 0 10px 0;
    margin-left: 20%;
    margin-right: 20%;
  }
  .drawer-footer button {
    flex: 1;
  }
</style>

<template>
  <div>
    <el-upload
      ref="upload"
      class="upload-demo"
      :drag="true"
      :multiple="false"
      :show-file-list="false"
      accept="image/*"
      action=""
      :auto-upload="false"
      :on-change="onChange"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <el-progress v-if="percentage > 0 && percentage < 100" v-model="progressStatus" class="el-upload_progress" :text-inside="true" :stroke-width="18" :percentage="percentage" />
      <div slot="tip" class="el-upload__tip">
        只能上传jpg/png等图片文件，且不超过{{ maxSize }}kb
      </div>
    </el-upload>
    <el-image
      v-if="tempShowImg"
      :lazy="false"
      style="width: 160px; max-height: 480px; margin: 12px; border-radius: 12px;"
      :src="imgUrl"
      :preview-src-list="imgListUrl"
    >
      <div slot="placeholder" class="image-slot">
        加载中<span class="dot">...</span>
      </div>
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline" />
      </div>
    </el-image>
  </div>
</template>

<script>
import { getToken } from '@/api/upload'
import * as qiniu from 'qiniu-js'
import { ellipsis } from '@/utils/validate'

export default {
  props: {
    showImg: {
      type: Boolean,
      default: false
    },
    confirmUpload: {
      type: Boolean,
      default: false
    },
    dirName: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      tempShowImg: this.showImg,
      imgUrl: '',
      imgListUrl: [],
      percentage: 0,
      progressStatus: { status: 'success' },
      fileObj: null,
      uploadData: {
        parent_id: 0,
        name: '',
        url: '',
        size: 0,
        width: 0,
        height: 0
      }
    }
  },
  watch: {
    showImg: {
      immediate: false,
      handler(val) {
        this.tempShowImg = val
      }
    },
    confirmUpload: {
      handler(val) {
        if (val) {
          if (this.tempShowImg && this.fileObj !== null) {
            // 父组件传来确认，执行上传
            this.uploadQiniu(this.fileObj)
          } else {
            this.$notify({
              title: '上传失败',
              message: '请选择图片',
              type: 'error',
              duration: 2500
            })
          }
        }
      }
    },
    maxSize: {
      immediate: false,
      handler(val) {}
    }
  },
  methods: {
    onChange(event) {
      // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
      // auto-upload="false"，只会跑这个方法，before-upload等不执行
      this.fileObj = event.raw
      const vue = this
      if (this.beforeUpload(this.fileObj)) {
        const reader = new FileReader()
        reader.readAsDataURL(event.raw)
        reader.onload = () => {
          this.imgUrl = reader.result
          this.imgListUrl = [this.imgUrl]
          this.tempShowImg = true
          this.$emit('onChange', 'change')

          const img = document.createElement('img')
          img.src = this.imgUrl
          img.onload = function() {
            vue.uploadData.width = img.width
            vue.uploadData.height = img.height
          }
        }
      }
    },
    beforeUpload(file) {
      let errorMessage = '' // eslint-disable-line no-unused-vars
      if (file.size > this.maxSize * 1024) {
        errorMessage = '文件超过' + this.maxSize + 'kb'
      }
      if (file.type.indexOf('image') !== 0) {
        errorMessage = '上传的文件不是图片文件'
      }

      if (errorMessage !== '') {
        this.$notify({
          title: '上传失败',
          message: errorMessage,
          type: 'error',
          duration: 2500
        })
        return false
      }
      this.uploadData.size = file.size
      return true
    },
    uploadQiniu(file) {
      const fileObj = file // eslint-disable-line no-unused-vars
      const fileName = ellipsis(file.name, 30, '') // eslint-disable-line no-unused-vars
      this.uploadData.name = fileName

      if (file.type.indexOf('gif') > 0) {
        this.upload(fileName, fileObj)
      } else {
        const options = {
          quality: 0.70,
          noCompressIfLarger: true
          // maxWidth: 300
          // maxHeight: 618
        }
        // options = { quality: number，图片压缩质量，在图片格式为 image/jpeg 或 image/webp 的情况下生效，其他格式不会生效，可以从 0 到 1 的区间内选择图片的质量。默认值 0.92, maxWidh: number，压缩图片的最大宽度值, maxHeight: number，压缩图片的最大高度值, noCompressIfLarger: boolean，为 true 时如果发现压缩后图片大小比原来还大，则返回源图片（即输出的 dist 直接返回了输入的 file）；默认 false，即保证图片尺寸符合要求，但不保证压缩后的图片体积一定变小 }
        qiniu.compressImage(fileObj, options).then(res => {
          // res = { dist: 压缩后输出的 Blob 对象或原始的 File 对象, width: 压缩后的图片宽度, height: 压缩后的图片高度 }
          this.uploadData.size = res.dist.size // 压缩后的大小
          this.upload(fileName, res.dist)
        })
      }
    },
    upload(fileName, file) {
      // 请求获取token
      getToken({ type: 'qiniu', fileName: fileName, dir: this.dirName }).then(response => {
        /**
         * 文档：https://developer.qiniu.com/kodo/1283/javascript
         * file: File 对象，上传的文件
         * key: 文件资源名，为空字符串时则文件资源名也为空，为 null 或者 undefined 时则自动使用文件的 hash 作为文件名
         * token: 上传验证信息，前端通过接口请求后端获得
         * putExtra: object，其中的每一项都为可选 { fname: string，文件原始文件名，若未指定，则魔法变量中无法使用 fname、ext、suffix, customVars: object，用来放置自定义变量，变量名必须以 x: 开始, metadata: object，用来防止自定义 meta，变量名必须以 x-qn-meta-开始, mimeType: string，指定所传的文件类型 }
         * config: object，其中的每一项都为可选
         */
        const observable = qiniu.upload(file, response.data.key, response.data.token, { fname: fileName }, { useCdnDomain: true, region: qiniu.region.z2 })
        // 上传开始
        observable.subscribe(this.uploadNext, this.uploadError, this.uploadComplete)

        // 上传取消
        // subscription.unsubscribe()
      })
    },
    uploadNext(res) {
      // 接收上传进度信息的回调函数
      // res.total = { loaded: 已上传大小，单位为字节, total: 本次上传的总量控制信息，单位为字节，注意这里的 total 跟文件大小并不一致, percent: 当前上传进度，范围：0～100 }
      this.percentage = res.total.percent
    },
    uploadError(err) {
      // 上传错误后触发；自动重试本身并不会触发该错误，而当重试次数到达上限后则可以触发
      // err.QiniuError = { name: 错误的类型, message: 错误的信息, stack: 调用堆栈信息 }
      this.$notify({
        title: '上传失败',
        message: err.data.error,
        type: 'error',
        duration: 2500
      })
    },
    uploadComplete(res) {
      this.uploadData.url = res.url
      this.$emit('uploadComplete', this.uploadData)
      // 接收上传完成后的后端返回信息，取决于token
      // this.percentage = 100
      // // this.showImg = true
      // this.imgUrl = res.url
      // this.imgListUrl = [res.url]
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-upload_progress {
    margin: 20px;
  }
  .avatar {
    width: 100px;
    height: auto;
  }
</style>

<template>
  <div>
    <p>123</p>
    <h3>{{ id }}</h3>
    <h3>{{ viewType }}</h3>
  </div>
</template>

<script>

export default {
  name: 'Test',
  data() {
    return {
      id: null,
      viewType: null
    }
  },
  created() {
    const id = this.$route.query.id
    const type = this.$route.query.type
    if (!id || !type) {
      this.$message({ type: 'error', message: '参数不能为空' })
    } else {
      this.id = id
      this.viewType = type
    }
  }
}
</script>

<style scoped>
</style>


<template>
  <div class="app-container">
    <el-container class="manager-container">
      <el-aside width="220px">
        <div>
          <el-input v-model="filterText" placeholder="搜索分类" style="margin-bottom:2px;" />
        </div>
        <div>
          <el-tree
            ref="typeTreeData"
            :data="typeTreeData"
            :props="defaultProps"
            :filter-node-method="filterNode"
            class="filter-tree"
            :highlight-current="true"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            node-key="id"
            :default-expanded-keys="defaultData"
            @node-click="nodeClick"
          >
            <span slot-scope="{ node }" class="custom-tree-node">
              <el-tooltip class="item" effect="dark" :content="node.label" :disabled="node.label | hoverStr(13)" placement="top-start">
                <span> {{ node.label }} </span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
      </el-aside>

      <el-main class="article-main-content">
        <el-row :gutter="6" style="margin-bottom: 12px">
          <el-col :span="4">
            <el-input v-model="listQuery.id" placeholder="ID" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="listQuery.title" placeholder="标题" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="4">
            <common-select v-model="listQuery.type" :width="`100%`" clearable placeholder="选择联盟" :options="typeOptions" />
          </el-col>

          <el-col :span="10">
            <el-button v-waves type="primary" icon="el-icon-search" @click="handleFilter">
              搜索
            </el-button>
            <el-button style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">
              添加
            </el-button>
          </el-col>
        </el-row>
        <div style="margin: 12px 0">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, i) in checkTypeArray" :key="i">
              <el-link v-show="i!==checkTypeArray.length-1" :underline="false" type="primary" @click="breadcrumbClick(item.id, item.name)">{{ item.name }}</el-link>
              <span v-show="i===checkTypeArray.length-1">{{ item.name }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-table
          ref="tableList"
          v-loading="listLoading"
          :data="list"
          :height="tableHeight"
          border
          fit
          highlight-current-row
          style="width: 100%; overflow: auto !important;"
        >
          <el-table-column label="ID" align="center">
            <template slot-scope="{row}">
              <div>{{ row.id }}</div>
              <div>{{ row.category_name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="标题" align="center" min-width="180px">
            <template slot-scope="{row}">
              <div>{{ row.title }}</div>
            </template>
          </el-table-column>
          <el-table-column label="主图" align="center" width="180px">
            <template slot-scope="{row}">
              <el-image :src="row.photo" />
            </template>
          </el-table-column>
          <el-table-column label="价格" align="center" min-width="180px">
            <template slot-scope="{row}">
              <div>{{ row.price }}</div>
            </template>
          </el-table-column>
          <el-table-column label="推广链接" align="center" min-width="180px">
            <template slot-scope="{row}">
              <div>{{ row.type | typeOptionsFilters }}</div>
              <el-link :href="row.url" :underline="false" target="_blank">{{ row.url }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="{row,$index}">
              <el-dropdown :split-button="false">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="handleUpdate(row)">编辑</el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover
                      :ref="`popover` + $index"
                      placement="top"
                      :tabindex="$index"
                      style="display: block; width: 100%;"
                    >
                      <p>删除 `{{ row.title }}` 吗？</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="handleCancelVisible($index)">取消</el-button>
                        <el-button size="mini" type="danger" @click="handleThoroughDel(row.id, $index)">确定</el-button>
                      </div>
                      <span slot="reference" style="display: block; width: 100%;">删除</span>
                    </el-popover>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListData" />
      </el-main>
    </el-container>

    <!-- 表单 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="80px">
        <el-form-item label="分类" prop="category_name">
          <el-input v-model="temp.category_name" :disabled="true" />
        </el-form-item>
        <el-form-item label="分类ID" prop="category_id">
          <el-input v-model="temp.category_id" :disabled="true" />
        </el-form-item>
        <el-form-item label="联盟" prop="type">
          <common-select v-model="temp.type" :options="typeOptions" />
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="temp.title" maxlength="50" show-word-limit />
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="temp.price" type="number" :min="0" :max="9999" />
        </el-form-item>
        <el-form-item label="图片" prop="photo">
          <el-input v-model="temp.photo" show-word-limit />
        </el-form-item>
        <el-form-item label="推广链接" prop="url">
          <el-input
            v-model="temp.url"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
            maxlength="500"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { goodsAdminCategoryListApi, goodsAdminListGoodsApi, goodsAdminAddGoodsApi, goodsAdminUpdateGoodsApi, goodsAdminDeleteGoodsApi } from '@/api/niche-goods'
import { ellipsis, ellipsisBool } from '@/utils/validate'
import waves from '@/directive/waves' // waves directive
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import CommonSelect from '@/components/BaseData/CommonSelect'

const typeOptions = [
  { label: '亚马逊', value: 1 },
  { label: 'ebay', value: 2 },
  { label: '沃尔玛', value: 3 },
  { label: '速卖通', value: 4 },
  { label: '淘宝', value: 5 },
  { label: '京东', value: 6 }
]
const typeOptionsKeyValue = typeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.label
  return acc
}, {})

export default {
  name: 'Goods',
  components: { Pagination, CommonSelect },
  directives: { waves },
  filters: {
    hoverStr(str, len) {
      return !ellipsisBool(str, len)
    },
    hoverStrOmit(str, len) {
      return ellipsis(str, len)
    },
    typeOptionsFilters(v) {
      return typeOptionsKeyValue[v]
    }
  },
  data() {
    return {
      typeOptions,
      checkTypeArray: [{ name: '全部' }],
      typeTreeData: [{ id: 0, label: '全部' }],
      filterText: '',
      defaultData: [0, 0],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableHeight: 300,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        category_id: '',
        page: 1,
        limit: 20,
        id: this.$route.params.id
      },
      temp: { id: undefined, title: '', price: 0, url: '' },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: { update: '编辑', create: '创建' },
      rules: {
        title: [{ required: true, message: '必须填写', trigger: 'change' }],
        type: [{ required: true, message: '必须填写', trigger: 'change' }],
        url: [{ required: true, message: '必须填写', trigger: 'change' }]
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.typeTreeData.filter(val)
    }
  },
  mounted() {
    this.$nextTick(function() {
      const self = this
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 60
      // 监听窗口大小变化
      window.onresize = function() {
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 60
      }
    })
  },
  created() {
    this.getTreeData()
    this.getListData()
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    getTreeData() {
      goodsAdminCategoryListApi().then(response => {
        this.typeTreeData = response.data
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    nodeClick(obj, node, selfObj) {
      this.listQuery.id = null
      this.listQuery.category_id = obj.id
      this.listQuery.category_name = obj.label
      this.listQuery.is_deleted = ''
      const typeArray = [{ name: obj.name }]
      let parentData = node.parent
      while (parentData) {
        if (parentData.parent) {
          typeArray.unshift({ id: parentData.data.id, name: parentData.data.name })
        }
        parentData = parentData.parent
      }
      this.checkTypeArray = typeArray
      this.handleFilter()
    },
    breadcrumbClick(id, name) {
      this.listQuery.category_id = id
      this.listQuery.category_name = name
      this.listQuery.is_deleted = ''
      const typeArray = []
      for (const i in this.checkTypeArray) {
        if (id !== this.checkTypeArray[i].id) {
          typeArray.push(this.checkTypeArray[i])
        } else {
          typeArray.push({ name: name })
          break
        }
      }
      this.checkTypeArray = typeArray
      this.handleFilter()
    },
    getListData() {
      this.listLoading = true
      goodsAdminListGoodsApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      // this.listQuery.id = null
      this.listQuery.page = 1
      this.getListData()
    },
    resetTemp() {
      this.temp = { id: undefined, title: '', price: 0, url: '' }
    },
    handleCreate() {
      this.resetTemp()
      this.temp.category_id = this.listQuery.category_id
      this.temp.category_name = this.listQuery.category_name
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          goodsAdminAddGoodsApi(this.temp).then((response) => {
            this.handleFilter()
            this.dialogFormVisible = false
            this.$message({ message: response.message, type: 'success', duration: 1500 })
          })
        }
      })
    },
    handleUpdate(row) {
      this.resetTemp()
      this.temp = row
      this.temp.category_id = row.category_id
      this.temp.category_name = row.category_name
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          goodsAdminUpdateGoodsApi(tempData).then((response) => {
            this.handleFilter()
            this.dialogFormVisible = false
            this.$message({ message: response.message, type: 'success', duration: 1500 })
          })
        }
      })
    },
    handleCancelVisible(index) {
      this.$refs[`popover` + index].doClose()
    },
    handleThoroughDel(id, index) {
      this.$refs[`popover` + index].doClose()
      goodsAdminDeleteGoodsApi({ id: id }).then(response => {
        this.list.splice(index, 1)
        this.$notify({ message: response.message, type: 'success', duration: 1500 })
      })
    }
  }
}
</script>

<style scoped>
  .article-main-content {
    padding: 0 20px 0 8px !important;
  }
  .manager-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .manager-container .pagination-container {
    padding: 10px 0;
  }
  .tab-key-desc span {
    margin-right: 20px;
  }
</style>


<template>
  <div class="app-container">
    <el-container class="data-manager-container">
      <el-aside width="240px" style="overflow-y: hidden">
        <div class="top-container">
          <el-button icon="el-icon-circle-plus" style="width: 100%; margin-bottom: 10px;" @click="handleCreate">添加数据库</el-button>
        </div>
        <div class="top-container">
          <database-tree-list-menu :height="(tableHeight + 100) + `px`" :refresh="refreshTree" @nodeClick="nodeClick" @treeData="treeData" />
        </div>
      </el-aside>

      <el-main class="main-tabs-content">
        <div class="database-info">
          <el-descriptions title="数据库信息">
            <el-descriptions-item label="数据库名">{{ nowDatabase.database_name }} <el-tag v-show="nowDatabase.is_system===1" size="small" style="margin-left: 6px">系统</el-tag></el-descriptions-item>
            <el-descriptions-item label="名称">{{ nowDatabase.name }}</el-descriptions-item>
            <el-descriptions-item label="字符集">{{ nowDatabase.charset }}</el-descriptions-item>
          </el-descriptions>
          <div v-show="nowDatabase.is_system!==1">
            <el-button size="mini" type="primary" style="margin-right: 12px" @click="handleUpdate(nowDatabase)">编辑</el-button>
            <el-popover
              v-show="total===0"
              ref="popoverNowDatabase"
              placement="top"
              width="240"
            >
              <p>删除数据库 `{{ nowDatabase.database_name }}` 吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="handleCancelVisible()">取消</el-button>
                <el-button size="mini" type="danger" @click="handleDel(nowDatabase.id, nowDatabase.name)">确定</el-button>
              </div>
              <el-button slot="reference" size="mini">删除</el-button>
            </el-popover>
          </div>
        </div>

        <el-row :gutter="8">
          <el-col :span="4">
            <el-input v-model="listQuery.table_name" placeholder="表名" clearable @keyup.enter.native="handleFilter" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="listQuery.name" placeholder="注释" clearable @keyup.enter.native="handleFilter" />
          </el-col>

          <el-col :span="16">
            <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
            <el-button style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreateTable">添加表</el-button>
          </el-col>
        </el-row>
        <el-table
          ref="tableList"
          v-loading="listLoading"
          :data="list"
          :height="tableHeight"
          fit
          highlight-current-row
          style="width: 100%; overflow: auto !important;"
        >
          <el-table-column label="表名" align="center">
            <template slot-scope="{row}">
              <div>{{ row.table_name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="注释" align="center">
            <template slot-scope="{row}">
              <div>{{ row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="字符集" align="center">
            <template slot-scope="{row}">
              <div>{{ row.charset }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{row, $index}">
              <div v-show="row.is_system!==1">
                <el-dropdown :split-button="false">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down el-icon--right" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="handleUpdateTable(row, 0)">编辑</el-dropdown-item>
                    <el-dropdown-item>
                      <el-popover
                        :ref="`popover` + $index"
                        placement="top"
                        :tabindex="$index"
                        style="display: block; width: 100%;"
                      >
                        <p>删除数据表 `{{ row.table_name }}` 吗？</p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text" @click="handleCancelTableVisible($index)">取消</el-button>
                          <el-button size="mini" type="danger" @click="handleDelTable(row.id, $index)">确定</el-button>
                        </div>
                        <span slot="reference" style="display: block; width: 100%;">删除</span>
                      </el-popover>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="total>listQuery.limit" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListTableData" />
      </el-main>
    </el-container>

    <!--添加|编辑 - 数据库-->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px">
        <el-form-item label="数据库名称" prop="database_name">
          <el-input v-model="temp.database_name" maxlength="50" :disabled="dialogStatus!=='create'" show-word-limit />
          <span>命名规则：由小写字母、数字、下划线_，组成，且小写字母开头</span>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="20" show-word-limit />
        </el-form-item>
        <el-form-item label="字符集" prop="charset">
          <my-s-q-l-select v-model="temp.charset" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          确认
        </el-button>
      </div>
    </el-dialog>

    <!--添加 - 数据表-->
    <el-dialog :title="`创建表`" :visible.sync="dialogTableFormVisible">
      <el-form ref="dataTableForm" :rules="tableRules" :model="temp" label-position="right" label-width="100px">
        <el-form-item label="数据表名称" prop="table_name">
          <el-input v-model="temp.table_name" maxlength="50" show-word-limit />
          <span>命名规则：由小写字母、数字、下划线_，组成，且小写字母开头</span>
        </el-form-item>
        <el-form-item label="注释" prop="name">
          <el-input v-model="temp.name" maxlength="20" show-word-limit />
        </el-form-item>
        <el-form-item label="字符集" prop="charset">
          <my-s-q-l-select v-model="temp.charset" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="createTableData()">
          确认
        </el-button>
      </div>
    </el-dialog>
    <!--编辑 - 数据表-->
    <el-drawer
      :title="temp.name"
      :visible.sync="drawerVisible"
      direction="rtl"
      :show-close="false"
      size="80%"
    >
      <div class="drawer-content">
        <div class="drawer-form">
          <el-form ref="dataTableForm" :rules="tableRules" :model="temp" label-position="right" label-width="90px">
            <el-form-item label="数据表名" prop="table_name">
              <el-input v-model="temp.table_name" maxlength="50" show-word-limit />
            </el-form-item>
            <el-form-item label="注释" prop="name">
              <el-input v-model="temp.name" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item label="字符集" prop="charset">
              <my-s-q-l-select v-model="temp.charset" />
            </el-form-item>
          </el-form>

          <el-tabs v-model="tabsName" type="border-card">
            <el-tab-pane label="字段配置" name="tabs1" class="table-field-data">
              <div>
                <el-link icon="el-icon-circle-plus-outline" :underline="false" style="margin-right: 10px; color: green" @click="handleFieldRowAdd">添加字段</el-link>
                <el-link icon="el-icon-s-unfold" :underline="false" style="margin-right: 10px; color: blue" @click="handleFieldRowInsert">插入字段</el-link>
                <el-link v-show="fieldOtherData.index>0" :underline="false" style="margin-right: 10px" @click="handleFieldRowCopy">复制字段</el-link>
                <el-link v-show="fieldOtherData.index>0" icon="el-icon-remove-outline" :underline="false" style="margin-right: 10px; color: red;" @click="handleFieldRowDelete">删除字段</el-link>
                <el-link v-show="fieldOtherData.index>1" icon="el-icon-top" :underline="false" style="margin-right: 10px" @click="handleFieldRowUp">上移</el-link>
                <el-link v-show="fieldOtherData.index>0" icon="el-icon-bottom" :underline="false" style="margin-right: 10px" @click="handleFieldRowDown">下移</el-link>
              </div>
              <el-divider />
              <el-table
                ref="tableFieldDataList"
                v-loading="listFieldLoading"
                :data="tableFieldData"
                :height="tableHeight-24"
                fit
                highlight-current-row
                cell-class-name="no-padding-table-cell"
                style="overflow: auto !important;"
                :row-class-name="tableRowClassName"
                @row-click="handleFieldRowClick"
              >
                <el-table-column label="名" align="center">
                  <template slot-scope="{row, $index}">
                    <el-input :ref="`fieldRowName` + $index" v-model="row.name" :disabled="$index===0" maxlength="50" @change="(value) => {handleInputFieldRowName(value, row)}" />
                    <div v-show="row.name === ''" style="color: red;">名称不能为空</div>
                  </template>
                </el-table-column>
                <el-table-column label="类型" align="center" width="90px">
                  <template slot-scope="{row, $index}">
                    <my-s-q-l-type-select v-model="row.type" :disabled="$index===0" />
                  </template>
                </el-table-column>
                <el-table-column label="长度" align="center" width="60px">
                  <template slot-scope="{row, $index}">
                    <el-input v-model="row.length" :disabled="$index===0" type="number" :min="0" :max="10000" class="no-padding" />
                  </template>
                </el-table-column>
                <el-table-column label="小数点" align="center" width="50px">
                  <template slot-scope="{row, $index}">
                    <el-input v-model="row.point" :disabled="$index===0" type="number" :min="0" :max="10" />
                  </template>
                </el-table-column>
                <el-table-column label="不是NULL" align="center" width="60px">
                  <template slot-scope="{row, $index}">
                    <el-checkbox v-model="row.is_not_null" :disabled="$index===0" />
                  </template>
                </el-table-column>
                <el-table-column label="主键" align="center" width="30px">
                  <template slot-scope="{row, $index}">
                    <el-checkbox v-model="row.is_primary_key" :disabled="$index===0" />
                  </template>
                </el-table-column>
                <el-table-column label="注释" align="center">
                  <template slot-scope="{row, $index}">
                    <el-input v-model="row.comment" :disabled="$index===0" maxlength="20" />
                  </template>
                </el-table-column>
              </el-table>
              <el-form :model="fieldOtherData" label-position="right" label-width="90px" class="field-other-data">
                <el-form-item label="默认" prop="default_value">
                  <el-autocomplete
                    v-model="fieldOtherData.default_value"
                    :disabled="fieldOtherData.type>=50"
                    :fetch-suggestions="(queryString, callback) => {querySearch(queryString, callback, fieldOtherData.type, fieldOtherData.is_not_null)}"
                    trigger-on-focus
                  />
                </el-form-item>
                <el-form-item v-show="fieldOtherData.type>=40" label="字符集" prop="charset">
                  <my-s-q-l-select v-model="fieldOtherData.charset" />
                </el-form-item>
                <el-form-item v-show="fieldOtherData.type<30" label="无符号" prop="is_unsigned">
                  <el-checkbox v-model="fieldOtherData.is_unsigned" />
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="索引配置" name="tabs2" class="table-field-data">
              <div>
                <el-link icon="el-icon-circle-plus-outline" :underline="false" style="margin-right: 10px; color: green" @click="handleFieldIndexRowAdd">添加索引</el-link>
                <el-link icon="el-icon-remove-outline" :underline="false" style="margin-right: 10px; color: red;" @click="handleFieldIndexRowDelete">删除索引</el-link>
              </div>
              <el-divider />
              <el-table
                ref="tableIndexDataList"
                v-loading="listLoading"
                :data="tableIndexData"
                :height="tableHeight+48"
                fit
                highlight-current-row
                cell-class-name="no-padding-table-cell"
                style="overflow: auto !important;"
                :row-class-name="tableRowClassName"
                @row-click="handleFieldIndexRowClick"
              >
                <el-table-column label="名" align="center">
                  <template slot-scope="{row, $index}">
                    <el-input :ref="`fieldIndexRowName` + $index" v-model="row.name" maxlength="100" />
                    <div v-show="row.name === ''" style="color: red;">名称不能为空</div>
                  </template>
                </el-table-column>
                <el-table-column label="字段" align="center">
                  <template slot-scope="{row, $index}">
                    <el-input :ref="`fieldIndexRowField` + $index" v-model="row.field" maxlength="100" />
                    <div v-show="row.field === ''" style="color: red;">字段不能为空</div>
                  </template>
                </el-table-column>
                <el-table-column label="索引类型" align="center" width="150px">
                  <template slot-scope="{row}">
                    <my-s-q-l-index-type-select v-model="row.type" />
                  </template>
                </el-table-column>
                <el-table-column label="注释" align="center">
                  <template slot-scope="{row}">
                    <el-input v-model="row.comment" maxlength="20" />
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>

        <div class="drawer-footer">
          <el-button @click="drawerVisible = false">取消</el-button>
          <el-button type="primary" @click="updateTableData">确认</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { addDatabaseApi, updateDatabaseApi, deleteDatabaseApi, tableListApi, addTableApi, detailTableApi, updateTableApi, deleteTableApi } from '@/api/config-center'
import Pagination from '@/components/Pagination'
import DatabaseTreeListMenu from '@/components/ConfigCenter/Database/TreeListMenu'
import MySQLSelect from '@/components/BaseData/MySQL/Select'
import MySQLTypeSelect from '@/components/BaseData/MySQL/TypeSelect'
import MySQLIndexTypeSelect from '@/components/BaseData/MySQL/IndexTypeSelect'

export default {
  name: 'DataSource',
  components: { Pagination, DatabaseTreeListMenu, MySQLSelect, MySQLTypeSelect, MySQLIndexTypeSelect },
  data() {
    return {
      nowDatabase: { id: '', name: '', database_name: '', charset: '', is_system: 0 },
      refreshTree: false,
      tableHeight: 300,
      listLoading: false,
      list: null,
      listQuery: { database_id: 1, page: 1, limit: 20, name: '' },
      total: 0,
      temp: { id: undefined, name: '' },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: { update: '编辑', create: '创建' },
      rules: {
        name: [{ required: true, message: '名称必须填写', trigger: 'change' }],
        database_name: [
          { required: true, message: '数据库名必须填写', trigger: 'change' },
          { pattern: /^[a-z]+[a-z0-9_]*$/, message: '命名规则错误', trigger: 'blur' }
        ]
      },
      dialogTableFormVisible: false,
      tableRules: {
        name: [{ required: true, message: '注释必须填写', trigger: 'change' }],
        table_name: [
          { required: true, message: '数据表名必须填写', trigger: 'change' },
          { pattern: /^[a-z]+[a-z0-9_]*$/, message: '命名规则错误', trigger: 'blur' }
        ]
      },
      tabsName: 'tabs1',
      listFieldLoading: false,
      drawerVisible: false,
      tableData: { id: null, name: '' },
      tableFieldData: [],
      tableIndexData: [],
      fieldOtherData: { is_primary_key: false, type: 10, default_value: '', is_unsigned: false, charset: '' },
      indexOtherData: { name: '', field: '', index: -1 }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const self = this
      let pageHeight = 0
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 220 - pageHeight
      // 监听窗口大小变化
      window.onresize = function() {
        if (self.total > self.listQuery.limit) {
          pageHeight = 56
        }
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 220 - pageHeight
      }
    })
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    treeData(data) {
      this.refreshTree = false

      if (this.nowDatabase.name === '') {
        this.nowDatabase = data[0]
      }

      if (this.list === null) {
        this.listQuery.database_id = data[0].id
        this.getListTableData()
      }
    },
    refreshTreeData() {
      // 更新子组件的数据
      this.refreshTree = true
    },
    nodeClick(obj, node, selfObj) {
      // 重置分页数据
      this.listQuery = { page: 1, limit: 20, name: '' }
      this.nowDatabase = obj
      this.listQuery.database_id = obj.id
      this.getListTableData()
    },
    resetTemp() {
      this.temp = { id: undefined, name: '' }
    },
    handleCreate() {
      this.resetTemp()
      this.temp.charset = 'utf8mb4'
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          addDatabaseApi(this.temp).then((response) => {
            this.refreshTreeData()
            this.temp = response.data
            this.dialogFormVisible = false
            this.$message({ message: response.message, type: 'success', duration: 1500 })
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          updateDatabaseApi(tempData).then((response) => {
            this.nowDatabase = response.data
            this.refreshTreeData()
            this.temp = response.data
            this.dialogFormVisible = false
            this.$message({ message: response.message, type: 'success', duration: 1500 })
          })
        }
      })
    },
    handleCancelVisible() {
      this.$refs['popoverNowDatabase'].doClose()
    },
    handleDel(id, name) {
      this.$refs['popoverNowDatabase'].doClose()
      deleteDatabaseApi({ id: id }).then(response => {
        this.nowDatabase.name = ''
        this.list = null
        this.refreshTreeData()
        this.$message({ message: response.message, type: 'success' })
      })
    },
    getListTableData() {
      this.listLoading = true
      tableListApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total
        if (this.total > this.listQuery.limit) {
          this.tableHeight = window.innerHeight - this.$refs.tableList.$el.offsetTop - 64 - 240 - 56
        }
        // Just to simulate the time of the request
        setTimeout(() => { this.listLoading = false }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.getListTableData()
    },
    handleCreateTable() {
      this.resetTemp()
      this.temp.charset = this.nowDatabase.charset
      this.temp.database_id = this.nowDatabase.id
      this.dialogTableFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataTableForm'].clearValidate()
      })
    },
    createTableData() {
      this.$refs['dataTableForm'].validate((valid) => {
        if (valid) {
          addTableApi(this.temp).then((response) => {
            this.getListTableData()
            this.handleUpdateTable(response.data, 1)
            this.temp = response.data
            this.dialogTableFormVisible = false
          })
        }
      })
    },
    handleUpdateTable(row, isNew) {
      this.temp = Object.assign({}, row) // copy obj
      this.tabsName = 'tabs1'
      this.listFieldLoading = true
      if (isNew === 1) {
        this.tableData = this.temp
        this.drawerVisible = true
      }
      detailTableApi({ id: row.id }).then((response) => {
        this.tableFieldData = response.data.tableFieldData
        this.fieldOtherData = this.tableFieldData[0]
        this.tableIndexData = response.data.tableIndexData
        if (this.tableIndexData.length > 0) {
          this.indexOtherData = this.tableIndexData[0]
        }
        this.listFieldLoading = false
        this.drawerVisible = true
        this.$nextTick(() => {
          this.$refs['dataTableForm'].clearValidate()
        })
      })
    },
    querySearch(queryString, callback, type, is_not_null) {
      let defaultValueOptions = [{ value: 'EMPTY STRING' }, { value: 'NULL' }]
      // 调用 callback 返回建议列表的数据
      if (type < 30) {
        defaultValueOptions = [{ value: 'NULL' }]
      }
      if (type >= 50) {
        defaultValueOptions = []
      }
      callback(defaultValueOptions)
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
      row.sort = rowIndex
    },
    handleFieldRowClick(row, column, event) {
      this.fieldOtherData = row
    },
    handleFieldIndexRowClick(row, column, event) {
      this.indexOtherData = row
    },
    // 添加字段
    handleFieldRowAdd() {
      const row = {
        name: '', table_id: this.tableData.id, type: 41, length: 255, point: 0, is_not_null: true, is_primary_key: false, comment: '',
        default_value: '', is_unsigned: false, charset: '', after: this.tableFieldData[this.tableFieldData.length - 1].name,
        sort: this.tableFieldData.length, index: this.tableFieldData.length, change: true
      }
      this.fieldOtherData = row
      this.tableFieldData.push(row)

      this.$nextTick(() => { this.$refs['fieldRowName' + row.index].focus() })
    },
    // 插入字段
    handleFieldRowInsert() {
      const nowSort = this.fieldOtherData.sort + 1
      const row = {
        name: '', table_id: this.tableData.id, type: 41, length: 255, point: 0, is_not_null: true, is_primary_key: false, comment: '',
        default_value: '', is_unsigned: false, charset: '', after: this.tableFieldData[nowSort - 1].name,
        sort: nowSort, index: nowSort, change: true
      }
      this.fieldOtherData = row
      this.tableFieldData.splice(nowSort, 0, row)

      this.$nextTick(() => { this.$refs['fieldRowName' + row.index].focus() })
    },
    // 复制字段
    handleFieldRowCopy() {
      if (this.fieldOtherData.sort < 1) {
        this.$message({ type: 'warning', message: '主键ID字段，不能复制' })
      } else {
        const nowSort = this.fieldOtherData.sort + 1
        const row = JSON.parse(JSON.stringify(this.fieldOtherData))
        delete row.id
        row.change = true
        row.after = row.name
        row.sort++
        row.index++
        this.fieldOtherData = row
        this.tableFieldData.splice(nowSort, 0, row)
        this.$nextTick(() => {
          this.$refs['fieldRowName' + row.index].focus()
        })
      }
    },
    // 删除字段
    handleFieldRowDelete() {
      if (this.fieldOtherData.sort < 1) {
        this.$message({ type: 'warning', message: '主键ID字段，不能删除' })
      } else {
        const nowSort = this.fieldOtherData.sort - 1
        this.fieldOtherData = this.tableFieldData[nowSort]
        this.tableFieldData.splice(nowSort + 1, 1)

        this.$nextTick(() => { this.$refs['fieldRowName' + nowSort].focus() })
      }
    },
    // 字段上移
    handleFieldRowUp() {
      if (this.fieldOtherData.sort < 2) {
        this.$message({ type: 'warning', message: '不能上移到主键ID字段前' })
      } else {
        const nowSort = this.fieldOtherData.sort - 1
        this.swapArray(this.tableFieldData, nowSort + 1, nowSort)
        this.tableFieldData[nowSort].sort = nowSort
        this.tableFieldData[nowSort].index = nowSort
        this.tableFieldData[nowSort].after = this.tableFieldData[nowSort - 1].name
        this.tableFieldData[nowSort + 1].sort = nowSort + 1
        this.tableFieldData[nowSort + 1].index = nowSort + 1
        this.tableFieldData.splice(nowSort, 0)
        this.$nextTick(() => { this.$refs['fieldRowName' + nowSort].focus() })
      }
    },
    // 字段下移
    handleFieldRowDown() {
      if (this.fieldOtherData.sort < 1) {
        this.$message({ type: 'warning', message: '主键ID字段，不能下移' })
      } else {
        if (this.fieldOtherData.sort < this.tableFieldData.length - 1) {
          const nowSort = this.fieldOtherData.sort + 1
          this.swapArray(this.tableFieldData, nowSort - 1, nowSort)
          this.tableFieldData[nowSort].sort = nowSort
          this.tableFieldData[nowSort].index = nowSort
          this.tableFieldData[nowSort].after = this.tableFieldData[nowSort - 1].name
          this.tableFieldData[nowSort - 1].sort = nowSort - 1
          this.tableFieldData[nowSort - 1].index = nowSort - 1
          this.tableFieldData.splice(nowSort, 0)
          this.$nextTick(() => { this.$refs['fieldRowName' + nowSort].focus() })
        }
      }
    },
    // 数组元素交换
    swapArray(arr, i, j) {
      [arr[i], arr[j]] = [arr[j], arr[i]]
    },
    // 修改字段名称
    handleInputFieldRowName(value, row) {
      row.change = true
      row.after = this.tableFieldData[row.index - 1].name

      if (this.tableFieldData.length > row.index + 1 && this.tableFieldData[row.index + 1].change) {
        this.tableFieldData[row.index + 1].after = value
      }
    },
    // 添加索引
    handleFieldIndexRowAdd() {
      const row = {
        name: '', table_id: this.tableData.id, field: '', type: 10, comment: '', index: this.indexOtherData + 1
      }
      this.indexOtherData = row
      this.tableIndexData.push(row)

      this.$nextTick(() => { this.$refs['fieldIndexRowName' + row.index].focus() })
    },
    // 删除索引
    handleFieldIndexRowDelete() {
      const nowIndex = this.indexOtherData.index - 1
      this.tableIndexData.splice(nowIndex + 1, 1)
      if (nowIndex >= 0) {
        this.indexOtherData = this.tableFieldData[nowIndex]
        this.$nextTick(() => { this.$refs['fieldIndexRowName' + nowIndex].focus() })
      } else {
        this.indexOtherData = {}
      }
    },
    updateTableData() {
      this.$refs['dataTableForm'].validate((valid) => {
        if (valid) {
          const tableFieldData = JSON.stringify(this.tableFieldData)
          const tableIndexData = JSON.stringify(this.tableIndexData)
          const tempData = Object.assign({}, this.temp)
          tempData.tableFieldData = tableFieldData
          tempData.tableIndexData = tableIndexData
          updateTableApi(tempData).then((response) => {
            this.getListTableData()
            this.drawerVisible = false
            this.$message({ message: response.message, type: 'success', duration: 1500 })
          })
        }
      })
    },
    handleCancelTableVisible(index) {
      this.$refs[`popover` + index].doClose()
    },
    handleDelTable(id, index) {
      this.$refs[`popover` + index].doClose()
      deleteTableApi({ id: id }).then(response => {
        this.getListTableData()
        this.$message({ message: response.message, type: 'success' })
      })
    }
  }
}
</script>

<style>
  .app-container {
    padding-bottom: 0 !important;
  }
  .main-tabs-content {
    padding: 0 20px 0 8px !important;
  }
  .data-manager-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .database-info {
    margin-bottom: 10px;
    height: 100px;
  }
  .drawer-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px 0 20px;
  }
  .drawer-content .drawer-form {
    flex: 1;
  }
  .drawer-footer {
    text-align: center;
    display: flex;
    padding: 10px 0 10px 0;
    margin-left: 20%;
    margin-right: 20%;
  }
  .drawer-footer button {
    flex: 1;
  }
  .table-field-data .el-table .cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .table-field-data .el-input__inner {
    padding-left: 6px !important;
    padding-right: 0 !important;
  }
  .table-field-data .el-divider {
    margin: 10px 0 !important;
  }
  .field-other-data {
    margin-top: 10px;
  }
  .field-other-data .el-form-item--small.el-form-item {
    margin-bottom: 0 !important;
  }
  .field-other-data .el-form-item {
    margin-bottom: 0 !important;
  }
</style>

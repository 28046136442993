<template>
  <div>
    <el-select
      ref="selectRef"
      v-model="sValue"
      multiple
      clearable
      placeholder="请选择"
      value-key="id"
      style="width: 100%;"
      @remove-tag="removeTagData"
      @clear="handleClear"
    >
      <el-option
        v-for="item in sValue"
        :key="item.id"
        :label="item.name"
        :value="item"
        hidden
      />
      <el-option
        :value="sValue"
        style="height: auto; padding: 0"
      >
        <el-tree
          ref="treeDataCheck"
          :data="treeData"
          :props="defaultProps"
          show-checkbox
          check-strictly
          node-key="id"
          :default-expanded-keys="defaultExpandedData"
          :default-checked-keys="checkedId"
          @check="checkData"
        />
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { organizationTreeMenuApi } from '@/api/user-center'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sValue: [], // 选中的数据 [{ id: 1, name: '' }, {}]
      checkedId: [], // 选中的id
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      defaultExpandedData: [1]
    }
  },
  watch: {
    value: {
      immediate: true, // 第一次加载就初始化数据
      handler(val) {
        this.checkedId = []
        for (const i in val) {
          this.checkedId.push(val[i].id)
        }
        this.sValue = val
        this.$nextTick(() => {
          this.$refs['treeDataCheck'].setCheckedKeys(this.checkedId)
        })
      }
    }
  },
  created() {
    this.getTree()
  },
  methods: {
    handleClear() {
      // 将选择器的值置空
      this.sValue = []
      this.checkedId = []
      this.$refs['treeDataCheck'].setCheckedKeys(this.checkedId)
      this.$emit('input', [])
    },
    getTree() {
      organizationTreeMenuApi().then(response => {
        this.treeData = response.data
        this.defaultExpandedData.push(response.data[0].id)
      })
    },
    checkData(data, checked) {
      this.sValue = checked.checkedNodes
      this.checkedId = checked.checkedKeys
      this.$emit('input', this.sValue)
    },
    removeTagData(tagObj) {
      this.checkedId = []
      for (const i in this.sValue) {
        if (tagObj.id === this.sValue[i].id) {
          this.sValue.splice(i, 1)
          continue
        }
        this.checkedId.push(this.sValue[i].id)
      }

      this.$refs['treeDataCheck'].setCheckedKeys(this.checkedId)
      this.$emit('input', this.sValue)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="app-container">
    <el-container class="redis-manager-container">
      <el-aside width="240px" style="overflow-y: hidden">
        <div class="top-container">
          <el-button icon="el-icon-circle-plus" style="width: 100%; margin-bottom: 10px;" @click="handleCreate">添加组织</el-button>
        </div>
        <div class="top-container">
          <organization-tree-list-menu :height="(tableHeight + 100) + `px`" :refresh="refreshTree" @nodeClick="nodeClick" @treeData="treeData" />
        </div>
        <div class="bottom-container">
          <el-button icon="el-icon-circle-plus" style="width: 240px ; margin-bottom: 10px ; position: fixed; bottom: 0;" @click="handleCreateOne">添加一级组织</el-button>
        </div>
      </el-aside>

      <el-main class="main-tabs-content">
        <div class="organization-info">
          <el-descriptions title="组织信息">
            <el-descriptions-item label="名称">{{ nowOrganization.name }}<el-tag v-show="nowOrganization.is_system===1" size="small" style="margin-left: 6px">系统</el-tag></el-descriptions-item>
            <el-descriptions-item label="code">{{ nowOrganization.code }}</el-descriptions-item>
          </el-descriptions>
          <div v-show="nowOrganization.is_system!==1">
            <el-button size="mini" type="primary" style="margin-right: 12px" @click="handleUpdate(nowOrganization)">编辑</el-button>
            <el-popover
              v-show="!nowOrganization.children"
              ref="popoverNowOrganization"
              placement="top"
              width="240"
            >
              <p>删除{{ nowOrganization.name }} 吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="handleCancelVisible()">取消</el-button>
                <el-button size="mini" type="danger" @click="handleDel(nowOrganization.id, nowOrganization.name)">确定</el-button>
              </div>
              <el-button slot="reference" size="mini">删除</el-button>
            </el-popover>
          </div>
        </div>

        <el-tabs v-model="nowTabs" type="border-card" @tab-click="changeTags">
          <el-tab-pane label="角色配置" name="tabs1">
            <div class="tabs-header-content"><el-button icon="el-icon-circle-plus" style="margin-bottom: 10px;" @click="detailRoleData">配置角色</el-button></div>
            <el-table
              ref="tableList"
              v-loading="listLoading"
              :data="list"
              :height="tableHeight"
              fit
              highlight-current-row
              style="width: 100%; overflow: auto !important;"
            >
              <el-table-column label="名称" align="center">
                <template slot-scope="{row}">
                  <div>{{ row.name }}</div>
                </template>
              </el-table-column>
              <el-table-column label="编码" align="center">
                <template slot-scope="{row}">
                  <div>{{ row.code }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="{row, $index}">
                  <div v-show="row.is_system!==1">
                    <el-popover
                      :ref="`popover` + $index"
                      placement="top"
                      width="240"
                      :tabindex="$index"
                    >
                      <p>移除 {{ row.name }} 吗？</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="handleCancelRoleVisible($index)">取消</el-button>
                        <el-button size="mini" type="danger" @click="handleDelRole(row.id, $index)">确定</el-button>
                      </div>
                      <el-button slot="reference" size="mini">移除</el-button>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <pagination v-show="total>listQuery.limit" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getListRoleData" />
          </el-tab-pane>
          <el-tab-pane label="身份管理" name="tabs2">
            <div class="tabs-header-content"><el-button icon="el-icon-circle-plus" style="margin-bottom: 10px;" @click="handleCreateIdentity">添加身份</el-button> <i class="el-icon-question" />身份角色默认拥有组织角色权限</div>
            <el-table
              ref="tableList"
              v-loading="listLoading"
              :data="identityList"
              :height="tableHeight"
              fit
              highlight-current-row
              style="width: 100%; overflow: auto !important;"
            >
              <el-table-column type="expand">
                <template slot-scope="prow">
                  <el-table
                    :data="prow.row.children"
                    fit
                    highlight-current-row
                  >
                    <el-table-column label="角色" align="center">
                      <template slot-scope="cData">
                        <div>{{ cData.row.name }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="编码" align="center">
                      <template slot-scope="cData">
                        <div>{{ cData.row.code }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                      <template slot-scope="{row, $index}">
                        <el-popover
                          :ref="`identityRolePopover` + row.id"
                          placement="top"
                          width="240"
                          :tabindex="$index"
                        >
                          <p>移除角色 '{{ row.name }}' 吗？</p>
                          <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text" @click="handleCancelIdentityRoleVisible(row.id)">取消</el-button>
                            <el-button size="mini" type="danger" @click="handleDelIdentityRole(row.id, $index, prow.$index)">确定</el-button>
                          </div>
                          <el-button slot="reference" size="mini">移除</el-button>
                        </el-popover>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column label="名称" align="center">
                <template slot-scope="{row}">
                  <div>{{ row.name }}</div>
                </template>
              </el-table-column>
              <el-table-column label="编码" align="center">
                <template slot-scope="{row}">
                  <div>{{ row.code }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="{row, $index}">
                  <div>
                    <el-button size="mini" style="margin-right: 10px" @click="detailIdentityRoleData(row)">配置角色</el-button>
                    <el-popover
                      :ref="`identityPopover` + $index"
                      placement="top"
                      width="240"
                      :tabindex="$index"
                    >
                      <p>删除身份 '{{ row.name }}' 吗？</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="handleCancelIdentityVisible($index)">取消</el-button>
                        <el-button size="mini" type="danger" @click="handleDelIdentity(row.id, $index)">确定</el-button>
                      </div>
                      <el-button slot="reference" type="danger" size="mini">删除</el-button>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <pagination v-show="identityTotal>identityListQuery.limit" :total="identityTotal" :page.sync="identityListQuery.page" :limit.sync="identityListQuery.limit" @pagination="getListRoleData" />
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px">
        <el-form-item v-show="!addParent&&dialogStatus==='create'" label="上级" prop="p_name">
          <el-input v-model="nowOrganization.name" :disabled="true" />
        </el-form-item>
        <el-form-item v-show="addParent" label="上级" prop="p_name">
          <el-input value="全部" :disabled="true" />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="temp.name" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="temp.code" maxlength="8" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          确认
        </el-button>
      </div>
    </el-dialog>

    <!--配置组织角色-->
    <el-drawer
      :title="nowOrganization.name + ` - 配置角色`"
      :visible.sync="drawerVisible"
      direction="rtl"
      :show-close="false"
      size="60%"
    >
      <div class="drawer-content">
        <el-table
          ref="multipleTable"
          :data="roleList"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="角色">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="Code">
            <template slot-scope="scope">{{ scope.row.code }}</template>
          </el-table-column>
        </el-table>

        <pagination v-show="roleTotal>roleListQuery.limit" :total="roleTotal" :page.sync="roleListQuery.page" :limit.sync="roleListQuery.limit" @pagination="detailRoleData" />

        <div class="drawer-footer">
          <el-button size="medium" @click="drawerVisible = false">取消</el-button>
          <el-button size="medium" type="primary" @click="updateRoleData">确认</el-button>
        </div>
      </div>
    </el-drawer>

    <!--添加身份-->
    <el-dialog title="添加身份" :visible.sync="dialogIdentityFormVisible">
      <el-form ref="identityDataForm" :rules="rules" :model="identityTemp" label-position="right" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="identityTemp.name" maxlength="32" show-word-limit />
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="identityTemp.code" maxlength="8" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogIdentityFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="createIdentityData">
          确认
        </el-button>
      </div>
    </el-dialog>
    <!--配置身份角色-->
    <el-drawer
      :title="nowIdentity.name + ` - 配置角色`"
      :visible.sync="drawerIdentityVisible"
      direction="rtl"
      :show-close="false"
      size="60%"
    >
      <div class="drawer-content">
        <el-form :rules="rules" :model="nowIdentity" label-position="right" label-width="60px" style="padding: 0 20px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="nowIdentity.name" maxlength="32" show-word-limit />
          </el-form-item>
          <el-form-item label="编码" prop="code">
            <el-input v-model="nowIdentity.code" maxlength="8" show-word-limit />
          </el-form-item>
        </el-form>
        <el-table
          ref="multipleIdentityTable"
          :data="roleList"
          tooltip-effect="dark"
          @selection-change="handleSelectionIdentityChange"
        >
          <el-table-column type="selection" :selectable="identitySelectable" width="55" />
          <el-table-column label="角色">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="Code">
            <template slot-scope="scope">{{ scope.row.code }}</template>
          </el-table-column>
        </el-table>

        <pagination v-show="roleTotal>roleListQuery.limit" :total="roleTotal" :page.sync="roleListQuery.page" :limit.sync="roleListQuery.limit" @pagination="detailIdentityRoleData(nowIdentity)" />

        <div class="drawer-footer">
          <el-button size="medium" @click="drawerIdentityVisible = false">取消</el-button>
          <el-button size="medium" type="primary" @click="updateIdentityRoleData">确认</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { organizationAddApi, organizationUpdateApi, organizationDeleteApi, organizationListRoleApi, organizationDestroyRoleApi, organizationDetailRoleApi, organizationAddRoleApi, organizationListIdentityApi, organizationAddIdentityApi, organizationDeleteIdentityApi, organizationRemoveIdentityRoleApi, organizationDetailIdentityApi, organizationUpdateIdentityApi } from '@/api/user-center'
import Pagination from '@/components/Pagination'
import OrganizationTreeListMenu from '@/components/UserCenter/Organization/TreeListMenu'

export default {
  name: 'UserOrganization',
  components: { Pagination, OrganizationTreeListMenu },
  data() {
    return {
      nowOrganization: { id: '', name: '', code: '', is_system: 0 },
      refreshTree: false,
      tableHeight: 300,
      listLoading: false,
      list: null,
      listQuery: {
        organization_id: 1,
        page: 1,
        limit: 20,
        name: ''
      },
      total: 0,
      temp: {
        id: undefined,
        parent_id: undefined,
        name: '',
        code: ''
      },
      addParent: false,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{ required: true, message: '名称必须填写', trigger: 'change' }]
      },
      drawerVisible: false,
      roleListQuery: {
        organization_id: 1,
        page: 1,
        limit: 20,
        name: ''
      },
      roleTotal: 0,
      roleList: [],
      checkedAllRoleId: [], // 翻了的有效页面
      checkedRoleId: [], // 选中了的角色ID数据
      isComplete: false,
      nowTabs: 'tabs1',
      identityList: null,
      identityListQuery: {
        organization_id: 1,
        page: 1,
        limit: 20,
        name: ''
      },
      identityTotal: 0,
      dialogIdentityFormVisible: false,
      drawerIdentityVisible: false,
      identityTemp: {
        id: undefined,
        name: '',
        code: ''
      },
      nowIdentity: { id: 0, name: '', code: '' }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const self = this
      let pageHeight = 0
      self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 240 - pageHeight
      // 监听窗口大小变化
      window.onresize = function() {
        if (self.total > self.listQuery.limit) {
          pageHeight = 56
        }
        self.tableHeight = window.innerHeight - self.$refs.tableList.$el.offsetTop - 64 - 240 - pageHeight
      }
    })
  },
  beforeDestroy() {
    // 注销window.onresize事件
    window.onresize = null
  },
  methods: {
    treeData(data) {
      this.refreshTree = false

      if (this.nowOrganization.name === '') {
        this.nowOrganization = data[0]
      }

      if (this.list === null) {
        this.listQuery.organization_id = data[0].id
        this.identityListQuery.organization_id = data.id
        this.getListRoleData()
      }
    },
    refreshTreeData() {
      // 更新子组件的数据
      this.refreshTree = true
    },
    resetRoleListQuery() {
      this.checkedAllRoleId = []
      this.checkedRoleId = []
      this.roleListQuery = {
        organization_id: 1,
        page: 1,
        limit: 20,
        name: ''
      }
    },
    nodeClick(obj, node, selfObj) {
      this.resetRoleListQuery()
      // 重置分页数据
      this.listQuery = {
        page: 1,
        limit: 20,
        name: ''
      }
      this.identityListQuery = {
        page: 1,
        limit: 20,
        name: ''
      }
      this.nowOrganization = obj
      this.listQuery.organization_id = obj.id
      this.identityListQuery.organization_id = obj.id
      this.nowTabs = 'tabs1'
      this.identityList = null
      this.getListRoleData()
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        parent_id: undefined,
        name: '',
        code: ''
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.addParent = false
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleCreateOne() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.addParent = true
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.addParent) {
            this.temp.parent_id = 0
          } else {
            this.temp.parent_id = this.nowOrganization.id
          }
          this.addParent = false

          organizationAddApi(this.temp).then((response) => {
            this.refreshTreeData()
            this.temp = response.data
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          organizationUpdateApi(tempData).then((response) => {
            this.nowOrganization = response.data
            this.refreshTreeData()
            this.temp = response.data
            this.dialogFormVisible = false
            this.$message({
              // title: 'Success',
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleCancelVisible() {
      this.$refs['popoverNowOrganization'].doClose()
    },
    handleDel(id, name) {
      this.$refs['popoverNowOrganization'].doClose()
      organizationDeleteApi({ id: id }).then(response => {
        this.nowOrganization.name = ''
        this.list = null
        this.refreshTreeData()
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    },
    getListRoleData() {
      this.listLoading = true
      this.checkedRoleId = []
      organizationListRoleApi(this.listQuery).then(response => {
        this.list = response.data.listData
        this.total = response.data.pageData.total
        if (this.total > this.listQuery.limit) {
          this.tableHeight = window.innerHeight - this.$refs.tableList.$el.offsetTop - 64 - 240 - 56
        }
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleCancelRoleVisible(index) {
      this.$refs[`popover` + index].doClose()
    },
    handleDelRole(id, index) {
      this.$refs[`popover` + index].doClose()
      organizationDestroyRoleApi({ id: id }).then(response => {
        this.getListRoleData()
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    },
    detailRoleData() {
      this.isComplete = false
      this.roleListQuery.organization_id = this.nowOrganization.id
      organizationDetailRoleApi(this.roleListQuery).then(response => {
        this.roleList = response.data.listData
        this.roleTotal = response.data.pageData.total
        if (!this.checkedRoleId[this.roleListQuery.page]) {
          this.checkedRoleId[this.roleListQuery.page] = response.data.checkedRole
        }
        this.checkedAllRoleId[this.roleListQuery.page] = this.roleList

        this.drawerVisible = true
        this.$nextTick(() => {
          this.checkedRoleId[this.roleListQuery.page].forEach(row => {
            // 不能自己自定义对象来设置选中（原因如下分析），那我可以从列表中找到需要选中的那个对象，然后把它拿过来作为选中的项就可以了
            this.$refs.multipleTable.toggleRowSelection(this.roleList.find(item => {
              return row.id === item.id // 注意这里寻找的字段要唯一，示例仅参考
            }), true)
          })

          // 渲染完毕
          this.isComplete = true
        })
      })
    },
    handleSelectionChange(val) {
      if (this.isComplete) {
        this.checkedRoleId[this.roleListQuery.page] = val
      }
    },
    updateRoleData() {
      const roleIdArray = []
      const allRoleIdArray = []
      for (const p in this.checkedAllRoleId) {
        if (this.checkedAllRoleId[p]) {
          for (const a in this.checkedAllRoleId[p]) {
            allRoleIdArray.push(this.checkedAllRoleId[p][a].id)
          }
        }
      }
      for (const cp in this.checkedRoleId) {
        if (this.checkedRoleId[cp]) {
          for (const c in this.checkedRoleId[cp]) {
            roleIdArray.push(this.checkedRoleId[cp][c].id)
          }
        }
      }

      if (this.nowOrganization.id > 0 && roleIdArray.length > 0 && allRoleIdArray.length > 0) {
        const tempData = { organization_id: this.nowOrganization.id, role_id: roleIdArray.join(','), all_role_id: allRoleIdArray.join(',') }
        organizationAddRoleApi(tempData).then((response) => {
          this.getListRoleData()
          this.drawerVisible = false
          this.$message({
            // title: 'Success',
            message: response.message,
            type: 'success',
            duration: 1500
          })
        })
      } else {
        this.$message({
          message: '请选择角色',
          type: 'warning',
          duration: 1500
        })
      }
    },
    changeTags(tabs) {
      this.resetRoleListQuery()
      if (tabs.name === 'tabs2' && this.identityList === null) {
        this.getIdentityListRoleData()
      }
    },
    getIdentityListRoleData() {
      this.listLoading = true
      this.identityListQuery.organization_id = this.nowOrganization.id
      organizationListIdentityApi(this.identityListQuery).then(response => {
        this.identityList = response.data.listData
        this.identityTotal = response.data.pageData.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    resetIdentityTemp() {
      this.identityTemp = {
        id: undefined,
        parent_id: undefined,
        name: '',
        code: ''
      }
    },
    handleCreateIdentity() {
      this.resetIdentityTemp()
      this.dialogIdentityFormVisible = true
      this.$nextTick(() => {
        this.$refs['identityDataForm'].clearValidate()
      })
    },
    createIdentityData() {
      this.$refs['identityDataForm'].validate((valid) => {
        if (valid) {
          this.identityTemp.organization_id = this.nowOrganization.id
          organizationAddIdentityApi(this.identityTemp).then((response) => {
            this.getIdentityListRoleData()
            this.dialogIdentityFormVisible = false
            this.$message({
              message: response.message,
              type: 'success',
              duration: 1500
            })
          })
        }
      })
    },
    handleCancelIdentityVisible(index) {
      this.$refs[`identityPopover` + index].doClose()
    },
    handleDelIdentity(id, index) {
      this.$refs[`identityPopover` + index].doClose()
      organizationDeleteIdentityApi({ id: id }).then(response => {
        this.identityList.splice(index, 1)
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    },
    handleCancelIdentityRoleVisible(id) {
      this.$refs[`identityRolePopover` + id].doClose()
    },
    handleDelIdentityRole(id, index, ci) {
      this.$refs[`identityRolePopover` + id].doClose()
      organizationRemoveIdentityRoleApi({ id: id }).then(response => {
        this.identityList[ci].children.splice(index, 1)
        this.$message({
          message: response.message,
          type: 'success'
        })
      })
    },
    detailIdentityRoleData(data) {
      if (data.id !== this.nowIdentity.id) {
        this.nowIdentity = data
        this.resetRoleListQuery()
      }

      this.isComplete = false
      this.roleListQuery.identity_id = this.nowIdentity.id
      organizationDetailIdentityApi(this.roleListQuery).then(response => {
        this.roleList = response.data.listData
        this.roleTotal = response.data.pageData.total
        if (!this.checkedRoleId[this.roleListQuery.page]) {
          this.checkedRoleId[this.roleListQuery.page] = response.data.checkedRole
        }
        this.checkedAllRoleId[this.roleListQuery.page] = this.roleList

        this.drawerIdentityVisible = true
        this.$nextTick(() => {
          this.checkedRoleId[this.roleListQuery.page].forEach(row => {
            // 不能自己自定义对象来设置选中（原因如下分析），那我可以从列表中找到需要选中的那个对象，然后把它拿过来作为选中的项就可以了
            this.$refs.multipleIdentityTable.toggleRowSelection(this.roleList.find(item => {
              return row.id === item.id // 注意这里寻找的字段要唯一，示例仅参考
            }), true)
          })

          const disabledRole = response.data.disabledRole
          disabledRole.forEach(row => {
            this.$refs.multipleIdentityTable.toggleRowSelection(this.roleList.find(item => {
              return row.id === item.id
            }), true)
          })

          // 渲染完毕
          this.isComplete = true
        })
      })
    },
    handleSelectionIdentityChange(val) {
      if (this.isComplete) {
        this.checkedRoleId[this.roleListQuery.page] = val
      }
    },
    updateIdentityRoleData() {
      const roleIdArray = []
      const allRoleIdArray = []
      for (const p in this.checkedAllRoleId) {
        if (this.checkedAllRoleId[p]) {
          for (const a in this.checkedAllRoleId[p]) {
            allRoleIdArray.push(this.checkedAllRoleId[p][a].id)
          }
        }
      }
      for (const cp in this.checkedRoleId) {
        if (this.checkedRoleId[cp]) {
          for (const c in this.checkedRoleId[cp]) {
            roleIdArray.push(this.checkedRoleId[cp][c].id)
          }
        }
      }

      const tempData = { name: this.nowIdentity.name, code: this.nowIdentity.code, identity_id: this.nowIdentity.id, role_id: roleIdArray.join(','), all_role_id: allRoleIdArray.join(',') }
      organizationUpdateIdentityApi(tempData).then((response) => {
        this.getIdentityListRoleData()
        this.drawerIdentityVisible = false
        this.$message({
          message: response.message,
          type: 'success',
          duration: 1500
        })
      })
    },
    identitySelectable(row, index) {
      return !row.disabled
    }
  }
}
</script>

<style scoped>
  .app-container {
    padding-bottom: 0 !important;
  }
  .main-tabs-content {
    padding: 0 20px 0 8px !important;
  }
  .redis-manager-container .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .redis-manager-container .el-pagination {
    text-align: center;
    padding-top: 20px;
  }
  .tab-key-desc span {
    margin-right: 20px;
  }
  .redis-manager-container .tip {
    padding: 10px 0;
    text-align: center;
    font-family: "Hiragino Sans GB";
    font-size: 12px;
  }
  .organization-info {
    margin-bottom: 10px;
    height: 100px;
  }
  .drawer-content .drawer-footer {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    padding: 0 20px;
    margin-top: 60px;
    margin-bottom: 20px;
  }
  .tabs-header-content {
    height: 45px;
  }
</style>

<template>
  <div class="app-container">
    <el-row :gutter="8" style="margin-bottom: 12px">
      <el-col :span="4">
        <el-input v-model="listQuery.name" placeholder="名称" clearable @keyup.enter.native="handleFilter" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="listQuery.project" placeholder="项目" clearable @keyup.enter.native="handleFilter" />
      </el-col>

      <el-col :span="16">
        <el-button v-waves type="primary" icon="el-icon-search" @click="handleFilter">
          搜索
        </el-button>
      </el-col>
    </el-row>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="名称" align="center">
        <template slot-scope="{row}">
          <div>名称：<b>{{ row.name }}</b></div>
          <div>项目：<el-link :underline="false" type="primary">{{ row.project }}</el-link></div>
        </template>
      </el-table-column>
      <el-table-column label="入队数" align="center">
        <template slot-scope="{row}">
          <div>{{ row.push }}</div>
        </template>
      </el-table-column>
      <el-table-column label="出队数" align="center">
        <template slot-scope="{row}">
          <div>{{ row.pop }}</div>
        </template>
      </el-table-column>
      <el-table-column label="成功出队数" align="center">
        <template slot-scope="{row}">
          <div>{{ row.popSuccess }}</div>
        </template>
      </el-table-column>
      <el-table-column label="失败出队数" align="center">
        <template slot-scope="{row}">
          <div>{{ row.popError }}</div>
        </template>
      </el-table-column>
      <el-table-column label="重试次数" align="center">
        <template slot-scope="{row}">
          <div>{{ row.popRetry }}</div>
        </template>
      </el-table-column>
      <el-table-column label="成功率" align="center">
        <template slot-scope="{row}">
          <div>
            <el-tag :type="row.successRate >= 100 ? 'success' : (row.successRate >= 95 ? 'warning' : 'danger')">{{ row.successRate }}%</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="{row}">
          <el-button v-if="row.popError>0" @click="seeLog(row.project, row.name)">日志</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="logTitle" :visible.sync="dialogLog">
      <el-row :gutter="6">
        <el-col :span="8">
          <el-select v-model="logQuery.dir" placeholder="请选择" @change="seeLogData">
            <el-option
              v-for="item in logData.dirArray"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="logQuery.file" placeholder="请选择" @change="seeLogData">
            <el-option
              v-for="item in logData.fileArray"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="logQuery.line" placeholder="请选择" @change="seeLogData">
            <el-option
              v-for="item in logLine"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-col>
      </el-row>
      <div class="log-content">
        <div class="log-content-body">{{ logData.content }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queueList, queueErrorLog } from '@/api/config-center'
import waves from '@/directive/waves' // waves directive

export default {
  name: 'Queue',
  directives: { waves },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        name: '',
        project: ''
      },
      dialogLog: false,
      logTitle: '',
      logQuery: {
        name: '',
        project: '',
        dir: '',
        file: '',
        line: 100
      },
      logData: {
        name: '',
        dirArray: [],
        fileArray: [],
        dir: '',
        file: '',
        showLine: 100,
        content: ''
      },
      logLine: [100, 200, 500, 1000]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      queueList(this.listQuery).then(response => {
        this.list = response.data.list
        this.total = response.data.total

        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      })
    },
    handleFilter() {
      this.getList()
    },
    seeLogData() {
      const tempData = Object.assign({}, this.logQuery)
      queueErrorLog(tempData).then(response => {
        this.dialogLog = true
        this.logData = response.data
        this.logQuery.dir = response.data.dir
        this.logQuery.file = response.data.file
      })
    },
    seeLog(project, name) {
      this.logQuery.project = project
      this.logQuery.name = name
      this.logTitle = name
      this.seeLogData()
    }
  }
}
</script>

<style scoped>
  .log-content {
    margin-top: 12px;
    height: 400px;
    background: #000000;
    color: #ffffff;
    padding: 8px;
    overflow-y: scroll;
  }
  .log-content-body {
    white-space: pre-wrap;
  }
</style>


var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wang-editor-main" },
    [
      _c("div", { ref: "editor", staticStyle: { "padding-bottom": "60px" } }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "素材中心",
            visible: _vm.dialogUpload,
            direction: "rtl",
            "show-close": true,
            size: "85%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUpload = $event
            },
          },
        },
        [
          _c("div", { staticClass: "drawer-content" }, [
            _c("a", [_vm._v("没有素材了")]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogUpload = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmImg } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "联盟商品",
            visible: _vm.dialogNicheGoods,
            direction: "rtl",
            "show-close": true,
            size: "75%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogNicheGoods = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-content" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "8px" },
                  attrs: { gutter: 6 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "ID", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleNicheGoodsFilter($event)
                          },
                        },
                        model: {
                          value: _vm.goodsQuery.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.goodsQuery, "id", $$v)
                          },
                          expression: "goodsQuery.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "标题", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleNicheGoodsFilter($event)
                          },
                        },
                        model: {
                          value: _vm.goodsQuery.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.goodsQuery, "title", $$v)
                          },
                          expression: "goodsQuery.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("common-select", {
                        attrs: {
                          width: "100%",
                          clearable: "",
                          placeholder: "选择联盟",
                          options: _vm.typeOptions,
                        },
                        model: {
                          value: _vm.goodsQuery.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.goodsQuery, "type", $$v)
                          },
                          expression: "goodsQuery.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "selectUpResId",
                          attrs: {
                            prop: "category_id",
                            placeholder: "选择分类",
                          },
                          model: {
                            value: _vm.goodsQuery.category_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.goodsQuery, "category_id", $$v)
                            },
                            expression: "goodsQuery.category_id",
                          },
                        },
                        [
                          _c("el-option", {
                            key: "categoryId",
                            attrs: {
                              hidden: "",
                              value: _vm.goodsQuery.category_id,
                              label: _vm.goodsQuery.category_name,
                            },
                          }),
                          _c("el-tree", {
                            ref: "treeDataDirCheck",
                            attrs: {
                              data: _vm.typeTreeData,
                              props: _vm.defaultProps,
                              "highlight-current": true,
                              "expand-on-click-node": false,
                              "check-on-click-node": true,
                              "node-key": "id",
                              "default-expanded-keys": _vm.defaultData,
                            },
                            on: { "node-click": _vm.nodeClickCheck },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.handleNicheGoodsFilter },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { overflow: "auto" },
                  style: { height: _vm.tableHeight + "px" },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "tableList",
                      attrs: {
                        data: _vm.goodsList,
                        "highlight-current-row": "",
                      },
                      on: { "current-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "序号", type: "index", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "标题" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("hoverStrOmit")(scope.row.title, 12)
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "价格" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v("ID：" + _vm._s(scope.row.id)),
                                ]),
                                _c("div", [
                                  _vm._v("价格：" + _vm._s(scope.row.price)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "主图" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-image", {
                                  attrs: { src: scope.row.photo, width: "80" },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "联盟" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("typeOptionsFilters")(scope.row.type)
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.goodsTotal > _vm.goodsQuery.limit,
                    expression: "goodsTotal>goodsQuery.limit",
                  },
                ],
                attrs: {
                  total: _vm.goodsTotal,
                  page: _vm.goodsQuery.page,
                  limit: _vm.goodsQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.goodsQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.goodsQuery, "limit", $event)
                  },
                  pagination: _vm.openNicheGoods,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogNicheGoods = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmNicheGoods },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }